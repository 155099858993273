import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1049 300" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >


<path fill="#58B953" opacity="1.000000" stroke="none" 
	d="
M1.000001,269.000000 
	C1.000000,187.645767 1.000000,106.291542 1.335286,24.367596 
	C6.810020,13.028675 14.940003,5.450913 26.000000,1.000000 
	C106.354225,1.000000 186.708450,1.000000 267.645325,1.339422 
	C286.213959,8.389282 293.383301,21.681086 293.120392,40.457424 
	C292.693787,70.926620 293.006470,101.406158 293.005188,131.881332 
	C293.003448,173.514084 292.906433,215.147110 293.036072,256.779449 
	C293.102264,278.033478 276.691284,292.351349 259.132141,292.300537 
	C184.194336,292.083588 109.255379,292.295258 34.316963,292.222626 
	C19.425051,292.208191 9.366096,284.491852 3.147030,271.269318 
	C2.726060,270.374268 1.729085,269.750122 1.000001,269.000000 
M57.020439,153.596069 
	C57.326706,154.392990 57.632977,155.189896 58.173122,156.742096 
	C59.060368,159.355331 59.919182,161.978638 60.839729,164.580093 
	C65.052223,176.484512 71.279984,187.140549 79.822441,196.529495 
	C81.626183,198.511963 82.806808,201.798813 82.860909,204.512833 
	C83.153076,219.170303 82.824440,233.839966 83.104492,248.497894 
	C83.192253,253.091492 81.516792,254.157791 77.308159,254.037415 
	C67.661682,253.761505 58.003082,253.937683 48.350483,253.832031 
	C45.085930,253.796295 42.373474,254.895523 42.362022,258.310242 
	C42.349796,261.955444 45.021137,263.095520 48.588757,263.053314 
	C60.750885,262.909454 72.915733,263.005798 85.079514,262.988068 
	C91.381828,262.978912 92.058273,262.371521 92.079041,256.176331 
	C92.127640,241.680161 92.089272,227.183685 92.119415,212.687393 
	C92.121971,211.457138 92.373421,210.227386 92.517189,208.925400 
	C101.318985,211.612030 103.195015,213.994553 103.176422,222.086685 
	C103.166855,226.252319 103.133278,230.417938 103.131393,234.583557 
	C103.128136,241.748352 102.974083,248.919144 103.230766,256.074951 
	C103.331520,258.883636 103.292503,262.795990 107.399414,262.865112 
	C121.224945,263.097931 135.058640,263.062866 148.883163,262.805298 
	C150.275787,262.779358 152.652664,260.620361 152.742310,259.324646 
	C152.858231,257.649017 151.544571,255.218750 150.090836,254.264328 
	C148.453934,253.189667 145.979233,253.114609 143.863831,253.092316 
	C133.749390,252.985748 123.633186,253.044525 113.300079,253.044525 
	C113.300079,241.774460 113.300079,231.042313 113.300079,220.166000 
	C114.241348,220.166000 114.957756,219.984070 115.532074,220.192886 
	C126.722290,224.261719 138.349365,226.288513 150.167664,226.502670 
	C158.586441,226.655243 167.098282,226.124863 175.433640,224.945557 
	C181.509247,224.085938 182.871918,218.465607 178.821320,213.685867 
	C177.538971,212.172653 176.179306,210.724976 174.998825,208.596207 
	C175.600540,208.077942 176.202255,207.559662 177.228348,207.598541 
	C180.897293,209.287781 184.410965,211.622833 188.263351,212.549210 
	C201.595474,215.755112 214.169312,210.316849 220.117737,199.209915 
	C225.433624,189.284119 225.398224,175.433701 214.405518,165.768005 
	C210.683731,162.495499 207.377350,158.750519 203.919662,154.414185 
	C203.943192,153.607147 203.966705,152.800125 204.678223,151.794281 
	C218.796967,144.131500 233.922867,139.401245 249.745819,136.399109 
	C234.764511,133.485748 220.399109,129.142853 206.711105,121.512352 
	C206.402145,121.379158 206.093170,121.245964 205.194687,121.050323 
	C204.802078,121.005035 204.409454,120.959747 204.001892,120.146095 
	C203.967789,119.378220 203.933670,118.610344 204.204376,117.254578 
	C208.836365,112.486855 213.834595,108.018326 218.024521,102.889458 
	C226.894989,92.031204 224.858978,75.102600 214.018295,66.168800 
	C202.114395,56.358814 186.661758,56.976006 174.975372,67.640564 
	C170.713501,71.529800 166.299515,75.252335 161.912292,78.295929 
	C159.974075,73.579712 158.035873,68.863487 156.942078,64.059891 
	C157.549850,62.375385 158.157639,60.690884 158.934631,58.537407 
	C174.043137,51.149441 185.073059,37.010021 199.862869,26.070662 
	C190.805527,21.391348 182.237839,23.525572 173.051270,23.011932 
	C163.694992,23.020367 154.338699,23.028799 144.047424,23.022991 
	C134.029297,23.028143 124.011162,23.033297 113.056885,23.017105 
	C105.701927,23.025862 98.346970,23.034616 90.056671,23.012527 
	C83.032730,23.035267 76.008781,23.058006 68.051712,23.028687 
	C62.256367,23.019958 56.442535,22.710644 50.669571,23.065763 
	C39.046371,23.780762 30.356434,29.078211 25.443583,40.024681 
	C22.400599,46.804844 22.903282,53.890495 23.028875,61.920879 
	C23.036608,67.943008 23.044340,73.965134 23.014156,80.904037 
	C23.020002,84.267281 23.025850,87.630524 23.005713,91.664467 
	C23.014807,92.111778 23.023899,92.559082 23.014677,93.837372 
	C23.022552,95.222542 23.030428,96.607712 23.006884,98.923035 
	C23.015278,103.280899 23.023674,107.638756 23.009844,112.926453 
	C23.018082,117.280067 23.026318,121.633682 23.012671,126.925194 
	C23.012344,132.947067 23.012018,138.968933 23.003292,145.926498 
	C23.298101,151.365570 23.393757,156.827271 24.007662,162.230087 
	C24.194685,163.875992 25.829699,166.658493 26.765471,166.643677 
	C28.636166,166.614029 30.688595,165.272690 32.295414,164.003723 
	C35.749733,161.275757 38.789589,158.003036 42.341084,155.424286 
	C45.233856,153.323822 48.618279,151.897720 51.796089,150.193848 
	C53.214699,149.433228 54.661297,148.724808 56.291920,148.203812 
	C56.291920,148.203812 56.174709,148.467484 56.051441,149.227814 
	C56.401886,150.463547 56.752331,151.699280 57.027966,153.003479 
	C57.027966,153.003479 57.072052,152.912170 57.020439,153.596069 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M498.000000,301.000000 
	C423.977356,301.000000 349.954712,301.000000 275.426270,300.640533 
	C272.764038,298.557159 270.607605,296.833282 267.869324,294.644257 
	C269.933868,293.782288 271.700195,293.101715 273.417206,292.313721 
	C275.625092,291.300446 278.145264,290.603180 279.909546,289.059540 
	C284.330261,285.191650 288.880859,281.448395 291.213257,275.596710 
	C291.861450,273.970490 296.688873,272.173035 296.950958,272.510529 
	C299.989105,276.422668 303.316254,273.310913 307.137695,273.090820 
	C306.629150,271.079010 305.534515,269.520508 304.569275,267.885590 
	C303.964722,266.861542 302.832123,265.350769 303.170441,264.693207 
	C306.238892,258.728546 303.919861,252.585571 303.930115,246.576813 
	C304.041809,181.104202 303.952148,115.631210 304.079620,50.158646 
	C304.091370,44.111912 305.233887,38.068764 305.810730,32.020073 
	C305.887299,31.217142 305.975067,30.258369 305.627930,29.597462 
	C303.407257,25.369280 301.203094,21.117161 298.642517,17.096437 
	C298.166565,16.349096 296.097565,16.616285 293.761353,16.264151 
	C296.053802,21.325367 297.854279,25.177025 299.534485,29.080454 
	C300.083984,30.356970 301.017792,32.195911 300.520050,33.007160 
	C299.926453,33.974575 297.786133,34.749454 296.688171,34.408554 
	C295.829163,34.141842 295.266113,32.093533 295.037842,30.757158 
	C293.447784,21.448872 291.601135,12.351199 282.422607,7.030896 
	C281.819244,6.681157 282.020172,4.943861 281.846985,3.851987 
	C283.115753,4.046014 284.648163,3.862432 285.592255,4.521541 
	C287.050201,5.539390 286.959747,9.427214 289.980499,6.979795 
	C290.442780,6.605286 288.851227,3.705246 288.224182,1.968002 
	C288.112518,1.658669 288.072876,1.323331 288.000000,1.000000 
	C541.884827,1.000000 795.769592,1.000000 1050.000000,1.000000 
	C1050.000000,64.355560 1050.000000,127.711746 1049.626831,191.575287 
	C1046.781860,192.082626 1044.310181,192.082626 1042.768311,192.082626 
	C1041.330078,187.852722 1040.165039,184.426361 1039.000000,180.666870 
	C1039.000000,179.889572 1039.000000,179.445389 1038.952637,178.616928 
	C1035.751465,172.414307 1036.129395,171.084045 1040.884766,166.137436 
	C1041.754395,165.232880 1041.359619,162.882690 1041.166748,161.244781 
	C1040.919434,159.145859 1040.335571,157.079468 1039.800293,155.024170 
	C1039.460571,153.719650 1038.945557,152.460785 1038.509155,151.181442 
	C1037.842529,151.197571 1037.175903,151.213699 1036.509155,151.229828 
	C1036.279663,153.130539 1035.634766,155.090485 1035.895874,156.921219 
	C1036.791138,163.198898 1035.726074,165.425903 1031.019775,165.560577 
	C1031.340088,162.082993 1031.670044,159.041809 1032.162842,155.626892 
	C1034.048828,147.671158 1033.348755,146.313950 1026.003540,142.570862 
	C1026.001465,140.428299 1026.000732,138.714157 1026.341187,136.945175 
	C1029.079346,133.428162 1026.854736,132.070633 1023.994995,130.625259 
	C1023.993286,127.833679 1023.996704,125.416840 1024.272217,122.801636 
	C1024.938232,121.609955 1025.332153,120.616638 1025.864136,119.275299 
	C1024.258911,119.160614 1023.130676,119.080002 1021.739990,118.793961 
	C1021.319153,117.725891 1021.160828,116.863251 1021.370850,115.995377 
	C1022.679382,115.463951 1023.619507,114.937759 1024.559692,114.411560 
	C1020.957764,110.599037 1025.444458,107.060158 1024.915039,103.436028 
	C1024.485596,100.495667 1023.321350,97.661140 1022.463562,94.785469 
	C1021.997681,93.223488 1021.382385,91.698296 1021.023193,90.113731 
	C1019.897888,85.149239 1018.862183,80.164429 1017.803650,75.243111 
	C1014.902222,75.621529 1013.453552,75.810463 1012.003052,75.626770 
	C1011.078979,73.833557 1010.156860,72.412987 1009.135559,70.631561 
	C1008.975830,69.526268 1008.915283,68.781822 1009.142273,68.027489 
	C1009.621582,68.011826 1009.813232,68.006042 1010.356201,68.036774 
	C1012.169800,68.973892 1013.632141,69.874496 1016.396057,71.576683 
	C1016.064941,68.108765 1016.381042,64.013123 1015.530884,63.754742 
	C1011.075745,62.400726 1011.116882,58.669441 1009.858398,55.471573 
	C1009.336304,54.144936 1007.978027,53.147369 1006.553833,51.974037 
	C1005.809937,51.938778 1005.512207,51.929646 1005.139404,51.661247 
	C1005.076233,51.044132 1005.088135,50.686283 1005.485962,50.184769 
	C1006.247986,49.360786 1006.623962,48.680470 1007.384766,47.915901 
	C1009.474060,40.485123 1013.715149,33.049191 1007.000366,25.632748 
	C1007.669617,23.844301 1008.338501,22.422518 1009.305176,20.961430 
	C1009.919739,20.514154 1010.236572,20.106182 1010.553406,19.698215 
	C1010.035583,19.465384 1009.517822,19.232548 1008.693359,18.867390 
	C1007.539612,18.525385 1006.692627,18.315701 1005.889526,18.079426 
	C1005.933533,18.052835 1005.908325,18.152464 1005.860718,17.739254 
	C1004.178528,16.883848 1002.545837,16.064997 1000.909180,16.057068 
	C982.273193,15.966783 963.636475,16.000273 944.781616,15.734612 
	C941.604553,14.979513 938.649109,14.092009 935.686584,14.067493 
	C919.080444,13.930074 902.472229,13.956467 885.865967,14.086945 
	C884.572388,14.097108 883.288452,15.333467 881.625000,16.000010 
	C880.500000,16.000320 879.750000,16.000542 878.775024,15.731844 
	C876.075989,15.933703 873.561523,16.257818 871.141479,16.924728 
	C869.409851,17.401930 866.371643,18.409828 866.403809,19.057247 
	C866.514160,21.275621 867.721375,23.439449 868.510376,25.624079 
	C870.340271,24.749388 872.170105,23.874695 874.362183,23.006012 
	C875.812988,24.341055 876.901611,25.670088 877.609741,27.043865 
	C875.552002,27.629288 873.874634,28.169966 872.235474,28.698347 
	C873.498718,35.032616 873.986755,35.251308 879.280518,32.002552 
	C879.934875,32.008541 880.308777,32.012466 880.812073,32.372993 
	C880.958984,33.487156 880.976501,34.244713 880.709839,35.270386 
	C880.638672,38.034374 880.851746,40.530247 881.079224,43.441940 
	C880.978516,45.234356 880.863464,46.610958 880.456604,47.988686 
	C879.775757,47.993011 879.386658,47.996212 878.596619,47.998741 
	C876.104248,48.105801 872.346558,47.810890 872.206543,48.390182 
	C871.560669,51.062897 871.495667,54.075859 872.106262,56.766228 
	C872.402527,58.071491 874.640869,58.935955 875.994263,60.380440 
	C875.989075,63.506405 875.989624,66.251900 875.631958,68.956459 
	C872.754822,65.773155 870.235901,62.630798 867.693481,59.459087 
	C864.634521,64.530502 858.494934,68.946114 865.637634,76.266983 
	C866.455566,72.881569 867.120911,70.127594 867.550110,68.351181 
	C870.180603,69.640175 872.589050,70.820396 874.861938,72.321381 
	C873.848694,78.233032 872.827087,83.807236 872.176514,89.424408 
	C871.967163,91.232399 872.735718,93.153656 873.075439,95.356903 
	C873.002197,96.125069 872.910461,96.559433 872.541809,97.019470 
	C871.842468,97.363312 871.420044,97.681473 870.603638,97.992455 
	C869.250610,97.738480 868.224365,97.150131 867.347717,97.322441 
	C865.922241,97.602646 864.594604,98.380486 863.225647,98.948280 
	C864.318176,100.294258 865.297058,101.764816 866.563599,102.920021 
	C867.020142,103.336517 868.167725,102.995415 869.000000,103.399994 
	C869.000000,104.866661 869.000000,105.933327 868.597595,107.001770 
	C862.809814,106.974602 866.071533,112.605751 863.389038,114.346039 
	C864.592712,115.897339 865.796387,117.448654 867.000000,119.456039 
	C866.122925,125.411293 864.972900,130.885574 864.468079,136.418716 
	C864.150208,139.903305 864.798523,143.476028 865.042114,147.384064 
	C864.977173,148.502975 864.883545,149.247040 864.415894,150.006790 
	C862.600342,152.766312 861.158936,155.510132 859.797119,158.102386 
	C859.060486,156.931946 858.136536,155.463821 857.118408,153.674377 
	C856.995483,152.910568 856.966675,152.468094 857.212402,151.811951 
	C857.033081,149.741714 856.579285,147.885147 856.028198,145.827393 
	C855.931030,145.626221 855.928040,145.179352 855.943665,144.883423 
	C855.722412,144.073608 855.485474,143.559692 855.168945,142.710052 
	C855.118408,141.917252 855.147522,141.460205 855.500732,140.935028 
	C855.883240,138.911118 855.941589,136.955322 856.264771,134.778336 
	C856.022156,133.371017 855.514771,132.184891 854.983276,130.690643 
	C854.368225,129.597687 853.777222,128.812851 853.072998,127.706032 
	C852.935486,126.932167 852.911316,126.480278 853.166504,126.021080 
	C853.632324,126.009277 853.818604,126.004791 854.373901,125.927460 
	C856.730591,120.966721 858.725342,116.081665 860.686279,111.183060 
	C860.792480,110.917656 860.614685,110.538429 860.568726,110.212204 
	C860.018555,106.309639 859.668152,102.362167 858.806885,98.529480 
	C858.515564,97.232986 856.931519,96.226982 855.938232,95.088226 
	C854.962036,96.170326 854.016968,97.282921 852.997925,98.323059 
	C852.392151,98.941368 851.669861,99.445656 850.968628,99.655663 
	C850.624817,98.206841 850.312378,97.103577 850.379883,95.919472 
	C853.067139,91.184784 852.044373,87.168030 847.835449,84.621536 
	C847.447327,80.495285 847.223694,76.747490 847.316284,72.841171 
	C851.403442,68.446342 850.624817,66.171707 844.707886,64.997467 
	C844.020630,64.993492 843.627930,64.991425 843.148315,64.664261 
	C843.065979,63.892735 843.070679,63.446308 843.400513,62.949028 
	C843.817993,61.598984 843.910217,60.299797 844.307373,58.844711 
	C845.753723,55.780022 846.716858,52.784912 848.074890,49.981083 
	C850.611572,44.743786 848.467041,39.702648 847.770020,34.588524 
	C847.549866,32.973286 846.867065,31.253738 847.201477,29.762577 
	C848.102844,25.743753 843.221191,18.466255 838.851135,17.667248 
	C836.679260,16.223772 834.687927,14.226174 832.627869,14.152656 
	C823.041992,13.810555 813.435791,13.926081 803.840515,14.089324 
	C802.549866,14.111280 801.279663,15.333506 799.666687,16.000034 
	C798.888916,16.000040 798.444458,15.999944 797.954102,15.676575 
	C796.908630,15.293535 795.909058,15.233769 794.909546,15.174003 
	C795.016785,16.301601 795.124023,17.429199 795.256165,18.691406 
	C795.281006,18.826014 795.072754,19.003712 794.765869,19.019958 
	C793.972656,19.357447 793.486328,19.678688 792.651733,19.964722 
	C790.809692,18.985529 789.315857,18.041546 786.837646,16.475504 
	C785.475098,21.373863 783.856506,25.676628 783.155457,30.124014 
	C782.400879,34.911263 782.741516,39.861992 782.168457,44.689659 
	C781.632996,49.200130 783.481934,54.154354 787.000000,56.404861 
	C786.665833,60.873016 786.331665,64.936356 785.630493,69.043671 
	C781.150024,72.532883 780.730713,76.154449 784.000000,80.400169 
	C783.998718,81.866623 783.997437,82.932915 783.639771,84.058319 
	C779.328125,87.122749 781.603271,95.172981 773.112793,94.562103 
	C775.856079,98.721252 777.926819,101.860733 779.997559,105.393944 
	C780.366211,107.206284 780.734863,108.624901 781.123230,110.382057 
	C781.032043,111.157143 780.921265,111.593689 780.510315,112.223099 
	C779.140137,116.277206 778.070068,120.138458 776.728638,124.286354 
	C774.761169,124.573006 773.065063,124.573006 770.582764,124.573006 
	C771.289978,119.434853 771.915710,114.889046 772.714355,109.086624 
	C770.739990,110.776421 769.831909,111.878098 768.665222,112.469574 
	C767.222351,113.201088 765.564819,113.509003 763.869202,113.722893 
	C762.893188,112.961632 762.047974,112.477402 761.106934,111.669861 
	C761.004272,110.899323 760.997437,110.452080 761.276428,109.745056 
	C761.708191,107.656845 761.854065,105.828423 762.381897,104.006157 
	C763.255310,104.084709 763.796326,104.049545 764.229614,104.248619 
	C765.880371,105.007004 767.496460,105.840981 769.125488,106.646729 
	C769.749207,104.480385 770.532776,102.342590 770.927063,100.135254 
	C771.115479,99.080368 770.432739,97.899963 770.377625,96.765083 
	C770.272949,94.609138 770.244324,92.440498 770.351318,90.285423 
	C770.501282,87.264557 772.159058,82.918892 770.767700,81.503883 
	C767.580627,78.262550 769.022461,75.731102 769.921814,72.655190 
	C770.471069,70.776772 771.087646,68.717064 770.813293,66.866394 
	C770.615906,65.535057 768.997131,64.414459 768.012451,63.199863 
	C766.842834,64.153755 765.346436,64.904793 764.593750,66.117424 
	C763.811951,67.377052 763.749573,69.083160 763.372559,70.594002 
	C762.943665,70.651222 762.514832,70.708443 762.085938,70.765663 
	C761.236084,67.333420 760.386292,63.901173 759.614014,60.781963 
	C757.507629,60.487404 755.757446,60.242661 754.005676,59.560658 
	C754.002686,57.082264 754.001343,55.041130 754.376221,52.921463 
	C757.167480,48.133209 756.524475,46.061684 752.005493,43.561684 
	C752.003296,41.082939 752.002869,39.041611 752.348938,36.939865 
	C753.130310,34.919552 753.565186,32.959656 754.000000,30.666500 
	C754.000793,29.888889 754.001648,29.444544 754.352661,28.963516 
	C756.587524,26.387924 758.545410,23.896526 760.265381,21.250572 
	C760.482910,20.915903 759.452332,19.769957 759.000000,18.666679 
	C759.000000,17.889214 759.000000,17.444950 758.930237,16.628374 
	C754.037476,13.102692 747.824219,12.987888 744.666687,16.000023 
	C743.888916,15.999943 743.444458,15.999804 742.908752,15.603257 
	C742.314758,14.831637 741.813416,14.130785 741.308960,14.128496 
	C725.821533,14.058248 710.324036,13.728242 694.850403,14.189510 
	C687.651123,14.404122 683.473999,18.992765 682.623108,25.000622 
	C681.492371,25.002197 680.738464,25.004284 679.723755,24.856449 
	C679.256653,24.152782 679.050415,23.599037 679.185425,22.993927 
	C679.684509,21.295008 679.842224,19.647453 680.326111,17.949593 
	C680.631897,17.067860 680.611633,16.236431 680.591309,15.405004 
	C678.761108,15.918735 676.486511,12.255521 674.531372,15.999870 
	C642.041809,16.000177 610.020874,16.000462 577.956787,15.687343 
	C575.606628,13.523872 575.331909,15.313471 575.000000,17.000343 
	C575.000000,17.000000 575.000000,17.000610 574.595947,17.000380 
	C572.461121,17.331789 570.730408,17.663427 568.680603,18.020161 
	C567.574402,18.361225 566.787170,18.677195 565.960083,18.632195 
	C562.411255,13.292512 554.682068,12.115771 550.531372,15.999756 
	C517.041809,15.999968 484.020905,16.000120 450.953369,15.677691 
	C448.270905,15.570421 445.635101,15.785733 442.530701,16.000717 
	C418.374725,16.000219 394.687347,16.000046 370.788818,15.738886 
	C367.493225,15.022720 366.191223,15.872694 367.998840,19.416382 
	C367.999268,21.222036 367.999634,22.611000 367.645050,24.064680 
	C364.605011,27.742607 366.562042,28.780586 369.994934,29.400982 
	C369.998138,30.867243 369.999084,31.933620 369.645630,33.064529 
	C369.527466,35.419426 369.763733,37.709789 369.999390,40.387669 
	C369.664642,42.516582 369.330505,44.257980 368.621796,46.044163 
	C365.633453,46.482441 362.998108,46.772346 360.414337,47.310089 
	C358.972656,47.610146 356.978699,47.921062 356.346466,48.926720 
	C354.710785,51.528370 353.391296,54.432877 352.553650,57.388786 
	C352.126770,58.895184 352.617401,60.977455 353.383026,62.437832 
	C354.820801,65.180244 356.759399,67.660103 358.948303,70.935318 
	C359.175018,71.032646 360.573669,71.633141 361.350006,71.966446 
	C358.741425,74.202423 356.718353,75.936501 355.788269,76.733757 
	C357.555389,81.039978 358.778290,84.020042 360.000854,87.375061 
	C359.999908,88.499901 359.999329,89.249802 359.614166,90.006165 
	C356.152100,90.991669 350.533203,100.932709 351.588318,103.903099 
	C351.899017,104.777763 353.170990,105.310966 354.003357,106.421997 
	C354.003265,110.562431 354.002228,114.281525 353.635712,118.043442 
	C349.205078,121.469765 348.842377,124.300529 352.001434,128.336761 
	C352.329041,129.447342 352.655212,130.221176 353.007446,131.331467 
	C352.952393,132.109085 352.871246,132.550247 352.441406,132.992828 
	C351.395111,132.996368 350.697571,132.998489 349.810577,132.728424 
	C348.617126,131.706192 347.613098,130.956177 345.550446,129.415329 
	C344.920929,134.081726 344.416321,137.821945 343.847382,142.039230 
	C343.185730,142.231064 341.997864,142.575500 339.385040,143.333099 
	C340.608521,139.588303 341.441711,136.891022 342.372253,134.227737 
	C343.482635,131.049744 343.414825,128.435532 339.588257,127.246582 
	C336.460938,132.700333 337.252258,142.374588 341.000916,146.417175 
	C341.000824,148.222290 341.001007,149.610840 340.725037,151.198181 
	C339.977417,152.069702 339.656647,153.031464 339.010162,153.368546 
	C334.573364,155.681976 334.853516,159.066330 335.716431,163.384567 
	C336.523224,167.421906 335.578094,171.776642 335.931244,175.947037 
	C336.245972,179.663300 337.282196,183.318466 338.000763,187.386139 
	C338.722046,188.860840 339.442627,189.950012 340.153015,191.375122 
	C340.015503,192.139343 339.888184,192.567596 339.430511,193.071594 
	C336.732605,196.764694 334.365082,200.382034 331.622681,203.923096 
	C330.999023,202.227966 330.636322,200.616501 330.530853,198.988388 
	C330.405548,197.053665 330.501373,195.104614 330.501373,193.161774 
	C329.867371,192.938873 329.233368,192.715988 328.599365,192.493088 
	C327.075287,195.319489 324.666687,198.065186 324.398376,201.006165 
	C324.263153,202.488144 328.009857,204.324295 329.994751,206.388000 
	C329.344940,209.543686 328.701599,212.311646 328.037170,215.037704 
	C328.016083,214.995804 328.087738,215.056580 327.753571,215.060547 
	C326.279602,215.709869 325.139801,216.355240 323.768707,216.731750 
	C322.427307,217.016586 321.317200,217.570282 320.207062,218.123993 
	C322.163544,219.956146 322.532196,224.572037 327.367096,221.999481 
	C328.822388,222.332367 329.911804,222.665176 330.863403,223.325653 
	C331.606354,224.765335 332.455566,226.818512 333.372681,226.849319 
	C349.085968,227.377243 364.806671,227.727188 380.528198,227.924271 
	C383.487885,227.961395 386.188324,226.874207 386.320618,222.863388 
	C387.760651,222.481949 388.880341,222.240372 390.004791,222.365356 
	C390.463593,223.776901 390.917633,224.821869 391.920319,227.129593 
	C393.284882,223.955872 394.066223,222.138596 395.665527,218.418869 
	C396.820618,221.076584 397.532654,222.714905 398.244690,224.353241 
	C398.581665,224.054581 398.918671,223.755936 399.255646,223.457275 
	C399.255646,219.126740 399.255646,214.796204 399.255646,209.610886 
	C397.589691,211.402985 396.527191,212.545898 396.421417,212.659698 
	C393.154114,212.809280 391.077667,212.904343 388.944153,212.655701 
	C387.678284,211.870651 386.469513,211.429291 385.158386,210.627075 
	C385.021729,209.517456 384.987457,208.768692 385.233673,207.792114 
	C385.675720,206.376190 385.837250,205.188095 386.389496,203.912186 
	C387.397369,200.540192 388.014526,197.256012 388.460052,194.885101 
	C390.230682,193.641907 392.724670,192.695755 392.724731,191.749405 
	C392.725067,187.949860 389.896912,189.860703 387.999817,189.973450 
	C387.667603,189.993195 387.334106,189.991287 386.643982,189.996643 
	C385.810364,189.984543 385.333984,189.975174 384.855316,189.631165 
	C384.965179,188.866104 385.077332,188.435730 385.541595,187.949783 
	C386.596619,186.263016 387.299530,184.631821 388.355286,182.963531 
	C391.157562,181.125717 393.606995,179.324966 395.706787,177.781265 
	C397.122162,179.192368 398.368439,180.752380 399.956909,181.786972 
	C400.336243,182.033981 402.634796,180.611938 402.720703,179.814316 
	C403.130768,176.007370 405.601654,175.743408 408.451080,176.021027 
	C411.863281,176.353470 415.260284,176.841843 419.315338,177.343246 
	C418.066650,182.068405 416.977448,186.190033 415.602478,191.393021 
	C418.720337,189.689331 420.351166,188.798218 422.877747,187.417618 
	C422.400055,194.545837 426.490753,196.027374 431.599213,196.372696 
	C432.408295,196.427399 433.196991,196.783737 433.996948,197.377930 
	C434.381866,198.856689 434.764954,199.958130 435.130005,201.387939 
	C435.019592,202.141861 434.927216,202.567444 434.529175,202.993256 
	C433.815857,202.996063 433.408203,202.998642 432.813965,202.768097 
	C431.893463,203.119675 431.159546,203.704376 430.425598,204.289078 
	C430.950409,204.859390 431.475189,205.429688 431.999878,206.404556 
	C432.487305,210.613159 430.533417,212.067368 426.865234,211.691193 
	C425.593231,209.888168 424.457245,208.396362 422.889008,206.336960 
	C420.837158,209.296844 419.478119,211.702805 417.686066,213.725037 
	C415.636932,216.037430 415.480164,217.953781 417.653931,220.212021 
	C418.651764,221.248672 419.378571,222.582565 420.077087,223.864075 
	C422.077545,227.533966 424.121368,227.623047 426.822571,224.291901 
	C427.616486,223.312866 428.926514,222.752350 430.333313,222.000122 
	C431.111115,222.000565 431.555542,222.000900 432.061035,222.347626 
	C433.810150,224.674500 435.160461,224.765869 436.437500,222.000305 
	C438.916656,221.999786 440.958344,221.999588 443.004272,222.367310 
	C444.721741,225.292313 446.246613,228.016861 448.273132,230.295166 
	C448.817291,230.906921 450.888977,230.159943 452.259430,230.036713 
	C452.016144,228.943222 451.883179,227.808975 451.498413,226.767776 
	C451.118347,225.739243 450.483704,224.804779 449.411743,222.804764 
	C454.109924,225.243835 457.521210,227.014801 462.726257,229.717010 
	C455.524933,231.052780 454.208954,234.253998 457.085663,239.015717 
	C457.222260,239.241806 457.035950,239.662964 456.543823,239.997055 
	C453.015869,240.584167 449.944153,241.167511 446.472137,241.801895 
	C408.719696,241.834061 371.367554,241.815201 333.913849,241.433136 
	C331.543152,240.717148 329.274017,240.364380 326.785767,239.744019 
	C321.663696,238.545990 323.197937,243.380829 321.559906,245.348160 
	C321.157837,245.831009 322.473846,247.744339 322.991943,249.371429 
	C322.348602,251.169052 321.713318,252.595261 321.450104,253.186218 
	C322.898682,255.530518 323.947449,257.227722 324.644592,258.955566 
	C323.531067,259.653107 322.769104,260.320099 322.000549,261.357025 
	C321.349884,264.234741 319.433441,266.653809 321.808563,269.346985 
	C322.187561,269.776764 321.746307,271.327057 321.248535,272.065643 
	C318.821167,275.667511 320.745636,277.108154 323.998230,278.375000 
	C323.995422,279.499908 323.991608,280.249847 323.666809,281.045685 
	C323.032654,281.843262 322.719421,282.594940 322.406189,283.346619 
	C322.937042,283.231873 323.467926,283.117157 324.253235,283.011719 
	C324.676666,283.036102 324.845734,283.051117 325.087097,283.212891 
	C325.159363,283.359589 325.561401,283.505951 325.561401,283.505951 
	C325.561401,283.505951 325.859772,283.171326 325.921265,283.110596 
	C325.982788,283.049866 326.072784,282.902313 326.318665,282.924622 
	C326.564514,282.946930 327.001892,283.175934 327.039703,283.452362 
	C327.713013,283.833557 328.348541,283.938293 329.422485,284.034973 
	C331.906464,284.030792 333.951996,284.034729 336.046448,284.409302 
	C339.014893,285.853302 341.928192,287.839813 344.855011,287.859955 
	C377.156708,288.082733 409.460571,287.976410 441.763611,288.062103 
	C443.583984,288.066956 445.744720,288.504059 447.157410,289.542267 
	C451.538513,292.762177 458.147156,291.234558 461.331055,286.014801 
	C462.108307,286.022461 462.552368,286.030426 463.125977,286.295441 
	C463.648621,286.793945 464.041748,287.035400 464.434845,287.276855 
	C464.627625,286.706879 464.820435,286.136902 465.088562,285.557220 
	C465.163879,285.547516 465.272858,285.653259 465.705933,285.757812 
	C467.952271,284.904266 470.235962,284.327393 471.423401,282.862122 
	C472.302948,281.776825 471.933685,279.517822 471.757080,277.819489 
	C471.688293,277.157990 470.622589,276.600128 469.728943,276.005981 
	C469.259766,276.037231 469.072693,276.057495 468.901001,276.046387 
	C468.916412,276.014984 468.950928,276.075836 468.973633,275.707275 
	C471.271973,270.280396 472.755951,275.011292 473.789642,276.037170 
	C475.934052,278.165344 477.906189,279.096191 478.751007,275.607056 
	C479.470581,272.635162 478.899658,269.350800 478.899658,265.436707 
	C476.156403,266.922821 474.662872,267.907837 473.036469,268.563293 
	C471.423615,269.213348 469.676910,269.531433 467.992249,269.746429 
	C467.995544,269.497742 468.001221,269.000397 468.345459,268.947449 
	C469.127258,267.931274 469.564850,266.968079 470.290253,265.861755 
	C471.049622,264.812897 471.521149,263.907166 472.413788,263.000977 
	C476.850067,263.479218 480.423035,261.774323 478.682983,257.767548 
	C476.363098,252.425613 478.958618,249.490128 480.730560,247.523575 
	C480.089691,240.948685 479.583221,235.752655 479.102325,230.818878 
	C486.718750,226.216263 490.127167,226.753189 498.165497,233.651398 
	C496.833252,235.386444 495.486267,237.140732 493.806061,239.328979 
	C495.229736,239.627716 496.116852,239.813858 497.001678,240.252228 
	C496.993927,240.672501 496.988464,240.840561 496.761902,241.151001 
	C496.677795,241.854538 496.814850,242.415695 496.962463,243.409180 
	C496.957947,245.571838 496.942841,247.302139 496.713959,249.179550 
	C496.662537,249.883606 496.824890,250.440552 496.993042,251.247833 
	C496.999756,251.665070 497.000732,251.832001 496.997864,252.000015 
	C496.994019,252.001099 496.983246,252.004822 496.617371,252.011047 
	C493.344482,253.784546 493.487732,254.739532 497.142944,257.297119 
	C498.858063,259.396179 500.429047,261.198090 501.598083,263.000824 
	C499.102356,263.543854 497.008514,264.086060 494.914673,264.628265 
	C494.886047,265.162720 494.857422,265.697205 494.828766,266.231659 
	C496.124084,266.781769 498.085083,268.142273 498.588715,267.728577 
	C500.040741,266.535767 500.904938,264.627350 502.353729,263.036133 
	C504.471619,264.048187 506.235809,265.024078 507.944244,266.349609 
	C505.925385,268.759338 503.962280,270.819519 501.590302,272.970215 
	C499.787964,273.043610 498.394501,273.026489 496.925598,272.638641 
	C494.947754,272.146729 492.603058,271.299500 491.269623,272.111969 
	C489.980865,272.897186 489.067230,275.429993 489.162903,277.129059 
	C489.389160,281.147705 492.458282,282.948395 496.148132,284.304382 
	C498.535736,286.406097 500.774017,288.203369 503.221252,290.115936 
	C503.691193,290.429047 503.881287,290.680450 503.997955,291.237061 
	C503.994232,291.656158 503.993073,291.823639 503.676453,292.152710 
	C501.014648,292.876678 498.668243,293.439117 496.176239,294.036469 
	C496.769196,296.300568 497.384613,298.650269 498.000000,301.000000 
M480.310577,281.062805 
	C477.588043,283.358368 474.865479,285.653931 471.873718,288.176453 
	C475.520782,291.529663 479.097382,289.440125 481.546722,288.015747 
	C483.993469,286.592896 483.454010,283.392670 480.310577,281.062805 
M1025.540161,68.734840 
	C1024.720581,66.885216 1024.056641,64.930481 1022.942383,63.279785 
	C1022.734131,62.971222 1019.710632,63.861401 1019.703308,64.232025 
	C1019.654175,66.742134 1020.066345,69.261284 1020.485474,73.270691 
	C1022.975586,71.148125 1024.034668,70.245354 1025.540161,68.734840 
M866.537048,33.915031 
	C867.558228,31.973127 868.579407,30.031225 869.600586,28.089323 
	C868.932007,27.597980 868.263428,27.106636 867.594849,26.615294 
	C866.208313,27.847357 864.458679,28.879793 863.616882,30.411301 
	C863.287537,31.010422 864.996399,32.729836 866.537048,33.915031 
M397.691010,187.219543 
	C393.996552,190.329025 394.021759,191.933197 398.979095,195.589615 
	C398.744812,192.309601 398.574097,189.920074 397.691010,187.219543 
M850.747437,20.019833 
	C850.240295,18.299610 849.733154,16.579388 849.226013,14.859165 
	C848.661926,15.076926 848.097900,15.294686 847.533813,15.512447 
	C847.106750,17.813509 845.600647,20.604717 850.747437,20.019833 
M869.596069,84.265022 
	C868.586609,83.204338 867.577209,82.143646 866.567749,81.082962 
	C865.842407,81.805656 865.117065,82.528351 864.391724,83.251022 
	C865.932373,83.752518 867.473022,84.254013 869.596069,84.265022 
M289.663879,289.195709 
	C289.025970,287.983978 288.388031,286.772278 287.750122,285.560547 
	C287.016968,286.286987 286.283813,287.013397 285.550659,287.739807 
	C286.756897,288.387390 287.963165,289.034973 289.663879,289.195709 
M777.909241,109.582397 
	C777.210022,109.181976 776.510864,108.781555 775.811646,108.381142 
	C775.595581,109.723862 775.379456,111.066582 775.163330,112.409309 
	C775.568176,112.536278 775.973083,112.663246 776.377991,112.790215 
	C776.898193,111.967979 777.418457,111.145744 777.909241,109.582397 
M875.675110,44.789879 
	C876.122375,43.784081 876.569580,42.778282 877.372681,40.972218 
	C872.551880,43.412979 872.551880,43.412979 875.675110,44.789879 
M320.057068,196.764496 
	C320.606018,197.507416 321.154938,198.250351 321.703888,198.993286 
	C322.138397,198.536041 322.572906,198.078796 323.007416,197.621552 
	C322.062103,197.112778 321.116760,196.604004 320.057068,196.764496 
M1025.362061,83.581169 
	C1024.905762,84.766502 1024.449463,85.951836 1023.993164,87.137169 
	C1024.528076,87.244247 1025.063110,87.351318 1025.598022,87.458389 
	C1025.667969,86.345032 1025.737915,85.231674 1025.362061,83.581169 
M778.252319,84.646996 
	C778.613953,83.790062 778.975586,82.933128 779.337280,82.076218 
	C778.781250,81.889648 778.225159,81.703072 777.669128,81.516487 
	C777.645691,82.511757 777.622314,83.507034 778.252319,84.646996 
M327.605713,287.154999 
	C327.345917,287.797272 327.086151,288.439545 326.826355,289.081818 
	C327.372864,289.174805 327.919403,289.267761 328.465912,289.360748 
	C328.384735,288.681122 328.303589,288.001526 327.605713,287.154999 
M353.304077,68.473969 
	C353.406860,67.510841 353.509644,66.547714 353.612427,65.584587 
	C353.149750,65.606178 352.687073,65.627777 352.224396,65.649376 
	C352.364960,66.588387 352.505524,67.527405 353.304077,68.473969 
M1039.275879,145.881927 
	C1039.468018,146.424957 1039.660156,146.967972 1039.852295,147.511017 
	C1040.130737,147.383759 1040.409302,147.256500 1040.687744,147.129257 
	C1040.362061,146.570343 1040.036377,146.011429 1039.275879,145.881927 
M321.474487,211.686081 
	C322.021301,212.024734 322.568146,212.363403 323.114990,212.702072 
	C323.249756,212.427841 323.384552,212.153625 323.519318,211.879395 
	C322.983826,211.674362 322.448303,211.469315 321.474487,211.686081 
M321.704285,207.566895 
	C321.989319,207.294876 322.274353,207.022873 322.559357,206.750854 
	C322.446564,206.632904 322.241913,206.406143 322.234253,206.412643 
	C321.933624,206.666489 321.651001,206.941620 321.704285,207.566895 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M1050.000000,221.000000 
	C1050.000000,227.021149 1050.000000,233.042297 1049.531372,239.531723 
	C1003.961487,240.031708 958.860229,240.063431 913.508423,239.999924 
	C912.838440,239.571609 912.418945,239.238525 911.933167,238.749390 
	C911.590088,238.614395 911.313354,238.635452 910.647095,238.677429 
	C909.480835,238.636322 908.704041,238.574295 907.805847,238.418945 
	C907.343201,238.280304 907.118286,238.431046 906.797119,238.928848 
	C906.245483,239.132248 905.906494,239.184662 905.206970,239.183228 
	C903.898987,240.419205 902.951477,241.709030 901.721436,243.136948 
	C901.623535,244.184265 901.808105,245.093506 901.999390,246.378479 
	C902.506165,249.187469 903.210999,251.359360 906.448853,250.299286 
	C906.418213,250.340408 906.519836,250.354416 906.411499,250.671875 
	C906.537109,251.327042 906.770996,251.664749 907.004150,252.341187 
	C906.668213,253.454086 906.333008,254.228256 905.944092,254.865875 
	C905.807129,254.679733 905.723755,254.630173 905.640503,254.580597 
	C905.761169,254.719757 905.881836,254.858917 906.023804,255.352051 
	C906.078247,255.940079 906.111450,256.174133 906.011353,256.565613 
	C905.643494,256.879120 905.385803,256.967682 904.854797,257.094818 
	C904.422729,257.503662 904.240723,257.806396 904.052124,258.067902 
	C904.045532,258.026703 904.123047,258.057587 903.816284,258.153015 
	C902.808472,259.746735 901.798706,261.198944 901.564514,262.766937 
	C901.475769,263.361298 903.174255,264.222504 904.031738,264.981079 
	C904.005310,265.000122 904.030090,264.939972 904.111450,265.171631 
	C904.372803,265.695251 904.626221,265.893005 905.139099,266.143341 
	C905.575195,266.485107 905.802307,266.704071 906.000549,267.288879 
	C905.997375,268.086304 905.999878,268.541931 905.863281,269.051758 
	C905.816956,269.069519 905.909668,269.033020 906.252441,268.995789 
	C906.669128,268.995605 906.835754,268.996216 907.125854,269.289795 
	C907.499207,270.379547 907.749023,271.176239 907.770630,272.098694 
	C907.122437,272.520569 906.702393,272.816742 905.834839,273.080566 
	C903.933899,273.859558 901.220215,274.728088 901.253967,275.471863 
	C901.373901,278.113281 898.988098,282.173523 904.126343,282.931335 
	C904.082520,282.976074 904.117615,282.855865 904.083984,283.242432 
	C906.204834,287.950439 910.769592,289.886963 912.663086,286.933136 
	C914.768616,283.648529 917.399109,284.129364 920.272522,283.976929 
	C922.838318,283.840851 925.391846,283.472992 928.416504,283.128540 
	C945.002258,283.036041 961.122375,282.991608 977.242371,283.015930 
	C1001.169128,283.052002 1025.095825,283.136810 1049.139648,283.384308 
	C1049.458496,283.791138 1049.706177,283.935211 1050.000000,284.000000 
	C1050.000000,289.654541 1050.000000,295.309113 1050.000000,301.000000 
	C987.645752,301.000000 925.291565,301.000000 862.534546,300.641022 
	C864.282104,299.274719 866.359619,298.043182 868.600891,297.316589 
	C871.429199,296.399628 873.900024,295.415344 873.875854,291.869019 
	C873.894958,291.882080 873.864441,291.900085 874.266663,291.933838 
	C876.335266,291.594147 878.001709,291.220673 879.588806,290.864990 
	C879.350342,287.864532 879.156555,285.425598 878.943787,282.530792 
	C878.888306,278.739746 878.851746,275.404572 879.142700,271.916443 
	C882.846375,267.635193 878.995056,266.069824 876.661743,264.073425 
	C874.541565,262.259399 872.095154,260.796143 870.151306,258.824524 
	C869.539307,258.203827 869.582153,255.745728 870.183838,255.307571 
	C875.291016,251.588547 876.162659,246.500275 876.087280,240.629105 
	C875.974670,231.850677 864.101807,218.688644 851.469910,225.156616 
	C840.338989,225.042587 829.673157,224.923035 819.004883,224.451202 
	C818.738220,223.197937 818.474060,222.296967 818.209961,221.395996 
	C817.929749,221.443039 817.649597,221.490082 817.369385,221.537140 
	C817.243774,222.624802 817.118164,223.712479 816.533813,224.874939 
	C811.718628,224.900467 807.362244,224.851227 803.004578,224.392441 
	C802.824707,218.707428 805.558472,214.047440 805.596558,208.577774 
	C805.652588,200.537231 807.308289,192.401123 809.167114,184.514206 
	C810.614014,178.374741 808.674500,171.112061 815.340881,166.035843 
	C813.522888,164.814880 812.122192,163.874191 810.721191,162.596710 
	C810.873230,161.840805 811.025391,161.421677 811.520874,160.922775 
	C814.768616,156.465775 815.115967,151.854645 813.074707,146.772751 
	C813.061035,146.345047 813.100342,146.174484 813.496338,145.956360 
	C815.492981,145.608215 817.132935,145.307663 818.848267,145.396729 
	C818.990723,146.840256 819.057678,147.894196 818.811157,149.221222 
	C818.334778,151.787140 817.784485,154.130630 818.076843,156.363846 
	C819.250732,165.333054 820.570312,174.288025 822.111206,183.200684 
	C822.462585,185.232925 823.911499,187.075409 824.975586,189.307739 
	C825.140991,190.039490 825.186096,190.468719 824.905334,191.150589 
	C825.330872,195.269669 826.082275,199.136139 826.948914,203.314850 
	C827.082336,204.067490 827.100525,204.507904 826.830322,205.170227 
	C826.968872,206.929047 827.395813,208.465958 827.946533,210.308578 
	C828.109375,211.049698 828.148376,211.485107 827.874023,212.167725 
	C827.240173,214.789642 825.359680,217.543854 829.111877,218.915161 
	C829.080627,218.910034 829.134033,218.869354 829.170654,219.277802 
	C830.174072,220.123764 831.139221,220.938599 832.107971,220.942978 
	C851.124573,221.029236 870.142395,221.118698 889.157532,220.939865 
	C892.451599,220.908890 895.734131,219.661087 899.010986,218.987366 
	C899.000000,218.999390 899.025024,218.979401 899.013855,219.398865 
	C899.152344,221.488785 899.302002,223.159241 899.451660,224.829712 
	C899.766357,225.086273 900.081116,225.342850 900.395813,225.599411 
	C904.428772,219.171036 910.484436,221.190186 915.981567,221.028519 
	C920.443359,220.897308 924.912354,220.967194 929.377502,221.012177 
	C933.962524,221.058365 938.944641,219.703186 942.280518,225.461609 
	C942.999756,222.664581 943.481995,220.789291 943.908142,218.615891 
	C943.811523,217.915741 943.770935,217.513718 944.020630,216.936615 
	C943.909607,215.840393 943.508179,214.919250 942.971008,213.775116 
	C942.839905,213.212830 942.844543,212.873520 942.848328,212.255127 
	C942.660400,211.666840 942.473389,211.357635 942.187012,210.711090 
	C942.086914,209.905380 942.086182,209.436981 942.346802,208.735519 
	C942.774109,206.601166 943.081421,204.699478 943.076111,202.798676 
	C943.065613,199.032745 942.442139,195.216415 942.869812,191.513046 
	C943.584961,185.320831 944.925903,179.200867 946.461304,172.940918 
	C959.611938,172.900818 972.304810,172.970276 984.997559,173.426178 
	C985.995178,174.876114 986.992798,175.939636 988.220154,177.182343 
	C988.537415,177.922333 988.624939,178.483124 988.439392,179.233246 
	C987.921997,181.237045 988.220459,183.392303 987.334229,184.803635 
	C984.386963,189.496933 986.700256,190.223450 990.879028,190.366882 
	C991.037964,191.463348 991.097717,192.202469 990.853333,193.061249 
	C989.390686,193.499146 988.232117,193.817368 986.938416,194.172745 
	C991.013184,198.721451 989.578430,204.134995 990.151733,209.150009 
	C990.174255,209.152496 990.198608,209.191116 989.829102,209.181717 
	C987.856079,212.038208 986.252625,214.904083 983.976257,218.972748 
	C987.887756,220.118271 990.813721,221.423080 993.841492,221.732895 
	C996.730286,222.028503 999.723877,221.055862 1002.673523,221.040436 
	C1018.448669,220.957932 1034.224487,221.000610 1050.000000,221.000000 
M898.221436,286.160553 
	C899.543091,287.071655 900.864746,287.982788 902.186462,288.893890 
	C902.508484,288.502136 902.830505,288.110352 903.152588,287.718597 
	C901.481995,285.841217 899.811401,283.963867 898.140808,282.086517 
	C897.811951,282.262177 897.483093,282.437836 897.154236,282.613495 
	C897.322266,283.644165 897.490295,284.674805 898.221436,286.160553 
M818.246277,171.419998 
	C814.119507,172.274872 816.014771,174.041946 818.414185,175.846786 
	C818.524658,174.564835 818.635132,173.282883 818.246277,171.419998 
M817.779846,212.206329 
	C817.298035,211.206238 816.816162,210.206146 815.772888,208.040817 
	C815.099548,212.961090 815.099548,212.961090 817.779846,212.206329 
M986.754333,201.020309 
	C986.600952,200.177826 986.447632,199.335342 986.294312,198.492859 
	C985.831848,198.667572 985.369385,198.842300 984.906921,199.017014 
	C985.375671,199.841248 985.844360,200.665482 986.754333,201.020309 
M820.754333,206.020340 
	C820.600952,205.177887 820.447571,204.335449 820.294189,203.492996 
	C819.819336,203.645721 819.344421,203.798447 818.869568,203.951172 
	C819.350769,204.797348 819.831970,205.643509 820.754333,206.020340 
M814.723389,190.117889 
	C814.531433,189.575012 814.339478,189.032150 814.147583,188.489273 
	C813.869202,188.616455 813.590820,188.743637 813.312439,188.870804 
	C813.637878,189.429596 813.963318,189.988403 814.723389,190.117889 
M897.269287,261.096130 
	C897.768555,261.040924 898.267883,260.985748 898.767151,260.930542 
	C898.652527,260.483704 898.537964,260.036865 898.423401,259.589996 
	C897.996948,259.892700 897.570435,260.195404 897.269287,261.096130 
M981.798340,208.392883 
	C981.794128,208.786240 981.789856,209.179581 981.785645,209.572922 
	C981.948853,209.574463 982.253967,209.586975 982.254822,209.575974 
	C982.285095,209.184570 982.286865,208.790970 981.798340,208.392883 
M983.393066,179.201614 
	C983.786316,179.205795 984.179626,179.209976 984.572876,179.214172 
	C984.574463,179.050949 984.586975,178.745865 984.575989,178.745026 
	C984.184631,178.714828 983.791016,178.713120 983.393066,179.201614 
M906.703979,234.567688 
	C906.989136,234.295578 907.274353,234.023468 907.559509,233.751358 
	C907.446716,233.633347 907.242004,233.406540 907.234314,233.412994 
	C906.933533,233.667007 906.650635,233.942245 906.703979,234.567688 
M899.517151,273.411682 
	C899.517151,273.411682 899.583618,273.523041 899.517151,273.411682 
z"/>
<path fill="#5BB956" opacity="1.000000" stroke="none" 
	d="
M497.001038,273.009338 
	C498.394501,273.026489 499.787964,273.043610 502.040649,273.063873 
	C506.266083,273.010956 509.632263,272.954926 513.368652,272.989502 
	C514.492981,273.016907 515.247131,272.953674 516.395325,272.976868 
	C517.858887,273.011932 518.928467,272.960571 520.248962,272.978088 
	C520.667297,273.001251 520.834656,272.955536 521.400024,272.988861 
	C523.578918,273.377991 525.359802,273.688110 527.123535,274.355469 
	C528.419922,280.396698 532.694336,283.464722 537.809082,283.771698 
	C549.542725,284.476013 561.332642,284.322937 573.099548,284.316498 
	C575.184875,284.315369 577.632080,283.848175 579.260681,282.684418 
	C581.192627,281.303833 583.656067,278.892456 583.588928,277.009766 
	C583.527283,275.280304 580.521545,272.359100 578.622192,272.196991 
	C563.438721,270.901031 548.210022,270.136230 532.598511,269.128479 
	C531.123962,269.059357 530.046021,269.070526 528.708984,268.782532 
	C527.315735,268.320160 526.171631,268.207458 525.048889,267.985809 
	C514.154785,265.834930 503.061005,264.009613 498.010895,251.738373 
	C497.978821,251.309814 497.924500,251.143188 497.909180,250.652374 
	C497.895142,249.898407 497.842041,249.468597 497.872253,248.611023 
	C497.916565,246.445389 497.877563,244.707520 497.909668,242.649017 
	C498.011292,241.891602 498.041779,241.454788 498.329651,240.862015 
	C498.716614,240.135483 498.846191,239.564926 499.080811,238.857971 
	C499.185913,238.721588 499.198334,238.377441 499.470520,238.224182 
	C500.146637,237.377365 500.550537,236.683823 501.072601,235.897720 
	C501.190796,235.805161 501.219940,235.506302 501.495422,235.356552 
	C502.018036,234.874527 502.265137,234.542236 502.795044,234.060974 
	C504.584595,232.723587 506.091339,231.535156 507.856201,230.330048 
	C508.411987,230.142227 508.709656,229.971085 509.260437,229.791641 
	C509.751007,229.554703 509.988495,229.326065 510.417908,229.090118 
	C510.881378,229.025742 511.073425,228.870407 511.553101,228.565063 
	C512.613281,228.252884 513.306335,227.992477 514.266052,227.768829 
	C514.816345,227.601471 515.099915,227.397324 515.661743,227.191223 
	C516.295166,227.084625 516.650269,226.979996 517.330933,226.936096 
	C518.244263,226.742981 518.831970,226.489105 519.585205,226.246826 
	C519.750793,226.258438 520.016785,226.059952 520.276367,226.073959 
	C520.697144,226.023712 520.858276,225.959473 521.343994,225.924408 
	C522.094604,225.881577 522.520569,225.809555 523.217102,225.784729 
	C523.778198,225.626709 524.068787,225.421463 524.762085,225.199036 
	C526.773254,225.055038 528.381714,224.928238 530.250977,224.845520 
	C530.672791,224.817917 530.833801,224.746231 531.384399,224.659210 
	C532.259216,224.462341 532.744507,224.280807 533.343262,224.217926 
	C533.713074,224.347168 533.884399,224.231430 533.980042,223.998016 
	C533.989441,224.006653 533.998352,223.983322 534.336243,224.046692 
	C535.987183,224.004257 537.300171,223.898453 538.985291,223.845276 
	C540.060364,223.706619 540.763306,223.515350 541.824829,223.603943 
	C552.453735,223.938568 562.724243,223.993317 573.364136,224.179291 
	C575.815186,224.495239 577.896729,224.679947 580.302490,225.007965 
	C581.763672,225.373993 582.900696,225.596710 584.402527,225.897339 
	C585.757263,226.068802 586.747070,226.162369 587.741516,226.253540 
	C587.746094,226.251160 587.758484,226.268967 587.888733,226.487961 
	C588.343933,226.745758 588.668823,226.784561 589.316772,226.911133 
	C590.305542,227.059982 590.971313,227.121033 591.897766,227.426086 
	C593.081177,227.843079 594.003906,228.016068 594.935364,228.135315 
	C594.944153,228.081573 594.858337,228.148605 594.980164,228.368408 
	C595.418396,228.651932 595.734863,228.715652 596.310791,228.917236 
	C596.954895,229.108887 597.339478,229.162628 597.737061,229.212265 
	C597.749939,229.208160 597.759827,229.227890 597.877808,229.468079 
	C598.321411,229.831314 598.647095,229.954346 599.163208,230.294922 
	C599.908630,230.650299 600.463745,230.788116 601.172974,231.180420 
	C601.871155,231.648209 602.415222,231.861496 603.109924,232.370255 
	C608.080566,236.275101 611.258423,240.902435 612.037109,247.193375 
	C611.950562,247.423386 611.965149,247.914703 611.503662,247.987747 
	C605.212158,248.098907 599.381958,248.192688 593.552368,248.159378 
	C587.799133,248.126511 581.743347,249.611664 577.426270,243.590530 
	C576.301147,242.021240 573.322083,241.517059 571.085388,241.070465 
	C567.684570,240.391479 564.178345,239.779938 560.734741,239.864471 
	C551.451294,240.092392 542.158630,240.500488 532.916016,241.344208 
	C531.195618,241.501266 529.690796,244.020294 528.086487,245.449234 
	C529.592529,247.127213 530.796875,249.536453 532.675476,250.311676 
	C535.487793,251.472305 538.758911,251.773132 541.861328,251.936111 
	C556.640503,252.712540 571.490845,252.699387 586.194031,254.178406 
	C598.282715,255.394424 610.687378,257.534241 614.914856,272.355225 
	C614.984436,273.125793 615.084351,273.561523 615.093140,274.414429 
	C613.285034,279.885101 611.568054,284.938568 609.632202,290.094482 
	C609.413269,290.196930 609.206055,290.633606 608.924316,290.770203 
	C602.159546,293.595825 595.670532,296.270538 589.198181,298.984924 
	C587.768555,299.584534 586.397827,300.324585 585.000000,301.000000 
	C566.312439,301.000000 547.624878,301.000000 528.320679,300.629883 
	C524.692383,299.691254 521.680725,299.122772 518.607056,298.507996 
	C518.544922,298.461670 518.438354,298.349274 518.312012,298.041901 
	C513.804626,295.799164 509.423676,293.863800 505.018372,291.942474 
	C504.994019,291.956512 505.048859,291.968719 504.978577,291.709076 
	C504.605713,291.294769 504.303101,291.140106 504.000519,290.985443 
	C503.881287,290.680450 503.691193,290.429047 503.105835,289.800415 
	C500.929382,286.880768 499.077271,284.391876 497.194885,281.532135 
	C497.035706,280.429810 496.906860,279.698334 496.839020,278.608215 
	C496.638062,276.834412 496.376068,275.419281 496.249390,273.827606 
	C496.584076,273.431335 496.789520,273.217407 497.001038,273.009338 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M287.531616,1.000000 
	C288.072876,1.323331 288.112518,1.658669 288.224182,1.968002 
	C288.851227,3.705246 290.442780,6.605286 289.980499,6.979795 
	C286.959747,9.427214 287.050201,5.539390 285.592255,4.521541 
	C284.648163,3.862432 283.115753,4.046014 281.846985,3.851987 
	C282.020172,4.943861 281.819244,6.681157 282.422607,7.030896 
	C291.601135,12.351199 293.447784,21.448872 295.037842,30.757158 
	C295.266113,32.093533 295.829163,34.141842 296.688171,34.408554 
	C297.786133,34.749454 299.926453,33.974575 300.520050,33.007160 
	C301.017792,32.195911 300.083984,30.356970 299.534485,29.080454 
	C297.854279,25.177025 296.053802,21.325367 293.761353,16.264151 
	C296.097565,16.616285 298.166565,16.349096 298.642517,17.096437 
	C301.203094,21.117161 303.407257,25.369280 305.627930,29.597462 
	C305.975067,30.258369 305.887299,31.217142 305.810730,32.020073 
	C305.233887,38.068764 304.091370,44.111912 304.079620,50.158646 
	C303.952148,115.631210 304.041809,181.104202 303.930115,246.576813 
	C303.919861,252.585571 306.238892,258.728546 303.170441,264.693207 
	C302.832123,265.350769 303.964722,266.861542 304.569275,267.885590 
	C305.534515,269.520508 306.629150,271.079010 307.137695,273.090820 
	C303.316254,273.310913 299.989105,276.422668 296.950958,272.510529 
	C296.688873,272.173035 291.861450,273.970490 291.213257,275.596710 
	C288.880859,281.448395 284.330261,285.191650 279.909546,289.059540 
	C278.145264,290.603180 275.625092,291.300446 273.417206,292.313721 
	C271.700195,293.101715 269.933868,293.782288 267.869324,294.644257 
	C270.607605,296.833282 272.764038,298.557159 274.960236,300.640533 
	C183.666656,301.000000 92.333305,301.000000 1.000000,301.000000 
	C1.000000,290.574341 1.000000,280.255829 1.000001,269.468658 
	C1.729085,269.750122 2.726060,270.374268 3.147030,271.269318 
	C9.366096,284.491852 19.425051,292.208191 34.316963,292.222626 
	C109.255379,292.295258 184.194336,292.083588 259.132141,292.300537 
	C276.691284,292.351349 293.102264,278.033478 293.036072,256.779449 
	C292.906433,215.147110 293.003448,173.514084 293.005188,131.881332 
	C293.006470,101.406158 292.693787,70.926620 293.120392,40.457424 
	C293.383301,21.681086 286.213959,8.389282 268.113953,1.339422 
	C274.354401,1.000000 280.708801,1.000000 287.531616,1.000000 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M609.206055,290.633606 
	C609.206055,290.633606 609.413269,290.196930 610.038940,290.097992 
	C613.395325,288.504303 618.342102,287.106354 618.412476,285.495422 
	C618.577515,281.716888 616.408508,277.836456 615.184326,273.997253 
	C615.084351,273.561523 614.984436,273.125793 615.222656,272.204346 
	C617.181580,267.699493 613.094360,259.365021 608.953552,257.968445 
	C605.242798,256.716949 601.604675,255.250031 597.934448,253.878143 
	C598.005981,253.083511 598.077454,252.288864 598.148987,251.494232 
	C601.550598,251.084290 604.986145,250.845169 608.336792,250.178894 
	C609.644653,249.918823 610.761353,248.697952 611.965149,247.914703 
	C611.965149,247.914703 611.950562,247.423386 612.288574,246.946701 
	C613.821411,243.475586 615.016174,240.481171 616.341187,237.160339 
	C613.876221,235.830627 611.186340,234.379578 608.246399,232.793640 
	C608.266785,232.868240 608.023376,232.492249 608.115784,232.238647 
	C609.707153,227.872910 606.886536,225.255112 603.857544,223.472229 
	C603.206238,223.088852 600.377563,226.367706 598.583374,227.994354 
	C598.223450,228.320618 598.006775,228.804901 597.724121,229.216370 
	C597.339478,229.162628 596.954895,229.108887 596.320251,228.723755 
	C596.070251,228.392380 596.101257,228.005569 596.320435,227.778992 
	C593.265259,224.159225 589.856079,223.657120 587.736938,226.255936 
	C586.747070,226.162369 585.757263,226.068802 584.400269,225.684113 
	C584.033203,225.392975 584.011108,224.966980 584.010437,224.546463 
	C583.715088,220.724640 586.068726,220.992447 588.291199,220.995758 
	C598.445679,221.010895 608.604126,221.166382 618.752869,220.925125 
	C623.084473,220.822159 624.144958,222.615982 624.064880,226.574860 
	C623.866516,236.392441 624.275940,246.227386 623.896790,256.034302 
	C623.668274,261.945801 620.209900,268.132935 626.462280,273.025482 
	C626.781372,273.275208 626.941589,273.971313 626.883240,274.421692 
	C626.364624,278.427979 629.049438,279.830322 632.316406,280.969574 
	C632.533813,280.978058 632.965515,281.033386 633.021484,281.286072 
	C633.341431,281.738129 633.605408,281.937500 633.901123,282.378357 
	C634.082092,282.918457 634.144470,283.234161 634.175049,283.872833 
	C634.542175,284.428284 634.854492,284.677917 635.112305,284.931702 
	C635.057739,284.935913 635.085754,284.830170 635.171448,285.093719 
	C635.566833,285.538086 635.876465,285.718903 636.131958,285.898132 
	C636.077820,285.896545 636.150452,285.816101 636.107422,286.200623 
	C637.685181,287.749512 639.306030,288.913910 640.955811,290.443481 
	C644.200928,295.452423 649.062927,296.944519 654.265198,298.055145 
	C657.250305,298.692444 660.093140,299.996246 663.000000,301.000000 
	C645.312439,301.000000 627.624878,301.000000 609.467834,300.603333 
	C608.597473,297.279449 607.269592,294.182495 610.515015,291.922821 
	C610.078674,291.493103 609.642395,291.063354 609.206055,290.633606 
M621.680847,253.197861 
	C621.100037,252.248001 620.519226,251.298141 619.938477,250.348267 
	C619.408081,250.873581 618.877625,251.398895 618.347229,251.924210 
	C619.292603,252.512512 620.238037,253.100830 621.680847,253.197861 
M615.686096,259.525085 
	C616.024780,258.978363 616.363403,258.431610 616.702087,257.884888 
	C616.427917,257.750122 616.153809,257.615387 615.879639,257.480652 
	C615.674561,258.016052 615.469543,258.551483 615.686096,259.525085 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1050.000000,250.000000 
	C1050.000000,252.370560 1050.000000,254.741135 1049.793457,257.634613 
	C1049.327393,258.317383 1049.135742,258.536591 1048.546631,258.878662 
	C1035.785034,258.952301 1023.488892,258.987030 1011.192932,258.967804 
	C981.120789,258.920807 951.048584,258.848877 920.821899,258.596924 
	C920.119080,258.536987 919.570679,258.667084 918.683228,258.869476 
	C917.886536,258.895081 917.429016,258.848389 916.738342,258.571533 
	C915.643677,258.240112 914.782166,258.138885 913.703125,257.926117 
	C913.272644,257.561798 913.114014,257.278595 912.874023,256.736023 
	C912.422180,256.363403 912.105957,256.219818 911.489746,256.061432 
	C910.797485,255.966843 910.405212,255.887070 909.948425,255.412323 
	C909.466675,253.427307 909.049438,251.837265 908.945923,250.166046 
	C910.121643,250.037521 910.983704,249.990158 912.075928,249.904266 
	C912.610107,249.745789 912.835449,249.539886 913.441162,249.141464 
	C932.513733,249.012100 951.127441,248.956161 969.741028,248.974411 
	C996.162964,249.000320 1022.584900,249.080856 1049.122803,249.333252 
	C1049.439087,249.771667 1049.692871,249.928864 1050.000000,250.000000 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M758.000000,301.000000 
	C749.645752,301.000000 741.291504,301.000000 732.468994,300.612610 
	C730.805664,298.685669 735.292480,294.970642 730.057251,294.652527 
	C731.223022,292.984894 732.073975,291.095154 733.489807,290.454987 
	C737.224060,288.766571 738.364502,280.723663 744.915894,285.561371 
	C744.972229,285.602936 746.189880,284.130737 746.789917,283.310089 
	C746.874573,283.194366 746.526428,282.762238 746.283081,282.291992 
	C745.043701,282.291992 743.727844,282.291992 743.004150,282.291992 
	C743.718384,278.736115 744.388428,275.400299 745.421753,271.997467 
	C746.739929,269.814545 748.506042,267.702515 748.516052,265.582153 
	C748.574158,253.292923 748.183777,241.002151 748.045227,228.710754 
	C748.026978,227.091187 748.204529,225.248123 748.979431,223.905655 
	C749.697205,222.662109 751.326233,221.144653 752.572388,221.128067 
	C767.055115,220.935425 781.541382,221.026993 796.026550,220.984238 
	C798.698425,220.976349 800.148926,221.983719 799.530884,224.868622 
	C785.820190,224.943573 772.572449,224.943573 758.794189,224.943573 
	C758.794189,246.770386 758.794189,267.881989 758.423645,289.101746 
	C758.035400,293.139923 758.017700,297.069946 758.000000,301.000000 
M749.269470,277.096161 
	C749.768066,277.041290 750.266663,276.986420 750.765259,276.931580 
	C750.650574,276.485229 750.535828,276.038910 750.421143,275.592590 
	C749.995422,275.894653 749.569702,276.196716 749.269470,277.096161 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M907.998901,271.972961 
	C907.749023,271.176239 907.499207,270.379547 907.542236,269.290955 
	C909.223450,268.999023 910.611755,268.998901 912.053955,269.136719 
	C912.192871,269.324219 912.277832,269.373810 912.362793,269.423401 
	C912.241821,269.282593 912.120911,269.141785 912.114136,268.728302 
	C912.457031,267.914307 912.685730,267.372986 912.946289,266.871826 
	C912.978027,266.911987 912.877380,266.893066 913.354858,266.931213 
	C959.221558,266.979614 1004.610779,266.989807 1050.000000,267.000000 
	C1050.000000,269.370575 1050.000000,271.741119 1049.794922,274.633301 
	C1049.329956,275.310303 1049.139282,275.526733 1048.553223,275.875916 
	C1024.320190,275.959839 1000.551758,276.003357 976.783447,275.974640 
	C957.506836,275.951324 938.230347,275.841156 918.660278,275.529968 
	C916.813599,275.231873 915.260376,275.173340 913.418884,274.958496 
	C911.419983,273.859100 909.709473,272.916046 907.998901,271.972961 
z"/>
<path fill="#5FB65A" opacity="1.000000" stroke="none" 
	d="
M918.953796,275.769470 
	C938.230347,275.841156 957.506836,275.951324 976.783447,275.974640 
	C1000.551758,276.003357 1024.320190,275.959839 1048.792236,275.957642 
	C1049.663940,275.978485 1049.831909,275.989227 1050.000000,276.000000 
	C1050.000000,278.041656 1050.000000,280.083344 1049.749023,282.580261 
	C1049.339355,283.090637 1049.180908,283.145752 1049.022461,283.200867 
	C1025.095825,283.136810 1001.169128,283.052002 977.242371,283.015930 
	C961.122375,282.991608 945.002258,283.036041 927.961060,283.042969 
	C922.412109,283.177917 917.433167,282.310791 913.261475,283.762787 
	C909.552429,285.053711 907.006409,284.911865 904.117615,282.855865 
	C904.117615,282.855865 904.082520,282.976074 904.084351,282.577026 
	C902.002136,278.474030 904.711731,275.931427 906.282349,273.112915 
	C906.702393,272.816742 907.122437,272.520569 907.770630,272.098694 
	C909.709473,272.916046 911.419983,273.859100 913.709351,275.226501 
	C915.843384,275.690399 917.398621,275.729919 918.953796,275.769470 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M911.845703,249.942810 
	C910.983704,249.990158 910.121643,250.037521 908.818726,250.166870 
	C908.377869,250.248871 908.161011,250.386292 907.887817,250.296829 
	C907.249695,250.256393 906.884766,250.305405 906.519836,250.354416 
	C906.519836,250.354416 906.418213,250.340408 906.357178,250.015945 
	C905.217285,248.120300 904.138550,246.549118 903.111511,244.662491 
	C903.181213,243.921677 903.199219,243.496323 903.453674,242.850861 
	C904.315918,241.499527 904.941650,240.368317 905.567444,239.237091 
	C905.906494,239.184662 906.245483,239.132248 907.046631,238.932053 
	C907.508789,238.784271 907.927246,238.512268 907.927246,238.512268 
	C908.704041,238.574295 909.480835,238.636322 910.869141,238.812027 
	C911.653625,238.918961 911.826599,238.912201 911.999512,238.905441 
	C912.418945,239.238525 912.838440,239.571609 913.641357,240.396133 
	C915.886108,241.249939 917.746948,241.925797 919.608887,241.928696 
	C963.072571,241.996552 1006.536255,241.991562 1050.000000,242.000000 
	C1050.000000,244.041672 1050.000000,246.083328 1049.745605,248.553482 
	C1049.329956,249.033997 1049.168457,249.086029 1049.006836,249.138062 
	C1022.584900,249.080856 996.162964,249.000320 969.741028,248.974411 
	C951.127441,248.956161 932.513733,249.012100 913.192322,249.130280 
	C912.138489,249.346100 911.925537,249.585159 911.845703,249.942810 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M916.971497,258.801697 
	C917.429016,258.848389 917.886536,258.895081 919.006592,258.940857 
	C920.104919,258.888916 920.540649,258.837952 920.976440,258.786987 
	C951.048584,258.848877 981.120789,258.920807 1011.192932,258.967804 
	C1023.488892,258.987030 1035.785034,258.952301 1048.787598,258.956604 
	C1049.662720,258.980682 1049.831299,258.990326 1050.000000,259.000000 
	C1050.000000,261.041656 1050.000000,263.083344 1049.531494,265.546661 
	C1005.381775,265.966370 961.700378,265.953857 918.019104,266.001740 
	C916.304871,266.003632 914.591248,266.582855 912.877380,266.893066 
	C912.877380,266.893066 912.978027,266.911987 912.586914,266.907440 
	C910.132568,266.917603 908.069458,266.932312 906.006287,266.947052 
	C905.802307,266.704071 905.575195,266.485107 905.107239,265.883942 
	C904.603027,265.298492 904.316528,265.119232 904.030090,264.939972 
	C904.030090,264.939972 904.005310,265.000122 903.980530,264.631744 
	C904.011536,262.194763 904.067261,260.126190 904.123047,258.057587 
	C904.123047,258.057587 904.045532,258.026703 904.308960,257.977478 
	C904.749939,257.615082 904.927429,257.301880 905.104919,256.988708 
	C905.385803,256.967682 905.643494,256.879120 906.393982,256.486511 
	C907.944336,256.102386 908.978638,255.954849 910.013000,255.807281 
	C910.405212,255.887070 910.797485,255.966843 911.613770,256.300720 
	C912.361816,256.691528 912.685791,256.828278 913.009705,256.964996 
	C913.114014,257.278595 913.272644,257.561798 913.891235,258.213928 
	C915.188477,258.676056 916.079956,258.738861 916.971497,258.801697 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1050.000000,220.550018 
	C1034.224487,221.000610 1018.448669,220.957932 1002.673523,221.040436 
	C999.723877,221.055862 996.730286,222.028503 993.841492,221.732895 
	C990.813721,221.423080 987.887756,220.118271 983.976257,218.972748 
	C986.252625,214.904083 987.856079,212.038208 989.831543,209.596588 
	C989.786438,212.895828 989.369385,215.770813 988.906128,218.964493 
	C1009.210022,218.964493 1028.883545,218.964493 1049.716675,218.964493 
	C1041.112915,207.376099 1034.449341,195.833389 1033.654541,181.993118 
	C1035.679199,180.994705 1037.339600,179.997971 1039.000000,179.001221 
	C1039.000000,179.445389 1039.000000,179.889572 1038.938721,181.026047 
	C1038.532349,183.803741 1037.867065,185.897369 1037.920288,187.972519 
	C1037.992432,190.779419 1038.395996,193.627136 1039.077026,196.352478 
	C1039.568237,198.318146 1040.655762,200.160339 1041.635132,201.972321 
	C1043.056030,204.601257 1044.436890,207.288162 1046.203003,209.678162 
	C1047.020142,210.783691 1048.707275,211.246048 1050.000000,212.000015 
	C1050.000000,214.700012 1050.000000,217.400024 1050.000000,220.550018 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1050.000000,241.666672 
	C1006.536255,241.991562 963.072571,241.996552 919.608887,241.928696 
	C917.746948,241.925797 915.886108,241.249939 913.891846,240.491364 
	C958.860229,240.063431 1003.961487,240.031708 1049.531372,240.000000 
	C1050.000000,240.444443 1050.000000,240.888901 1050.000000,241.666672 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M25.531321,1.000000 
	C14.940003,5.450913 6.810020,13.028675 1.335286,23.898937 
	C1.000000,16.403925 1.000000,8.807849 1.000000,1.000000 
	C9.019642,1.000000 17.041142,1.000000 25.531321,1.000000 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1050.000000,211.531616 
	C1048.707275,211.246048 1047.020142,210.783691 1046.203003,209.678162 
	C1044.436890,207.288162 1043.056030,204.601257 1041.635132,201.972321 
	C1040.655762,200.160339 1039.568237,198.318146 1039.077026,196.352478 
	C1038.395996,193.627136 1037.992432,190.779419 1037.920288,187.972519 
	C1037.867065,185.897369 1038.532349,183.803741 1038.938721,181.359192 
	C1040.165039,184.426361 1041.330078,187.852722 1042.768311,192.082626 
	C1044.310181,192.082626 1046.781860,192.082626 1049.626831,192.041321 
	C1050.000000,198.354401 1050.000000,204.708786 1050.000000,211.531616 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M913.354858,266.931213 
	C914.591248,266.582855 916.304871,266.003632 918.019104,266.001740 
	C961.700378,265.953857 1005.381775,265.966370 1049.531494,265.984161 
	C1050.000000,266.000000 1050.000000,266.500000 1050.000000,266.750000 
	C1004.610779,266.989807 959.221558,266.979614 913.354858,266.931213 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M608.924316,290.770203 
	C609.642395,291.063354 610.078674,291.493103 610.515015,291.922821 
	C607.269592,294.182495 608.597473,297.279449 608.999146,300.603333 
	C601.312439,301.000000 593.624817,301.000000 585.468628,301.000000 
	C586.397827,300.324585 587.768555,299.584534 589.198181,298.984924 
	C595.670532,296.270538 602.159546,293.595825 608.924316,290.770203 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M498.399994,301.000000 
	C497.384613,298.650269 496.769196,296.300568 496.176239,294.036469 
	C498.668243,293.439117 501.014648,292.876678 503.941040,292.155457 
	C504.696960,291.987366 504.872925,291.978027 505.048859,291.968719 
	C505.048859,291.968719 504.994019,291.956512 505.011383,292.324402 
	C508.400665,297.180542 513.299744,298.048798 518.438354,298.349274 
	C518.438354,298.349274 518.544922,298.461670 518.388794,298.885406 
	C518.155090,299.872772 518.077576,300.436371 518.000000,301.000000 
	C513.310730,301.000000 508.621490,301.000000 503.608490,300.671936 
	C504.585266,297.364685 503.462494,296.269287 499.403687,296.066132 
	C500.580170,298.301880 501.290070,299.650940 502.000000,301.000000 
	C500.933319,301.000000 499.866669,301.000000 498.399994,301.000000 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M850.000000,301.000000 
	C819.979126,301.000000 789.958191,301.000000 759.478455,300.713745 
	C759.038818,300.237488 759.058105,300.047485 759.539185,299.891663 
	C789.990662,299.904480 819.980286,299.883148 849.986206,300.146118 
	C850.001648,300.620331 850.000793,300.810181 850.000000,301.000000 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M663.449951,301.000000 
	C660.093140,299.996246 657.250305,298.692444 654.265198,298.055145 
	C649.062927,296.944519 644.200928,295.452423 641.023132,290.374084 
	C641.061401,289.939545 641.195862,289.803375 641.491943,289.845825 
	C642.180237,289.952087 642.572449,290.015900 643.138916,290.370667 
	C647.542053,292.441223 651.770996,294.220856 655.999939,296.000244 
	C656.000000,296.000000 655.999756,296.000519 656.160522,296.246613 
	C656.872498,296.608643 657.423584,296.724579 658.302490,296.924255 
	C659.079956,297.025238 659.529541,297.042480 660.249146,297.332214 
	C663.668518,298.135406 666.817932,298.666138 670.148682,299.407501 
	C670.886658,300.078735 671.443298,300.539368 672.000000,301.000000 
	C669.299988,301.000000 666.599976,301.000000 663.449951,301.000000 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M726.000000,301.000000 
	C721.310730,301.000000 716.621521,301.000000 711.468628,300.678894 
	C711.028442,299.930450 711.051880,299.503113 711.480042,299.047791 
	C717.923157,297.679901 723.961548,296.339935 729.964966,295.344757 
	C728.619873,297.459686 727.309937,299.229828 726.000000,301.000000 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M850.460632,301.000000 
	C850.000793,300.810181 850.001648,300.620331 850.217102,300.038635 
	C850.763794,299.662048 851.095947,299.677307 851.722168,299.739532 
	C852.342102,299.585510 852.667908,299.384521 853.409058,299.105530 
	C861.192322,298.565796 867.691895,295.678436 873.856750,291.855988 
	C873.900024,295.415344 871.429199,296.399628 868.600891,297.316589 
	C866.359619,298.043182 864.282104,299.274719 862.065918,300.641022 
	C858.307068,301.000000 854.614197,301.000000 850.460632,301.000000 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M672.468628,301.000000 
	C671.443298,300.539368 670.886658,300.078735 670.569336,299.341248 
	C680.522705,299.307831 690.236633,299.551270 699.970520,300.095703 
	C699.993591,300.597809 699.996765,300.798920 700.000000,301.000000 
	C690.979126,301.000000 681.958191,301.000000 672.468628,301.000000 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M726.428467,301.000000 
	C727.309937,299.229828 728.619873,297.459686 729.964966,295.344788 
	C735.292480,294.970642 730.805664,298.685669 732.000366,300.612610 
	C730.285645,301.000000 728.571289,301.000000 726.428467,301.000000 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M759.077332,299.857513 
	C759.058105,300.047485 759.038818,300.237488 759.009766,300.713745 
	C759.000000,301.000000 758.500000,301.000000 758.250000,301.000000 
	C758.017700,297.069946 758.035400,293.139923 758.502502,289.552032 
	C758.993713,293.215302 759.035522,296.536407 759.077332,299.857513 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M700.456848,301.000000 
	C699.996765,300.798920 699.993591,300.597809 700.208679,299.999023 
	C700.761414,299.626343 701.095947,299.651306 701.721436,299.727905 
	C702.339355,299.585785 702.666321,299.392059 703.428772,299.126953 
	C706.206726,298.994324 708.549133,298.933044 710.937195,298.923035 
	C710.982788,298.974365 711.075317,299.075775 711.075317,299.075775 
	C711.051880,299.503113 711.028442,299.930450 711.002502,300.678894 
	C707.637878,301.000000 704.275818,301.000000 700.456848,301.000000 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M502.250000,301.000000 
	C501.290070,299.650940 500.580170,298.301880 499.403687,296.066132 
	C503.462494,296.269287 504.585266,297.364685 503.142365,300.671936 
	C503.000000,301.000000 502.500000,301.000000 502.250000,301.000000 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M518.454102,301.000000 
	C518.077576,300.436371 518.155090,299.872772 518.450928,298.931702 
	C521.680725,299.122772 524.692383,299.691254 527.851990,300.629883 
	C524.969421,301.000000 521.938782,301.000000 518.454102,301.000000 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1049.139648,283.384338 
	C1049.180908,283.145752 1049.339355,283.090637 1049.749023,283.017761 
	C1050.000000,283.000000 1050.000000,283.500000 1050.000000,283.750000 
	C1049.706177,283.935211 1049.458496,283.791138 1049.139648,283.384338 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M1049.122803,249.333252 
	C1049.168457,249.086029 1049.329956,249.033997 1049.745605,248.990982 
	C1050.000000,249.000000 1050.000000,249.500000 1050.000000,249.750000 
	C1049.692871,249.928864 1049.439087,249.771667 1049.122803,249.333252 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M1050.000000,258.750000 
	C1049.831299,258.990326 1049.662720,258.980682 1049.253174,258.893127 
	C1049.135742,258.536591 1049.327393,258.317383 1049.793457,258.078766 
	C1050.000000,258.000000 1050.000000,258.500000 1050.000000,258.750000 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M1050.000000,275.750000 
	C1049.831909,275.989227 1049.663940,275.978485 1049.256958,275.885986 
	C1049.139282,275.526733 1049.329956,275.310303 1049.794922,275.077454 
	C1050.000000,275.000000 1050.000000,275.500000 1050.000000,275.750000 
z"/>
<path fill="#FAFDF9" opacity="1.000000" stroke="none" 
	d="
M174.855026,209.247299 
	C176.179306,210.724976 177.538971,212.172653 178.821320,213.685867 
	C182.871918,218.465607 181.509247,224.085938 175.433640,224.945557 
	C167.098282,226.124863 158.586441,226.655243 150.167664,226.502670 
	C138.349365,226.288513 126.722290,224.261719 115.532074,220.192886 
	C114.957756,219.984070 114.241348,220.166000 113.300079,220.166000 
	C113.300079,231.042313 113.300079,241.774460 113.300079,253.044525 
	C123.633186,253.044525 133.749390,252.985748 143.863831,253.092316 
	C145.979233,253.114609 148.453934,253.189667 150.090836,254.264328 
	C151.544571,255.218750 152.858231,257.649017 152.742310,259.324646 
	C152.652664,260.620361 150.275787,262.779358 148.883163,262.805298 
	C135.058640,263.062866 121.224945,263.097931 107.399414,262.865112 
	C103.292503,262.795990 103.331520,258.883636 103.230766,256.074951 
	C102.974083,248.919144 103.128136,241.748352 103.131393,234.583557 
	C103.133278,230.417938 103.166855,226.252319 103.176422,222.086685 
	C103.195015,213.994553 101.318985,211.612030 92.517189,208.925400 
	C92.373421,210.227386 92.121971,211.457138 92.119415,212.687393 
	C92.089272,227.183685 92.127640,241.680161 92.079041,256.176331 
	C92.058273,262.371521 91.381828,262.978912 85.079514,262.988068 
	C72.915733,263.005798 60.750885,262.909454 48.588757,263.053314 
	C45.021137,263.095520 42.349796,261.955444 42.362022,258.310242 
	C42.373474,254.895523 45.085930,253.796295 48.350483,253.832031 
	C58.003082,253.937683 67.661682,253.761505 77.308159,254.037415 
	C81.516792,254.157791 83.192253,253.091492 83.104492,248.497894 
	C82.824440,233.839966 83.153076,219.170303 82.860909,204.512833 
	C82.806808,201.798813 81.626183,198.511963 79.822441,196.529495 
	C71.279984,187.140549 65.052223,176.484512 60.839729,164.580093 
	C59.919182,161.978638 59.060368,159.355331 58.100540,156.023026 
	C57.709324,154.506699 57.390690,153.709427 57.072052,152.912170 
	C57.072052,152.912170 57.027966,153.003479 57.067230,152.581192 
	C56.795902,150.928436 56.485306,149.697952 56.174709,148.467484 
	C56.174709,148.467484 56.291920,148.203812 56.207565,147.832886 
	C55.972000,146.956467 55.820786,146.450974 55.723190,145.754120 
	C55.776806,145.562775 55.699249,145.168198 55.844536,144.828873 
	C55.610916,142.001251 55.232002,139.512970 54.906197,136.587402 
	C54.913536,134.098709 54.867771,132.047272 54.882523,129.746613 
	C54.889252,129.335037 54.835464,129.172729 54.831200,128.576126 
	C54.839478,126.106049 54.798237,124.070274 54.852669,121.597580 
	C54.901745,118.789970 54.855145,116.419281 54.886986,113.673981 
	C54.990337,112.536591 55.015244,111.773819 55.283699,110.726929 
	C56.009243,107.966873 56.491241,105.490936 57.078266,102.922104 
	C57.183292,102.829216 57.184856,102.548798 57.521996,102.419495 
	C60.241673,101.849785 62.624207,101.409370 64.992867,100.979950 
	C64.978989,100.990959 64.990227,101.024559 65.232666,101.260628 
	C74.701904,110.522087 83.916351,119.560143 93.159393,128.568878 
	C100.638992,135.858887 108.153008,143.113556 115.788330,150.588852 
	C116.142227,151.068069 116.425468,151.216721 116.808403,151.124878 
	C116.842201,151.009964 116.735092,151.224243 116.770187,151.433670 
	C116.805290,151.643097 117.068077,151.979050 117.196938,152.270172 
	C118.197899,152.364410 119.069992,152.167526 120.212379,151.855347 
	C120.989326,151.147537 121.495987,150.555008 122.277031,149.865433 
	C123.041283,149.184738 123.531158,148.601105 124.010513,148.008728 
	C124.000000,148.000000 124.017883,148.020523 124.289017,147.900482 
	C124.836861,147.283295 125.113571,146.786148 125.321625,146.232864 
	C125.252968,146.176712 125.322571,146.339874 125.578018,146.273346 
	C126.002068,145.911942 126.170677,145.617065 126.355957,145.263016 
	C126.372635,145.203827 126.302086,145.304459 126.561424,145.204926 
	C126.969818,144.768036 127.118874,144.430664 127.563568,143.945312 
	C128.918106,142.752426 130.025085,141.751404 131.026764,140.654236 
	C133.861572,137.549194 137.262833,136.766830 140.811783,138.931976 
	C145.326981,141.686630 143.194321,145.392563 141.374374,148.959229 
	C136.427505,153.227798 131.795319,157.414139 126.538925,162.164597 
	C133.868973,169.324203 140.128860,175.451813 146.403564,181.564240 
	C155.881958,190.797501 165.370682,200.020157 174.855026,209.247299 
M107.928627,162.277466 
	C103.901169,156.309860 102.004234,156.432800 98.709206,162.481567 
	C96.734749,166.106125 94.692497,169.854172 91.923981,172.846619 
	C88.153244,176.922348 89.126938,180.791931 91.007362,185.766937 
	C92.332748,188.178619 93.658134,190.590317 95.271049,193.576523 
	C96.506737,194.393707 97.742416,195.210892 99.280579,196.633667 
	C102.526375,198.741776 105.772171,200.849869 109.624069,203.296829 
	C112.411674,204.545013 115.199280,205.793198 118.642799,207.358246 
	C121.755699,208.265564 124.868599,209.172897 128.675171,210.406540 
	C138.689240,213.113388 148.762436,215.163879 160.747559,213.367828 
	C152.487793,205.366211 145.135193,198.208542 137.718811,191.117599 
	C137.043808,190.472214 135.959183,190.255249 134.989349,189.183487 
	C133.399414,187.716019 131.809464,186.248535 129.875427,184.201065 
	C128.259720,182.460602 126.644020,180.720123 124.738876,178.375336 
	C122.493439,176.249405 120.248001,174.123474 117.729065,171.407822 
	C116.152916,169.933517 114.576767,168.459229 112.719353,166.352051 
	C111.148857,165.229248 109.578369,164.106461 107.928627,162.277466 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M161.954926,79.050919 
	C166.299515,75.252335 170.713501,71.529800 174.975372,67.640564 
	C186.661758,56.976006 202.114395,56.358814 214.018295,66.168800 
	C224.858978,75.102600 226.894989,92.031204 218.024521,102.889458 
	C213.834595,108.018326 208.836365,112.486855 203.748840,117.693237 
	C197.638412,123.561783 191.983536,128.991653 186.379654,134.372559 
	C184.066208,132.426697 182.556946,131.157227 180.892715,129.579254 
	C179.339233,126.500702 177.940704,123.730675 176.633148,120.611595 
	C180.640961,116.120392 184.557770,111.978233 188.739380,107.611046 
	C193.283325,103.182716 197.917648,99.270554 201.698273,94.658585 
	C203.718491,92.194138 205.645340,88.253807 205.102783,85.468811 
	C204.523132,82.493294 201.472946,79.189423 198.625092,77.656586 
	C194.431259,75.399284 190.166367,77.495094 186.877274,80.685219 
	C182.458084,84.971428 178.235306,89.460144 173.625122,94.005264 
	C169.546860,97.763077 165.771698,101.377579 161.759125,104.981445 
	C161.521744,104.970802 161.046509,104.975327 160.841187,104.713120 
	C159.805847,104.140839 158.975815,103.830772 157.909332,103.314087 
	C156.294174,102.656120 154.915451,102.204765 153.237457,101.483902 
	C150.292007,100.790962 147.645874,100.367554 144.788956,99.844925 
	C144.278870,99.626839 143.972534,99.604584 143.636566,99.416611 
	C143.882568,98.509796 144.151199,97.865288 144.682220,97.052139 
	C146.392456,95.389534 147.840286,93.895576 149.603699,92.278076 
	C153.955414,87.761208 157.991547,83.367897 162.008484,78.992706 
	C161.989273,79.010826 161.954926,79.050926 161.954926,79.050919 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M203.881119,155.221466 
	C207.377350,158.750519 210.683731,162.495499 214.405518,165.768005 
	C225.398224,175.433701 225.433624,189.284119 220.117737,199.209915 
	C214.169312,210.316849 201.595474,215.755112 188.263351,212.549210 
	C184.410965,211.622833 180.897293,209.287781 176.951691,206.993683 
	C167.769135,197.717194 158.931335,188.973816 149.916046,180.417435 
	C147.194504,177.834473 147.110992,175.829346 150.040619,173.579941 
	C151.084351,172.778564 152.045731,171.869934 153.255615,170.906555 
	C153.730469,170.612549 153.911453,170.362274 154.003723,170.024933 
	C153.997971,169.997864 154.052032,170.009674 154.260010,169.909363 
	C154.727814,169.624054 154.910172,169.380905 155.150085,168.784515 
	C156.263687,167.671509 157.242249,166.853638 158.325653,165.996460 
	C158.430496,165.957138 158.812637,165.725571 159.110413,165.667847 
	C160.589722,166.787964 161.771255,167.965805 162.965271,169.521820 
	C163.019363,175.865219 168.990372,175.274902 172.182373,178.101852 
	C172.759415,178.319626 172.960724,178.566559 173.113342,179.168762 
	C173.482117,179.600555 173.756958,179.793381 174.015289,179.993713 
	C173.998779,180.001221 174.013824,179.968201 174.117508,180.202789 
	C174.503448,180.618637 174.785706,180.799911 175.295624,181.054779 
	C175.804855,181.318970 175.971680,181.582260 176.113144,182.156479 
	C176.467514,182.596725 176.732544,182.798767 177.263214,183.121185 
	C178.019485,183.826385 178.510101,184.411194 179.115295,185.225067 
	C179.517029,185.627167 179.804214,185.800232 180.378906,186.099548 
	C181.786163,187.454224 182.905899,188.682617 184.117828,190.152740 
	C184.483795,190.593399 184.757584,190.792328 185.344772,191.129425 
	C187.288773,192.405594 188.850800,193.661392 190.563583,194.658157 
	C194.682327,197.055084 199.387665,196.299637 202.840485,192.825058 
	C205.957153,189.688751 206.955444,184.461716 203.904770,181.047806 
	C198.751450,175.280930 192.858932,170.174606 187.218048,164.450500 
	C184.190338,161.057831 181.221832,158.005203 178.128967,154.637115 
	C177.906372,153.882935 177.808136,153.444183 177.859863,152.778168 
	C178.004959,152.369461 178.000107,152.188004 178.132751,151.689026 
	C178.605423,150.274750 178.940628,149.178040 179.494751,147.834564 
	C179.784119,146.721725 179.854584,145.855621 180.006592,144.776917 
	C180.088135,144.564301 180.059540,144.109802 180.222382,143.988464 
	C180.583389,143.618347 180.585464,143.367996 180.557861,142.782837 
	C180.750412,141.957199 180.776550,141.464828 180.901947,140.568329 
	C180.971527,138.781464 180.941864,137.398712 180.969666,135.703873 
	C181.052582,134.969421 181.078003,134.547089 181.399490,134.252731 
	C189.090744,141.327637 196.485931,148.274551 203.881119,155.221466 
z"/>
<path fill="#88C65F" opacity="1.000000" stroke="none" 
	d="
M90.992012,23.043373 
	C98.346970,23.034616 105.701927,23.025862 113.582207,23.362129 
	C114.995949,24.541538 115.884361,25.375921 116.867760,26.299507 
	C110.962257,30.942322 105.553413,35.923985 99.427834,39.761597 
	C95.135399,42.450760 90.674278,44.348351 89.323776,49.845139 
	C89.173607,50.456360 87.531303,50.575077 86.836075,51.204109 
	C85.317520,52.578064 83.963127,54.133446 82.187943,55.983315 
	C78.117767,52.111668 76.167381,58.412804 73.013618,59.911911 
	C69.295761,61.679161 67.890999,66.194839 63.613838,68.404678 
	C57.165234,71.736412 53.308258,78.530281 46.432178,82.398552 
	C38.741894,86.724861 33.025330,94.559601 26.473978,100.844391 
	C25.140406,99.737564 24.089354,98.865227 23.038303,97.992882 
	C23.030428,96.607712 23.022552,95.222542 23.317524,93.379730 
	C23.424145,92.279312 23.227922,91.636536 23.031698,90.993767 
	C23.025850,87.630524 23.020002,84.267281 23.414303,80.449249 
	C27.661848,78.040260 31.833549,76.533325 35.283882,74.031517 
	C41.078949,69.829567 46.705486,65.299347 51.884205,60.363049 
	C62.859314,49.901688 77.579277,43.626942 85.768929,30.042738 
	C86.162994,29.389111 87.521080,29.340645 88.405098,28.953022 
	C89.870308,28.310566 91.315063,27.621441 92.874542,26.903097 
	C92.103699,25.322655 91.547859,24.183014 90.992012,23.043373 
z"/>
<path fill="#80C35D" opacity="1.000000" stroke="none" 
	d="
M23.022593,98.457962 
	C24.089354,98.865227 25.140406,99.737564 26.473978,100.844391 
	C33.025330,94.559601 38.741894,86.724861 46.432178,82.398552 
	C53.308258,78.530281 57.165234,71.736412 63.613838,68.404678 
	C67.890999,66.194839 69.295761,61.679161 73.013618,59.911911 
	C76.167381,58.412804 78.117767,52.111668 82.187943,55.983315 
	C83.963127,54.133446 85.317520,52.578064 86.836075,51.204109 
	C87.531303,50.575077 89.173607,50.456360 89.323776,49.845139 
	C90.674278,44.348351 95.135399,42.450760 99.427834,39.761597 
	C105.553413,35.923985 110.962257,30.942322 116.867760,26.299507 
	C115.884361,25.375921 114.995949,24.541538 114.050278,23.372803 
	C124.011162,23.033297 134.029297,23.028143 144.515167,23.393309 
	C143.651062,24.963770 142.136398,27.336498 141.018814,27.162256 
	C136.025284,26.383711 133.795227,27.751963 132.916382,32.663921 
	C132.661850,34.086464 131.636002,35.727863 130.460846,36.550800 
	C128.342270,38.034412 125.580261,38.638710 123.561951,40.217464 
	C119.829536,43.137020 116.808907,47.110523 112.809898,49.507256 
	C108.258583,52.235008 105.437622,55.793034 103.357422,60.496735 
	C96.409248,58.204700 89.673698,58.048141 83.653442,61.218830 
	C78.700821,63.827236 74.514351,67.890358 69.967659,70.972687 
	C69.722061,70.263382 69.497131,69.890129 69.272194,69.516876 
	C69.000893,69.764015 68.729591,70.011162 68.458290,70.258301 
	C68.904373,70.640785 69.350449,71.023277 69.740196,71.772667 
	C68.189705,75.444481 66.695557,78.749382 64.822731,82.042633 
	C61.469109,83.940216 58.511868,85.877800 55.514595,87.751343 
	C52.000957,89.947647 48.134808,91.707108 44.985283,94.332352 
	C38.426331,99.799477 32.266315,105.743401 25.854761,111.391968 
	C25.225048,111.946739 23.987488,111.811554 23.032070,111.996620 
	C23.023674,107.638756 23.015278,103.280899 23.022593,98.457962 
z"/>
<path fill="#74BF59" opacity="1.000000" stroke="none" 
	d="
M103.763939,60.581749 
	C105.437622,55.793034 108.258583,52.235008 112.809898,49.507256 
	C116.808907,47.110523 119.829536,43.137020 123.561951,40.217464 
	C125.580261,38.638710 128.342270,38.034412 130.460846,36.550800 
	C131.636002,35.727863 132.661850,34.086464 132.916382,32.663921 
	C133.795227,27.751963 136.025284,26.383711 141.018814,27.162256 
	C142.136398,27.336498 143.651062,24.963770 144.982666,23.400429 
	C154.338699,23.028799 163.694992,23.020367 173.521942,23.408745 
	C172.825790,25.301119 171.908890,27.139809 170.434769,28.213430 
	C168.105927,29.909578 165.188522,30.811815 162.907867,32.556732 
	C159.242569,35.361019 155.893570,38.578701 152.410919,41.621704 
	C151.980728,41.326618 151.550522,41.031536 151.120316,40.736450 
	C151.342850,42.573761 151.565369,44.411072 151.819519,46.509529 
	C150.792587,46.213768 150.162537,46.032318 149.292755,45.615509 
	C147.579468,41.382168 146.105942,37.384174 144.748825,33.702030 
	C139.055649,47.797874 133.373306,61.866940 127.357368,75.955521 
	C125.973244,76.358078 124.922714,76.741119 123.595840,76.940506 
	C118.151817,72.016563 112.984131,67.276291 107.828178,62.307152 
	C107.555077,62.080837 107.270226,62.083393 106.683167,62.060081 
	C105.508621,61.550060 104.636276,61.065907 103.763939,60.581749 
M141.567657,32.296085 
	C141.295242,32.010529 141.022827,31.724972 140.750412,31.439413 
	C140.632309,31.552441 140.405243,31.757441 140.411835,31.765230 
	C140.666061,32.066441 140.941635,32.349625 141.567657,32.296085 
M150.482666,38.589756 
	C150.482666,38.589756 150.415588,38.477551 150.482666,38.589756 
z"/>
<path fill="#92CA63" opacity="1.000000" stroke="none" 
	d="
M90.524338,23.027950 
	C91.547859,24.183014 92.103699,25.322655 92.874542,26.903097 
	C91.315063,27.621441 89.870308,28.310566 88.405098,28.953022 
	C87.521080,29.340645 86.162994,29.389111 85.768929,30.042738 
	C77.579277,43.626942 62.859314,49.901688 51.884205,60.363049 
	C46.705486,65.299347 41.078949,69.829567 35.283882,74.031517 
	C31.833549,76.533325 27.661848,78.040260 23.433262,79.990860 
	C23.044340,73.965134 23.036608,67.943008 23.432581,61.456711 
	C28.828119,57.780331 35.326591,55.619137 38.467918,51.116028 
	C43.510323,43.887711 50.809093,40.458618 57.409283,35.704201 
	C62.112423,32.316307 67.124367,29.116484 68.984840,23.080746 
	C76.008781,23.058006 83.032730,23.035267 90.524338,23.027950 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M127.690956,75.936005 
	C133.373306,61.866940 139.055649,47.797874 144.748825,33.702030 
	C146.105942,37.384174 147.579468,41.382168 149.268555,46.051079 
	C151.688614,52.530426 153.893127,58.338848 156.097656,64.147270 
	C158.035873,68.863487 159.974075,73.579712 161.933609,78.673431 
	C161.954926,79.050926 161.989273,79.010826 161.637085,78.944885 
	C157.185776,82.535912 153.086624,86.192894 148.709991,89.898422 
	C148.246231,89.970802 148.059952,89.994621 147.784821,89.837021 
	C147.509949,89.432037 147.271622,89.326889 146.858429,89.160492 
	C146.735825,88.980820 146.393112,88.712875 146.327209,88.487671 
	C146.120560,87.958183 145.888199,87.763084 145.527954,87.589066 
	C145.491699,87.500954 145.357925,87.365265 145.258728,87.096680 
	C144.455093,87.219406 143.750641,87.610703 142.866837,88.274033 
	C141.794876,89.010201 140.902267,89.474350 139.882690,89.633194 
	C135.734131,84.863922 131.712540,80.399963 127.690956,75.936005 
z"/>
<path fill="#67BC55" opacity="1.000000" stroke="none" 
	d="
M156.519867,64.103577 
	C153.893127,58.338848 151.688614,52.530426 149.508301,46.286430 
	C150.162537,46.032318 150.792587,46.213768 151.819519,46.509529 
	C151.565369,44.411072 151.342850,42.573761 151.120316,40.736450 
	C151.550522,41.031536 151.980728,41.326618 152.410919,41.621704 
	C155.893570,38.578701 159.242569,35.361019 162.907867,32.556732 
	C165.188522,30.811815 168.105927,29.909578 170.434769,28.213430 
	C171.908890,27.139809 172.825790,25.301119 173.989777,23.411007 
	C182.237839,23.525572 190.805527,21.391348 199.862869,26.070662 
	C185.073059,37.010021 174.043137,51.149441 158.934631,58.537407 
	C158.157639,60.690884 157.549850,62.375385 156.519867,64.103577 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M207.000519,121.941071 
	C220.399109,129.142853 234.764511,133.485748 249.745819,136.399109 
	C233.922867,139.401245 218.796967,144.131500 204.098801,151.663254 
	C199.507141,147.919434 195.494919,144.306641 191.221237,140.458420 
	C193.510010,138.537354 194.691132,137.545990 196.075500,136.384048 
	C194.633270,135.137665 193.429214,134.097107 192.060333,132.914124 
	C196.166977,128.792664 200.091904,124.853554 204.016846,120.914459 
	C204.409454,120.959747 204.802078,121.005035 205.602203,121.333473 
	C206.339981,121.724770 206.670258,121.832924 207.000519,121.941071 
z"/>
<path fill="#9BCD67" opacity="1.000000" stroke="none" 
	d="
M68.518280,23.054714 
	C67.124367,29.116484 62.112423,32.316307 57.409283,35.704201 
	C50.809093,40.458618 43.510323,43.887711 38.467918,51.116028 
	C35.326591,55.619137 28.828119,57.780331 23.461952,60.988304 
	C22.903282,53.890495 22.400599,46.804844 25.443583,40.024681 
	C30.356434,29.078211 39.046371,23.780762 50.669571,23.065763 
	C56.442535,22.710644 62.256367,23.019958 68.518280,23.054714 
z"/>
<path fill="#67BC55" opacity="1.000000" stroke="none" 
	d="
M54.756992,122.034508 
	C54.798237,124.070274 54.839478,126.106049 54.692886,128.673965 
	C54.610703,129.469360 54.716354,129.732605 54.822006,129.995850 
	C54.867771,132.047272 54.913536,134.098709 54.652687,136.878052 
	C54.237244,139.735748 54.128403,141.865540 53.657562,144.004135 
	C52.727192,144.253113 52.158829,144.493271 51.590462,144.733429 
	C51.967880,145.131989 52.295662,145.792480 52.732903,145.875351 
	C53.682133,146.055237 54.687283,145.940033 55.669571,145.945465 
	C55.820786,146.450974 55.972000,146.956467 56.109203,147.727325 
	C54.661297,148.724808 53.214699,149.433228 51.796089,150.193848 
	C48.618279,151.897720 45.233856,153.323822 42.341084,155.424286 
	C38.789589,158.003036 35.749733,161.275757 32.295414,164.003723 
	C30.688595,165.272690 28.636166,166.614029 26.765471,166.643677 
	C25.829699,166.658493 24.194685,163.875992 24.007662,162.230087 
	C23.393757,156.827271 23.298101,151.365570 23.353369,145.494919 
	C25.362223,144.564957 27.195757,144.352676 28.625557,143.479385 
	C29.811207,142.755219 30.388401,141.108093 31.463528,140.117783 
	C38.143387,133.964828 46.585270,129.848419 52.079514,122.295685 
	C52.344742,121.931091 53.333763,122.093025 54.178417,122.019913 
	C54.371105,122.030052 54.756992,122.034508 54.756992,122.034508 
M51.482773,137.588577 
	C51.482773,137.588577 51.417282,137.478256 51.482773,137.588577 
z"/>
<path fill="#74BF59" opacity="1.000000" stroke="none" 
	d="
M53.985729,122.009766 
	C53.333763,122.093025 52.344742,121.931091 52.079514,122.295685 
	C46.585270,129.848419 38.143387,133.964828 31.463528,140.117783 
	C30.388401,141.108093 29.811207,142.755219 28.625557,143.479385 
	C27.195757,144.352676 25.362223,144.564957 23.357571,145.027069 
	C23.012018,138.968933 23.012344,132.947067 23.388403,126.455032 
	C24.786579,125.539742 26.000765,125.313126 26.799143,124.612625 
	C30.807606,121.095520 34.603317,117.331673 38.670757,113.887108 
	C44.171700,109.228569 51.283848,106.396637 54.475430,99.145493 
	C55.432781,96.970428 57.421143,96.735687 58.861771,99.308594 
	C58.202866,100.589508 57.693859,101.569153 57.184853,102.548798 
	C57.184856,102.548798 57.183292,102.829216 56.715084,102.986755 
	C55.844635,105.766541 55.442390,108.388794 55.040150,111.011047 
	C55.015244,111.773819 54.990337,112.536591 54.638596,113.973923 
	C54.203087,117.102242 54.094410,119.556000 53.985729,122.009766 
z"/>
<path fill="#7EC25B" opacity="1.000000" stroke="none" 
	d="
M59.011677,99.007324 
	C57.421143,96.735687 55.432781,96.970428 54.475430,99.145493 
	C51.283848,106.396637 44.171700,109.228569 38.670757,113.887108 
	C34.603317,117.331673 30.807606,121.095520 26.799143,124.612625 
	C26.000765,125.313126 24.786579,125.539742 23.399345,125.986084 
	C23.026318,121.633682 23.018082,117.280067 23.020956,112.461533 
	C23.987488,111.811554 25.225048,111.946739 25.854761,111.391968 
	C32.266315,105.743401 38.426331,99.799477 44.985283,94.332352 
	C48.134808,91.707108 52.000957,89.947647 55.514595,87.751343 
	C58.511868,85.877800 61.469109,83.940216 64.811340,82.382355 
	C65.065819,83.172043 64.953026,83.610344 64.593376,84.320328 
	C64.515594,86.713448 64.684669,88.834877 64.944336,91.221405 
	C65.068825,91.855774 65.102722,92.225052 65.099838,92.986984 
	C65.460152,94.232094 65.857254,95.084549 66.494049,96.143509 
	C66.872993,97.163811 67.012245,97.977615 67.004181,99.075127 
	C66.234650,99.914078 65.612442,100.469315 64.990227,101.024559 
	C64.990227,101.024559 64.978989,100.990959 64.910591,100.608276 
	C62.898689,99.819504 60.955181,99.413414 59.011677,99.007324 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M158.220810,166.035767 
	C157.242249,166.853638 156.263687,167.671509 154.917480,168.851349 
	C154.251358,169.395889 154.085419,169.661346 154.052032,170.009674 
	C154.052032,170.009674 153.997971,169.997864 153.771271,170.099594 
	C153.250336,170.392090 153.083710,170.661728 153.044708,171.010269 
	C152.045731,171.869934 151.084351,172.778564 150.040619,173.579941 
	C147.110992,175.829346 147.194504,177.834473 149.916046,180.417435 
	C158.931335,188.973816 167.769135,197.717194 176.739502,206.715103 
	C176.202255,207.559662 175.600540,208.077942 174.926926,208.921753 
	C165.370682,200.020157 155.881958,190.797501 146.403564,181.564240 
	C140.128860,175.451813 133.868973,169.324203 126.538925,162.164597 
	C131.795319,157.414139 136.427505,153.227798 141.671219,149.132080 
	C142.918549,149.871353 143.554367,150.520004 143.930481,151.380737 
	C139.737961,155.092300 135.805161,158.591812 132.280350,161.728287 
	C137.011230,166.756210 140.714081,170.691559 145.532150,175.812164 
	C148.664291,172.118912 151.661438,168.759720 154.423904,165.217529 
	C155.220459,164.196167 155.341599,162.648071 156.080063,161.278625 
	C156.959717,162.579575 157.531708,163.943375 158.127548,165.490173 
	C158.151398,165.673203 158.220810,166.035767 158.220810,166.035767 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M204.009369,120.530273 
	C200.091904,124.853554 196.166977,128.792664 192.060333,132.914124 
	C193.429214,134.097107 194.633270,135.137665 196.075500,136.384048 
	C194.691132,137.545990 193.510010,138.537354 191.221237,140.458420 
	C195.494919,144.306641 199.507141,147.919434 203.754807,151.762665 
	C203.966705,152.800125 203.943192,153.607147 203.900391,154.817825 
	C196.485931,148.274551 189.090744,141.327637 181.363037,133.830902 
	C181.036224,132.149979 181.041946,131.018875 181.047668,129.887772 
	C182.556946,131.157227 184.066208,132.426697 186.379654,134.372559 
	C191.983536,128.991653 197.638412,123.561783 203.596436,117.987183 
	C203.933670,118.610344 203.967789,119.378220 204.009369,120.530273 
z"/>
<path fill="#67BC55" opacity="1.000000" stroke="none" 
	d="
M56.113075,148.847656 
	C56.485306,149.697952 56.795902,150.928436 57.104637,152.546967 
	C56.752331,151.699280 56.401886,150.463547 56.113075,148.847656 
z"/>
<path fill="#80C35D" opacity="1.000000" stroke="none" 
	d="
M23.018705,91.329117 
	C23.227922,91.636536 23.424145,92.279312 23.326681,92.964233 
	C23.023899,92.559082 23.014807,92.111778 23.018705,91.329117 
z"/>
<path fill="#67BC55" opacity="1.000000" stroke="none" 
	d="
M57.046246,153.254120 
	C57.390690,153.709427 57.709324,154.506699 57.983601,155.645386 
	C57.632977,155.189896 57.326706,154.392990 57.046246,153.254120 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M206.855804,121.726715 
	C206.670258,121.832924 206.339981,121.724770 205.896973,121.364700 
	C206.093170,121.245964 206.402145,121.379158 206.855804,121.726715 
z"/>
<path fill="#59B954" opacity="1.000000" stroke="none" 
	d="
M880.994019,35.002266 
	C880.976501,34.244713 880.958984,33.487156 880.767578,31.996023 
	C880.071411,26.848789 879.549133,22.435135 879.014648,18.009899 
	C879.002441,17.998320 879.017578,18.026590 879.411377,18.110022 
	C880.870056,18.129473 881.935059,18.065495 883.459961,18.101471 
	C898.613342,18.134556 913.306641,18.067686 928.368408,18.103161 
	C929.491211,18.134565 930.245605,18.063622 931.422852,18.094620 
	C933.867737,18.184576 935.889771,18.172586 937.902710,18.508137 
	C936.667297,20.990269 935.438293,23.123388 934.215027,25.259769 
	C931.178589,30.562658 926.569946,35.538582 925.383423,41.227840 
	C920.356873,65.329628 916.386230,89.650681 911.947754,113.876595 
	C910.043030,124.273216 907.938110,134.633194 905.600342,145.201202 
	C904.857300,146.256119 904.218567,147.079437 904.052002,147.989212 
	C901.642700,161.149460 899.411865,174.343613 896.869202,187.477539 
	C896.049316,191.712463 894.205688,195.743179 893.283813,199.966278 
	C892.575256,203.211563 893.040344,206.738205 892.173218,209.918854 
	C889.957275,218.047485 889.776245,217.998520 881.467346,217.998703 
	C867.020630,217.999008 852.573669,217.964081 838.127380,218.044754 
	C836.411133,218.054321 834.698425,218.694046 832.655762,219.120422 
	C831.887024,219.129517 831.446655,219.059845 831.006714,218.544983 
	C830.337463,212.556290 829.722412,207.005508 828.984741,201.471069 
	C827.985718,193.975830 826.966858,186.481125 825.759888,179.017990 
	C825.578552,177.897018 824.328735,176.948853 823.444763,175.750534 
	C823.316406,175.580322 823.354614,175.155640 823.686035,175.036438 
	C823.172852,167.140640 822.340088,159.362671 821.430847,151.593643 
	C821.401917,151.346420 820.588562,151.190964 820.039429,150.676483 
	C819.931030,149.919922 819.924072,149.479614 820.215332,148.789597 
	C820.288879,144.383270 820.181152,140.214020 819.735046,136.081284 
	C819.651428,135.306503 818.214844,134.677750 817.292175,133.666718 
	C817.094788,132.915497 817.007812,132.479172 817.197571,131.828949 
	C817.246582,129.868683 817.018860,128.122299 816.791077,126.375923 
	C816.206665,126.393799 815.622253,126.411682 815.037842,126.429565 
	C815.037842,128.293991 815.037842,130.158432 814.997498,132.391205 
	C814.560364,134.168167 814.163513,135.576782 813.413147,137.079071 
	C809.730957,143.174469 810.958618,149.655411 811.100342,156.393234 
	C811.022522,157.506927 810.921814,158.247116 810.519897,159.194092 
	C808.520874,166.397354 806.753601,173.378784 805.232483,180.413437 
	C805.079956,181.118912 806.419739,182.147095 807.085999,183.357330 
	C807.018066,184.122772 806.931091,184.556381 806.475037,185.088196 
	C805.749817,189.129028 805.393616,193.071686 805.072632,197.352112 
	C805.020081,198.132217 804.932434,198.574524 804.553589,199.184494 
	C801.927429,203.692215 801.790161,208.264816 802.885376,213.299805 
	C802.864380,214.086761 802.780640,214.535049 802.356018,215.072586 
	C799.723145,216.107468 797.443054,217.837280 795.137085,217.872421 
	C779.325256,218.113373 763.507935,217.994766 747.692383,218.003159 
	C744.371216,218.004929 740.687500,218.700027 740.739075,213.411316 
	C740.743591,212.949921 738.983154,212.471313 737.937866,211.849609 
	C737.833252,211.698486 737.868896,211.332581 737.912842,210.853088 
	C737.312500,205.837326 736.700623,201.296158 736.017944,196.765656 
	C733.594177,180.681686 731.197632,164.593048 728.606384,148.535934 
	C728.407593,147.303757 726.770874,146.303604 725.814697,145.183945 
	C725.826782,145.172302 725.854309,145.155182 725.902161,144.815216 
	C725.686279,143.660095 725.422546,142.844955 725.054932,141.709534 
	C724.929871,140.943085 724.908752,140.496902 725.186646,139.809067 
	C725.649719,138.782669 726.002747,137.983978 725.945923,137.215591 
	C725.667297,133.445023 725.427673,129.658112 724.814270,125.936302 
	C724.680420,125.124046 723.073303,124.554619 722.099854,123.430466 
	C722.036560,121.207390 722.280029,119.372635 721.923950,117.662773 
	C721.587708,116.048470 720.620972,114.565491 719.738892,112.991913 
	C719.546204,112.959084 719.155884,112.939445 719.151245,112.594650 
	C719.173584,111.792938 719.200562,111.336021 719.477417,110.580841 
	C718.866943,102.867950 718.293396,95.404137 717.033875,88.057945 
	C716.328857,83.945808 714.486694,80.028641 713.053223,75.707909 
	C712.910095,74.953255 712.870605,74.514320 713.140320,73.838562 
	C712.616272,67.501503 711.942993,61.372814 710.865356,55.316055 
	C710.474365,53.118671 709.117432,51.093155 708.094604,48.678284 
	C707.951416,47.932636 707.914978,47.497925 708.181091,46.804642 
	C709.666992,39.611446 704.699463,29.453880 698.696594,26.906374 
	C698.266113,26.308361 698.075134,25.827333 697.836914,25.063072 
	C697.518921,24.523314 697.248108,24.266787 696.987061,24.005367 
	C696.996765,24.000477 696.983154,24.021639 696.995728,23.597784 
	C696.697876,19.812145 698.983582,19.986200 701.249329,19.989353 
	C716.909424,20.011143 732.569580,19.993301 748.229614,20.024817 
	C749.341980,20.027054 750.453857,20.321215 751.677673,20.495556 
	C751.852051,22.211241 752.001831,23.685181 752.165161,25.103081 
	C752.178650,25.047043 752.211731,25.157516 751.856018,25.114569 
	C750.924072,25.254869 749.924561,25.323641 749.839050,25.639629 
	C749.197754,28.010422 748.522705,30.412357 748.322693,32.842751 
	C748.240662,33.839619 749.305603,34.930866 749.899902,36.316677 
	C749.866211,37.098557 749.781494,37.542934 749.347778,38.093086 
	C749.121704,43.665756 748.737610,49.203506 749.489319,54.582531 
	C750.551514,62.183025 752.709473,69.624573 753.941284,77.208412 
	C756.316284,91.830879 758.453796,106.493668 760.531616,121.162132 
	C762.147278,132.567902 763.421814,144.022598 765.102600,155.417984 
	C765.392090,157.380661 766.954895,159.155518 768.143311,161.212570 
	C768.461304,161.963135 768.565552,162.518234 768.450439,163.402054 
	C768.489807,167.465149 768.595886,171.222977 769.161987,174.910202 
	C769.253479,175.506287 771.390076,175.788422 772.581360,176.215668 
	C772.671753,173.790176 772.762085,171.364670 772.850342,168.610138 
	C772.955505,167.857666 773.062805,167.434250 773.473999,166.805206 
	C774.167542,165.890961 774.794922,165.219116 774.911194,164.468170 
	C776.647705,153.250427 778.306580,142.020691 780.005859,130.797119 
	C780.890320,124.955856 781.962219,119.138565 782.670532,113.276726 
	C783.080444,109.884521 782.936035,106.425339 783.000244,102.600891 
	C782.919800,101.150528 782.880249,100.095566 782.939880,98.718140 
	C783.057800,97.946846 783.076477,97.498009 783.298401,97.031052 
	C783.501648,97.012932 783.907288,96.966728 784.284790,96.912453 
	C784.705444,94.875298 784.748657,92.892426 784.786499,90.584915 
	C784.891846,89.843330 785.002502,89.426369 785.374390,88.783447 
	C788.638489,85.533867 788.956055,81.846863 789.138611,77.739601 
	C789.446350,70.815865 790.744202,63.940956 791.431335,57.026199 
	C791.627747,55.049835 791.153442,53.006824 790.954346,50.660385 
	C791.004944,49.888306 791.087952,49.450214 791.537964,48.912460 
	C793.271057,41.867325 794.937561,34.963638 795.911011,27.963568 
	C796.738281,22.014635 798.033997,20.181265 804.051086,20.042698 
	C811.167480,19.878813 818.299438,19.789511 825.406738,20.090567 
	C828.905151,20.238762 832.363159,21.339211 835.953064,22.318609 
	C836.098999,23.065042 836.131348,23.501606 835.852661,24.187416 
	C836.054138,29.463156 836.516968,34.495892 837.148193,39.507450 
	C837.218994,40.069691 838.243286,40.511837 838.913086,41.332111 
	C839.021973,42.097485 839.043213,42.540428 838.744873,43.139977 
	C838.156250,44.598583 837.472046,45.973618 837.686096,47.190678 
	C839.013367,54.737850 840.416809,62.276962 842.051514,69.762711 
	C843.027649,74.232445 844.471558,78.600014 845.824097,83.374283 
	C845.903015,84.488541 845.863037,85.242012 845.492676,86.063721 
	C845.701660,88.092812 846.241028,90.053680 846.885864,92.333618 
	C847.000427,93.098053 847.009521,93.543411 846.635132,94.071213 
	C844.874695,96.902290 847.685608,110.878273 850.241089,113.019897 
	C850.442810,113.053009 850.850769,113.079552 850.854980,113.479828 
	C850.984070,114.936844 851.108948,115.993568 851.151672,117.368881 
	C851.027832,118.123383 850.986084,118.559303 850.671448,119.271820 
	C850.932495,124.078232 851.448242,128.610306 852.004578,133.137375 
	C852.813599,139.721054 853.579773,146.311234 854.500732,152.879501 
	C855.227112,158.059509 856.088989,163.223434 857.025818,168.369919 
	C857.614319,171.602905 858.452393,174.790451 859.243896,178.287140 
	C863.299988,176.012344 866.497803,158.370728 865.829285,146.264755 
	C866.090576,144.048065 866.347534,142.243637 866.597351,140.438202 
	C868.001099,130.291809 869.403137,120.145187 870.912964,109.678360 
	C871.028259,108.910713 871.036316,108.463348 871.313477,107.734100 
	C871.994690,103.966080 872.406738,100.479942 872.818726,96.993805 
	C872.910461,96.559433 873.002197,96.125069 873.339722,95.065506 
	C874.663513,86.625145 875.741394,78.809959 876.935547,70.677902 
	C877.067444,69.914612 877.083130,69.468185 877.350342,68.723160 
	C878.650696,61.612213 879.699524,54.799885 880.748352,47.987556 
	C880.863464,46.610958 880.978516,45.234356 881.338318,43.155155 
	C881.386658,39.969124 881.190369,37.485695 880.994019,35.002266 
z"/>
<path fill="#59B954" opacity="1.000000" stroke="none" 
	d="
M1033.290283,181.994797 
	C1034.449341,195.833389 1041.112915,207.376099 1049.716675,218.964493 
	C1028.883545,218.964493 1009.210022,218.964493 988.906128,218.964493 
	C989.369385,215.770813 989.786438,212.895828 990.201050,209.605988 
	C990.198608,209.191116 990.174255,209.152496 990.452698,208.909790 
	C991.624817,203.445618 994.178162,198.269150 991.157471,192.941574 
	C991.097717,192.202469 991.037964,191.463348 990.938110,189.971039 
	C990.169495,185.826523 989.441040,182.435211 988.712524,179.043915 
	C988.624939,178.483124 988.537415,177.922333 988.237915,176.782135 
	C988.099060,174.111923 987.842163,172.337097 984.997620,173.039734 
	C972.304810,172.970276 959.611938,172.900818 946.085205,173.008850 
	C943.951233,178.570999 942.667114,183.959549 941.347046,189.339294 
	C939.723267,195.956787 938.863770,202.537201 942.085449,208.968597 
	C942.086182,209.436981 942.086914,209.905380 942.151123,210.996033 
	C942.426147,211.923615 942.637695,212.228928 942.849243,212.534241 
	C942.844543,212.873520 942.839905,213.212830 942.918579,214.107483 
	C943.244690,215.479141 943.487488,216.295410 943.730286,217.111679 
	C943.770935,217.513718 943.811523,217.915741 943.449707,218.711548 
	C928.373169,219.063339 913.699097,219.021362 899.025024,218.979401 
	C899.025024,218.979401 899.000000,218.999390 899.035278,218.581268 
	C903.434753,194.036072 907.752075,169.900421 912.174866,145.784103 
	C917.763062,115.313240 923.581665,84.883766 928.992554,54.381741 
	C930.782959,44.288563 933.006592,34.604206 939.789429,26.482357 
	C941.896973,23.958763 943.350830,20.889217 945.061340,18.043859 
	C945.018677,18.018097 945.066833,18.105370 945.530273,18.097725 
	C965.965271,18.110874 985.936768,18.131668 1005.908325,18.152462 
	C1005.908325,18.152464 1005.933533,18.052835 1005.922241,18.480198 
	C1005.357605,22.273083 1004.804260,25.638605 1004.142090,29.326220 
	C1004.023010,30.099112 1004.012817,30.549915 1003.773560,31.177906 
	C1003.449341,31.906561 1003.354187,32.458027 1003.151428,33.366158 
	C1002.987244,34.463631 1002.930603,35.204441 1002.617371,36.226456 
	C1003.273804,41.114582 1004.186890,45.721512 1005.099976,50.328438 
	C1005.088135,50.686283 1005.076233,51.044132 1005.066040,51.983574 
	C1005.677551,54.725880 1006.287476,56.886589 1006.896484,59.425163 
	C1007.008789,60.547916 1007.122070,61.292793 1007.204468,62.332932 
	C1007.400452,62.938637 1007.627258,63.249084 1007.794983,63.675392 
	C1007.735901,63.791245 1007.911133,63.983482 1007.899170,64.345749 
	C1008.209717,65.817795 1008.532227,66.927582 1008.854736,68.037369 
	C1008.915283,68.781822 1008.975830,69.526268 1009.085938,71.024475 
	C1010.431458,77.568451 1011.717285,83.360970 1013.025513,89.148430 
	C1016.331299,103.773293 1019.647217,118.395882 1022.927490,133.356186 
	C1023.006531,134.127426 1023.117310,134.561844 1023.179749,135.389954 
	C1023.426453,137.055466 1023.725647,138.326355 1024.015869,139.599274 
	C1026.634277,151.085052 1029.251099,162.571167 1031.845703,174.430176 
	C1031.962158,175.533752 1032.101196,176.264343 1032.200195,177.280396 
	C1032.440674,178.043762 1032.721191,178.521622 1032.977417,179.378113 
	C1033.065430,180.502777 1033.177856,181.248795 1033.290283,181.994797 
M959.966125,92.517998 
	C956.782166,109.524368 953.598267,126.530746 950.412659,143.545944 
	C961.377991,143.545944 971.579590,143.545944 982.018677,143.545944 
	C981.931824,141.776291 982.022522,140.435425 981.780090,139.157791 
	C977.210938,115.080353 972.617981,91.007370 967.951111,66.948746 
	C967.740295,65.861885 966.786865,64.919090 965.702881,63.117256 
	C963.654480,73.492416 961.860596,82.578720 959.966125,92.517998 
z"/>
<path fill="#59B954" opacity="1.000000" stroke="none" 
	d="
M339.760864,192.995865 
	C339.888184,192.567596 340.015503,192.139343 340.410675,191.101105 
	C344.715729,171.324570 348.752930,152.157990 352.790131,132.991409 
	C352.871246,132.550247 352.952393,132.109085 353.245117,131.104248 
	C353.590729,129.692398 353.724701,128.844193 353.974060,127.687897 
	C354.107727,126.944290 354.126038,126.508774 354.392120,125.780861 
	C356.474274,116.372864 358.308655,107.257278 360.126923,98.114456 
	C360.110779,98.087227 360.084564,98.142090 360.300720,98.043289 
	C360.745911,97.680779 360.808044,97.382347 360.827026,96.682404 
	C360.965698,95.543076 360.980682,94.770523 361.260498,93.723198 
	C362.299103,89.298904 363.072876,85.149384 364.011780,80.657639 
	C364.349915,79.580185 364.522888,78.844940 364.835815,77.784515 
	C366.248505,71.303802 367.521240,65.148262 368.879211,58.664337 
	C368.959473,57.884209 368.954468,57.432468 369.224060,56.714539 
	C370.606567,50.298092 371.714508,44.147831 372.904541,37.661400 
	C372.915100,36.873768 372.843567,36.422318 372.821655,35.733501 
	C372.871246,35.496136 372.674377,35.052902 372.726501,34.760967 
	C372.498596,33.984444 372.218536,33.499855 371.960724,32.601395 
	C371.033203,27.776392 370.083466,23.365263 369.292480,18.726252 
	C369.966431,18.291321 370.481628,18.084270 371.461273,17.945496 
	C395.576477,18.051565 419.227203,18.089361 442.915344,18.489079 
	C444.253387,21.590536 445.554016,24.330074 446.872772,27.478313 
	C446.903931,29.248219 446.916992,30.609421 446.682953,32.254158 
	C443.750641,40.111870 447.181091,46.968052 448.942261,54.394672 
	C449.042236,55.512726 449.157715,56.254494 449.261414,57.387566 
	C449.853149,62.183498 450.962189,66.586060 450.980133,70.993073 
	C451.123596,106.248535 450.718170,141.509781 451.224823,176.758545 
	C451.435669,191.427490 450.683319,205.542419 443.117798,218.532135 
	C443.040924,218.664139 443.070618,218.858215 443.022430,219.017349 
	C442.994293,219.011398 443.028290,219.057846 442.568909,219.130188 
	C438.850952,219.202545 435.592438,219.202545 431.850006,219.202545 
	C432.933807,213.316864 433.884338,208.154953 434.834839,202.993042 
	C434.927216,202.567444 435.019592,202.141861 435.398621,201.191589 
	C435.483795,199.437866 435.282379,198.208832 435.096863,196.643829 
	C435.007660,195.873459 434.902527,195.439041 434.863708,194.619171 
	C434.321716,191.806564 433.713440,189.379379 433.115143,186.621185 
	C433.026093,185.860947 432.927002,185.431747 432.895416,184.602829 
	C432.358917,181.474915 431.888245,178.706680 431.086761,176.037796 
	C430.756348,174.937454 429.714996,174.050598 428.540131,172.952789 
	C417.056091,172.902863 406.028931,172.966782 394.632385,172.932556 
	C393.508270,172.904099 392.753540,172.973770 391.627777,172.940475 
	C390.506744,172.941849 389.756805,173.046188 388.648102,173.239716 
	C387.256073,178.221054 386.222778,183.113190 385.189484,188.005341 
	C385.077332,188.435730 384.965179,188.866104 384.598694,189.907547 
	C383.955688,193.346222 383.567047,196.173843 383.104034,199.331573 
	C383.082489,200.106720 383.135345,200.551758 383.132812,201.371643 
	C383.702698,203.837616 384.327942,205.928772 384.953186,208.019928 
	C384.987457,208.768692 385.021729,209.517456 385.155029,211.009445 
	C385.754059,214.084824 386.254120,216.416977 386.817169,219.042969 
	C366.591064,219.042969 346.933472,219.042969 326.896667,219.042969 
	C327.362610,217.483490 327.725189,216.270035 328.087738,215.056580 
	C328.087738,215.056580 328.016083,214.995804 328.339539,214.884903 
	C329.831329,212.958420 331.053375,211.174850 332.157684,209.321167 
	C335.244476,204.139633 339.832031,199.649475 339.760864,192.995865 
M401.794434,100.221962 
	C399.050171,114.596947 396.305908,128.971939 393.503662,143.650726 
	C404.735931,143.650726 414.937042,143.650726 425.665009,143.650726 
	C420.553589,116.852623 415.524445,90.485886 410.495270,64.119141 
	C409.874268,64.122978 409.253235,64.126816 408.632233,64.130653 
	C406.397705,75.877991 404.163177,87.625320 401.794434,100.221962 
z"/>
<path fill="#59B854" opacity="1.000000" stroke="none" 
	d="
M678.844177,23.045296 
	C679.050415,23.599037 679.256653,24.152782 679.804016,25.193380 
	C682.386169,28.911144 684.627197,32.142056 686.890564,35.635372 
	C687.259216,36.270874 687.605530,36.643963 687.956238,37.312317 
	C688.277771,38.084206 688.594910,38.560829 688.899170,39.415668 
	C688.990601,40.541718 689.094910,41.289547 689.140015,42.362083 
	C689.338989,43.269974 689.597229,43.853168 689.818359,44.598091 
	C689.781250,44.759827 690.010071,45.000179 689.983887,45.406479 
	C690.226562,48.054344 690.495483,50.295914 690.794006,52.784168 
	C686.305359,50.824577 682.171265,49.019745 677.574768,47.119354 
	C662.076660,47.086182 647.040955,47.148571 631.649658,47.139137 
	C630.605103,47.714809 629.916016,48.362309 629.139771,49.470440 
	C629.105591,65.619308 629.158569,81.307541 629.128174,97.438576 
	C629.044739,99.808044 629.044739,101.734726 629.044739,104.078606 
	C631.306702,104.078606 632.940918,104.078438 634.575195,104.078636 
	C652.858582,104.080811 671.141968,104.082855 689.425354,104.085335 
	C700.049438,104.086769 700.046143,104.087822 702.309448,114.262337 
	C702.374329,114.554039 702.684265,114.791229 702.858521,115.383957 
	C702.937988,116.141655 703.038818,116.569237 703.076416,117.396233 
	C703.654724,120.539879 704.296326,123.284103 704.916992,126.401749 
	C705.004272,127.513809 705.112549,128.252457 705.157959,129.362793 
	C705.391541,131.584457 705.687988,133.434418 705.933716,134.968063 
	C702.024170,134.333420 698.534668,133.766968 694.640259,133.121918 
	C693.141113,133.097855 692.046875,133.152374 690.494385,133.121338 
	C672.375244,133.090729 654.714417,133.145660 636.673096,133.119858 
	C635.520691,133.093369 634.748657,133.147614 633.739014,133.137482 
	C633.501404,133.073120 633.025879,133.201141 632.691528,133.128815 
	C631.906616,133.106522 631.456055,133.156540 630.673828,133.144440 
	C629.963745,133.723221 629.585144,134.364136 629.131836,135.338440 
	C629.105042,136.122253 629.153076,136.572662 629.137085,137.260834 
	C629.073120,137.498611 629.201843,137.973969 629.123047,138.351334 
	C629.096497,139.493607 629.148621,140.258499 629.138550,141.261261 
	C629.076294,141.499115 629.209351,141.972519 629.130066,142.436829 
	C629.099060,154.282043 629.147339,165.662949 629.110962,177.499359 
	C629.026306,182.060333 629.026306,186.165802 629.026306,190.765305 
	C631.258789,190.765305 632.879089,190.764526 634.499451,190.765427 
	C650.142273,190.774094 665.798096,191.177078 681.423584,190.660431 
	C692.812805,190.283844 704.703735,193.804184 715.356567,186.607025 
	C716.638977,192.126862 717.788574,197.075058 718.909912,202.358429 
	C718.987732,203.127777 719.093750,203.561966 719.125732,204.393555 
	C719.678162,207.537460 720.304626,210.283981 720.912048,213.409912 
	C720.994568,214.540985 721.096130,215.292648 721.133911,216.486420 
	C720.707275,220.919632 717.578979,219.232941 715.711060,219.237473 
	C671.559387,219.344543 627.407410,219.332947 583.255493,219.317337 
	C581.556885,219.316727 579.858398,219.060318 577.713501,218.887054 
	C578.575684,216.798752 579.227661,215.470108 579.681763,214.076950 
	C581.822205,207.510117 585.667603,200.943375 585.734192,194.344620 
	C586.238159,144.366058 586.050476,94.379784 585.884705,44.396568 
	C585.871948,40.555859 584.723999,36.602741 583.481750,32.911167 
	C581.864685,28.105780 579.625244,23.509851 577.339539,18.077217 
	C580.035339,18.077217 581.821228,18.077305 583.607117,18.077204 
	C611.431213,18.075640 639.255981,18.171007 667.078796,17.981600 
	C671.990051,17.948164 676.209656,18.342737 678.844177,23.045296 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M679.185425,22.993931 
	C676.209656,18.342737 671.990051,17.948164 667.078796,17.981600 
	C639.255981,18.171007 611.431213,18.075640 583.607117,18.077204 
	C581.821228,18.077305 580.035339,18.077217 577.339539,18.077217 
	C579.625244,23.509851 581.864685,28.105780 583.481750,32.911167 
	C584.723999,36.602741 585.871948,40.555859 585.884705,44.396568 
	C586.050476,94.379784 586.238159,144.366058 585.734192,194.344620 
	C585.667603,200.943375 581.822205,207.510117 579.681763,214.076950 
	C579.227661,215.470108 578.575684,216.798752 577.713501,218.887054 
	C579.858398,219.060318 581.556885,219.316727 583.255493,219.317337 
	C627.407410,219.332947 671.559387,219.344543 715.711060,219.237473 
	C717.578979,219.232941 720.707275,220.919632 721.389709,216.730225 
	C722.822754,218.010651 723.787781,220.590378 725.077393,220.764420 
	C729.162354,221.315704 733.368713,220.968124 736.504639,220.968124 
	C736.695618,217.088882 736.845459,214.043930 737.212524,211.085495 
	C737.429749,211.172012 737.868896,211.332581 737.868896,211.332581 
	C737.868896,211.332581 737.833252,211.698486 737.898926,212.261017 
	C738.518738,215.489319 737.196838,219.525314 741.800720,219.534622 
	C760.615417,219.572678 779.430420,219.359512 798.245361,219.361984 
	C801.450745,219.362411 802.876648,218.136795 802.696777,214.983337 
	C802.780640,214.535049 802.864380,214.086761 802.993530,212.898651 
	C803.640869,207.778152 804.242798,203.397507 804.844727,199.016846 
	C804.932434,198.574524 805.020081,198.132217 805.318115,197.046448 
	C805.966980,192.598663 806.405518,188.794312 806.844116,184.989990 
	C806.931091,184.556381 807.018066,184.122772 807.342163,183.059341 
	C808.626648,175.930862 809.674072,169.432190 810.721436,162.933502 
	C812.122192,163.874191 813.522888,164.814880 815.340881,166.035843 
	C808.674500,171.112061 810.614014,178.374741 809.167114,184.514206 
	C807.308289,192.401123 805.652588,200.537231 805.596558,208.577774 
	C805.558472,214.047440 802.824707,218.707428 802.631897,224.470612 
	C801.504944,224.903427 800.749390,224.848541 799.993896,224.793671 
	C800.148926,221.983719 798.698425,220.976349 796.026550,220.984238 
	C781.541382,221.026993 767.055115,220.935425 752.572388,221.128067 
	C751.326233,221.144653 749.697205,222.662109 748.979431,223.905655 
	C748.204529,225.248123 748.026978,227.091187 748.045227,228.710754 
	C748.183777,241.002151 748.574158,253.292923 748.516052,265.582153 
	C748.506042,267.702515 746.739929,269.814545 745.322754,271.914032 
	C744.860474,271.897644 744.660461,271.736053 744.729248,271.330627 
	C745.395874,255.683868 745.993713,240.442505 746.612610,224.665436 
	C736.202881,224.665436 726.827026,224.665436 717.196289,224.665436 
	C717.196289,237.083817 717.196289,249.047424 717.109253,261.336975 
	C717.022278,262.111176 717.022278,262.559387 716.789673,263.270203 
	C715.993042,265.350891 715.428955,267.169037 714.781860,269.072723 
	C714.698853,269.158264 714.772339,269.385071 714.478271,269.578369 
	C710.774719,274.815430 705.968323,277.611816 700.114441,279.109467 
	C691.123779,281.409607 682.133850,280.765656 673.088806,279.790344 
	C673.075073,279.839600 673.172791,279.809601 672.947510,279.529846 
	C670.844910,278.716187 668.967590,278.182281 666.935669,277.443848 
	C666.295349,277.115387 665.809570,276.991486 665.132202,276.584961 
	C662.663025,274.157593 660.385498,272.012787 658.074524,269.920319 
	C658.041077,269.972656 658.146912,269.907593 658.088013,269.521912 
	C657.310364,265.935730 656.118774,262.757233 655.968628,259.530273 
	C655.628174,252.213776 655.778625,244.873428 655.769165,237.542740 
	C655.752197,224.351303 655.764404,224.351501 642.443726,224.353073 
	C637.045654,224.353714 631.647522,224.353180 626.093872,224.353180 
	C626.093872,237.860458 625.979675,250.343201 626.183167,262.820770 
	C626.227783,265.553619 627.403931,268.268066 628.027344,271.288605 
	C628.254822,272.088348 628.513916,272.590424 628.817627,273.445984 
	C629.881714,275.598175 630.901123,277.396942 631.936279,279.423950 
	C631.952026,279.652191 632.222900,280.020966 632.152527,280.250793 
	C632.082153,280.480652 632.098938,280.961090 632.098938,280.961090 
	C629.049438,279.830322 626.364624,278.427979 626.883240,274.421692 
	C626.941589,273.971313 626.781372,273.275208 626.462280,273.025482 
	C620.209900,268.132935 623.668274,261.945801 623.896790,256.034302 
	C624.275940,246.227386 623.866516,236.392441 624.064880,226.574860 
	C624.144958,222.615982 623.084473,220.822159 618.752869,220.925125 
	C608.604126,221.166382 598.445679,221.010895 588.291199,220.995758 
	C586.068726,220.992447 583.715088,220.724640 583.686157,224.420197 
	C582.244995,224.481720 581.127380,224.248993 579.969360,223.655884 
	C578.345154,218.977341 574.820740,218.698898 571.008179,219.008026 
	C571.004089,219.004181 571.000000,218.999664 570.999634,218.632095 
	C570.966370,217.774185 570.933411,217.283859 570.927795,216.576385 
	C570.955200,216.359253 571.180359,215.983917 571.394287,215.808868 
	C571.682129,215.279190 571.755920,214.924545 571.813232,214.409241 
	C571.796753,214.248596 572.002441,213.999573 572.253235,213.821503 
	C572.603821,213.254120 572.703613,212.864822 572.810425,212.161896 
	C573.237061,210.896606 573.656799,209.944962 574.311768,208.811783 
	C574.632507,208.250687 574.717896,207.871109 574.822998,207.171600 
	C575.292542,205.891739 575.742554,204.931839 576.393799,203.824005 
	C576.700439,203.365982 576.805786,203.055908 576.909058,202.540710 
	C576.906982,202.335617 577.117249,201.983383 577.337769,201.807037 
	C577.655457,201.273315 577.752686,200.915939 577.833191,200.286591 
	C577.927795,199.670746 578.039124,199.326859 578.297302,198.897125 
	C578.498535,198.712753 578.623901,198.572159 578.594788,198.523132 
	C578.485840,198.339447 578.326416,198.185699 578.112122,197.687500 
	C578.084412,196.904449 578.130432,196.454620 578.558594,195.897064 
	C579.291992,194.928024 579.947571,194.067566 579.950012,193.205292 
	C580.007812,172.297760 580.025574,151.389893 579.918152,130.482758 
	C579.912170,129.318466 578.767700,128.160019 578.079956,126.631470 
	C576.646912,124.257690 575.287109,122.251282 573.777405,119.937401 
	C573.207275,119.481758 572.787048,119.333580 572.296692,119.250458 
	C572.226562,119.315521 572.049866,119.241951 571.865662,118.939949 
	C571.246155,118.487587 570.810852,118.337227 570.009277,118.224152 
	C566.727600,117.352623 563.812195,116.443810 561.338013,115.672539 
	C564.879089,114.573799 568.645630,113.377342 572.429443,112.238426 
	C576.656006,110.966263 578.214783,108.441147 578.126587,103.790176 
	C577.807800,86.984138 577.978088,70.168526 577.995605,53.356556 
	C578.003845,45.425659 578.725647,37.407997 575.218994,30.006248 
	C577.574768,30.026323 579.289612,30.025461 578.201904,27.104073 
	C576.970703,23.797274 576.053650,20.373514 575.000000,17.000610 
	C575.000000,17.000610 575.000000,17.000000 575.338013,16.998379 
	C576.450623,16.664753 577.225342,16.332747 578.000000,16.000744 
	C610.020874,16.000462 642.041809,16.000177 674.841919,16.135853 
	C677.080750,16.847843 678.540344,17.423876 680.000000,17.999903 
	C679.842224,19.647453 679.684509,21.295008 679.185425,22.993931 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M324.996185,258.924957 
	C323.947449,257.227722 322.898682,255.530518 321.450104,253.186218 
	C321.713318,252.595261 322.348602,251.169052 322.993622,249.369751 
	C323.003357,248.996674 323.010925,248.989197 323.400421,248.990662 
	C324.568939,248.974625 325.347931,248.957123 326.587646,248.937927 
	C349.540497,248.946304 372.032623,248.936478 394.524719,248.972275 
	C415.346527,249.005402 436.168243,249.087357 457.072021,249.495422 
	C459.089569,249.965912 461.025146,250.088791 463.295349,250.141693 
	C464.080658,250.120605 464.531281,250.169540 464.981567,250.421722 
	C464.981232,250.624985 464.997559,251.031235 464.869141,251.321198 
	C464.493134,252.405716 464.245605,253.200272 463.630280,254.001587 
	C461.167389,255.596817 459.072266,257.185333 456.516052,258.852783 
	C412.735962,258.970093 369.416992,259.008453 325.822693,259.015106 
	C325.363647,258.963928 325.179932,258.944458 324.996185,258.924957 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M499.198303,238.377441 
	C499.198334,238.377441 499.185913,238.721588 498.785645,238.873779 
	C497.924866,239.350632 497.464386,239.675308 497.003937,240.000000 
	C496.116852,239.813858 495.229736,239.627716 493.806061,239.328979 
	C495.486267,237.140732 496.833252,235.386444 498.165497,233.651398 
	C490.127167,226.753189 486.718750,226.216263 479.102325,230.818878 
	C479.583221,235.752655 480.089691,240.948685 480.730560,247.523575 
	C478.958618,249.490128 476.363098,252.425613 478.682983,257.767548 
	C480.423035,261.774323 476.850067,263.479218 472.154297,262.818878 
	C470.656799,261.423492 469.839844,260.209717 468.874451,258.785126 
	C467.804840,257.738495 466.883667,256.902679 465.978180,255.800140 
	C465.997284,255.355301 466.000702,255.177200 466.263977,254.793518 
	C467.350037,253.060059 468.176239,251.532166 469.273376,249.776520 
	C470.235779,247.004669 472.633698,244.384262 469.106293,241.803162 
	C469.004791,241.581970 468.948608,241.098495 469.089752,240.776672 
	C470.091095,236.997726 469.361786,236.219086 465.624634,237.004150 
	C464.502106,237.004684 463.753754,237.005234 462.679352,236.975525 
	C461.428833,236.833130 460.504395,236.720978 459.579926,236.608826 
	C460.037689,237.076004 460.495483,237.543182 460.956268,238.228073 
	C460.959290,238.445786 460.982330,238.880646 460.763214,239.057617 
	C460.050964,239.498657 459.557861,239.762726 458.720825,240.011047 
	C457.917938,239.994629 457.458954,239.993958 457.000000,239.993286 
	C457.035950,239.662964 457.222260,239.241806 457.085663,239.015717 
	C454.208954,234.253998 455.524933,231.052780 462.726257,229.717010 
	C457.521210,227.014801 454.109924,225.243835 449.411743,222.804764 
	C450.483704,224.804779 451.118347,225.739243 451.498413,226.767776 
	C451.883179,227.808975 452.016144,228.943222 452.259430,230.036713 
	C450.888977,230.159943 448.817291,230.906921 448.273132,230.295166 
	C446.246613,228.016861 444.721741,225.292313 443.005219,221.999542 
	C443.010681,220.528503 443.019501,219.793167 443.028320,219.057846 
	C443.028290,219.057846 442.994293,219.011398 443.442963,218.952530 
	C445.872498,218.586731 447.853394,218.279800 449.849060,218.139923 
	C449.941559,218.521088 449.987030,218.741837 450.083008,219.365845 
	C451.649139,220.175842 453.124146,220.905670 454.617615,220.945679 
	C459.998383,221.089935 465.385406,221.001236 470.770050,221.001297 
	C483.847107,221.001450 496.924164,221.002029 510.161835,221.313385 
	C512.354553,222.189362 514.411194,222.680786 516.409790,223.346420 
	C517.651611,223.759995 518.805542,224.437515 519.999023,225.262543 
	C520.005066,225.705643 520.010925,225.882797 520.016785,226.059952 
	C520.016785,226.059952 519.750793,226.258438 519.250854,226.176544 
	C518.169128,226.354889 517.587219,226.615128 517.005371,226.875351 
	C516.650269,226.979996 516.295166,227.084625 515.394653,227.166565 
	C514.565918,227.339935 514.282654,227.535980 513.999390,227.732056 
	C513.306335,227.992477 512.613281,228.252884 511.491211,228.189713 
	C509.818451,227.714630 508.574707,227.563156 507.330933,227.411682 
	C507.420013,228.390015 507.509064,229.368378 507.598083,230.346741 
	C506.091339,231.535156 504.584595,232.723587 502.447327,234.115173 
	C501.617859,234.714325 501.418915,235.110321 501.219940,235.506302 
	C501.219940,235.506302 501.190796,235.805161 500.725708,235.948242 
	C499.906525,236.853363 499.552429,237.615402 499.198303,238.377441 
M468.482849,254.587448 
	C468.482849,254.587448 468.417084,254.477127 468.482849,254.587448 
M513.412109,225.483276 
	C513.412109,225.483276 513.522888,225.417038 513.412109,225.483276 
z"/>
<path fill="#5FB65A" opacity="1.000000" stroke="none" 
	d="
M327.001892,283.175934 
	C327.001892,283.175934 326.564514,282.946930 326.289307,282.502258 
	C326.035889,280.065643 326.057648,278.073730 326.407043,276.063080 
	C327.161865,275.956451 327.588989,275.868500 328.478394,275.854370 
	C368.917999,275.875122 408.895386,275.822083 449.134033,275.862305 
	C450.309021,275.895111 451.222656,275.834595 452.478851,275.823883 
	C454.205963,275.838623 455.590546,275.803558 457.273132,275.836029 
	C458.046417,275.601685 458.521698,275.299805 459.277466,274.895325 
	C460.048676,274.451752 460.539398,274.110718 461.275513,273.787109 
	C461.881104,273.721649 462.102905,273.500183 462.183533,273.141296 
	C462.180725,273.142548 462.203888,273.175659 462.530304,273.200500 
	C463.509125,273.177795 464.161530,273.130249 465.004883,273.332581 
	C465.792511,273.742615 466.389221,273.902710 467.107727,274.324005 
	C467.803314,275.082062 468.377106,275.578949 468.950928,276.075836 
	C468.950928,276.075836 468.916412,276.014984 468.897217,276.370911 
	C469.035492,277.125732 469.192963,277.524597 469.307251,278.307098 
	C469.133636,279.968903 469.003265,281.247101 468.601624,282.697205 
	C468.075073,283.208557 467.819794,283.548035 467.266968,283.948700 
	C466.403900,284.557678 465.838379,285.105469 465.272858,285.653259 
	C465.272858,285.653259 465.163879,285.547516 465.068115,285.581482 
	C464.972351,285.615448 464.941223,285.591797 464.601929,285.367737 
	C463.186157,285.068787 462.109711,284.993958 460.898315,284.703552 
	C460.322815,284.272888 459.882263,284.057770 459.095215,283.561829 
	C455.178467,283.248962 451.608185,283.216888 447.571655,283.113831 
	C439.630829,283.030487 432.156158,282.996033 424.681549,283.009674 
	C395.450928,283.062897 366.220337,283.131287 336.551788,283.107849 
	C333.736603,283.078613 331.359314,283.135559 328.655518,283.116089 
	C327.886627,283.085052 327.444275,283.130493 327.001892,283.175934 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M449.834259,217.972885 
	C447.853394,218.279800 445.872498,218.586731 443.471100,218.958481 
	C443.070618,218.858215 443.040924,218.664139 443.117798,218.532135 
	C450.683319,205.542419 451.435669,191.427490 451.224823,176.758545 
	C450.718170,141.509781 451.123596,106.248535 450.980133,70.993073 
	C450.962189,66.586060 449.853149,62.183498 449.613312,57.448196 
	C450.984619,59.730915 452.859650,62.340672 452.870636,64.958244 
	C453.052399,108.266266 453.010284,151.575287 452.973999,194.884048 
	C452.971863,197.444565 452.594849,200.004761 452.358398,202.993057 
	C456.810883,201.705307 455.971680,198.422318 455.973602,195.710587 
	C456.008118,146.405212 455.658203,97.095299 456.313324,47.798618 
	C456.430969,38.944637 449.034454,33.680325 450.456116,24.262608 
	C448.340942,25.911175 447.597809,26.490383 446.854675,27.069599 
	C445.554016,24.330074 444.253387,21.590536 442.971863,18.431889 
	C442.990936,18.012777 443.105255,17.899759 443.440735,17.972708 
	C444.901093,18.631475 446.026001,19.217293 446.685852,19.560913 
	C448.366882,18.173494 449.683441,17.086887 451.000000,16.000275 
	C484.020905,16.000120 517.041809,15.999968 550.844604,16.037849 
	C552.085144,16.686825 552.543823,17.297770 552.538574,17.983074 
	C518.187988,18.057432 484.301300,18.057432 449.605408,18.057432 
	C451.658813,22.810551 453.503082,26.517277 454.864929,30.393497 
	C456.166809,34.099068 457.839172,37.977242 457.855347,41.785885 
	C458.062012,90.448341 457.718292,139.114822 458.217133,187.773071 
	C458.332306,199.009033 454.854309,208.528351 449.834259,217.972885 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M468.948608,241.098495 
	C468.948608,241.098495 469.004791,241.581970 469.056274,242.204483 
	C468.243774,245.159164 467.379730,247.491318 466.360107,249.821182 
	C466.204498,249.818893 465.993652,250.047806 465.733765,250.049316 
	C465.309906,250.106705 465.145905,250.162582 464.981903,250.218475 
	C464.531281,250.169540 464.080658,250.120605 462.926025,250.030792 
	C460.477997,249.709213 458.734009,249.428497 456.990021,249.147797 
	C436.168243,249.087357 415.346527,249.005402 394.524719,248.972275 
	C372.032623,248.936478 349.540497,248.946304 326.525330,248.509827 
	C326.018951,246.063797 326.035645,244.044144 326.289703,241.984039 
	C326.527069,241.943573 326.979065,241.777359 327.411194,241.872238 
	C329.900665,241.910202 331.958038,241.853271 334.015411,241.796341 
	C371.367554,241.815201 408.719696,241.834061 446.858398,241.904785 
	C451.441345,241.252823 455.802216,243.889603 459.064728,240.026794 
	C459.557861,239.762726 460.050964,239.498657 461.183594,239.081482 
	C463.220367,238.893585 464.617645,238.858795 466.276733,238.995346 
	C467.341858,239.810623 468.145233,240.454559 468.948608,241.098495 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M448.872742,275.769012 
	C408.895386,275.822083 368.917999,275.875122 328.424561,275.555908 
	C327.275177,274.791931 326.641846,274.400208 325.734802,273.909119 
	C324.973633,274.539459 324.486206,275.269104 323.998413,276.332336 
	C323.997742,277.110565 323.997498,277.555298 323.997223,278.000000 
	C320.745636,277.108154 318.821167,275.667511 321.248535,272.065643 
	C321.746307,271.327057 322.187561,269.776764 321.808563,269.346985 
	C319.433441,266.653809 321.349884,264.234741 322.321045,261.405212 
	C323.790802,262.700500 324.933502,264.317535 326.055145,265.952301 
	C326.034119,265.970001 326.028992,265.917755 326.172394,266.184998 
	C326.874634,266.642883 327.433502,266.833496 328.034668,267.319122 
	C328.483063,267.929260 328.889191,268.244354 329.295288,268.559448 
	C329.532959,268.048615 329.770660,267.537781 330.423645,267.022217 
	C332.222534,267.021332 333.606110,267.025238 335.086823,267.422913 
	C336.357086,268.210815 337.529633,268.947479 338.703430,268.949432 
	C375.505707,269.010223 412.308167,269.019562 449.110260,268.916443 
	C450.411804,268.912781 451.709930,267.684235 453.449432,267.020325 
	C455.949158,266.990997 458.009094,266.967926 460.060730,266.971436 
	C460.052399,266.998077 460.034241,266.945557 460.022217,267.331512 
	C459.006378,270.133881 458.002533,272.550293 456.988281,275.167023 
	C456.977905,275.367340 456.975128,275.768494 456.975128,275.768494 
	C455.590546,275.803558 454.205963,275.838623 452.341125,275.432922 
	C450.864807,275.251099 449.868774,275.510040 448.872742,275.769012 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M459.441681,283.842651 
	C459.882263,284.057770 460.322815,284.272888 460.883698,284.977417 
	C461.001953,285.649597 460.999939,285.832336 460.997925,286.015106 
	C458.147156,291.234558 451.538513,292.762177 447.157410,289.542267 
	C445.744720,288.504059 443.583984,288.066956 441.763611,288.062103 
	C409.460571,287.976410 377.156708,288.082733 344.855011,287.859955 
	C341.928192,287.839813 339.014893,285.853302 336.250549,284.317291 
	C336.672119,283.697815 336.866791,283.477600 336.989716,283.194031 
	C366.220337,283.131287 395.450928,283.062897 424.681549,283.009674 
	C432.156158,282.996033 439.630829,283.030487 447.885559,283.370667 
	C452.257629,283.746521 455.849670,283.794586 459.441681,283.842651 
M457.412018,285.482452 
	C457.412018,285.482452 457.523163,285.415955 457.412018,285.482452 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M527.140686,273.998199 
	C525.359802,273.688110 523.578918,273.377991 521.382629,272.747498 
	C520.810242,272.174622 520.653259,271.922180 520.496277,271.669769 
	C520.330139,272.082916 520.164062,272.496063 519.997986,272.909241 
	C518.928467,272.960571 517.858887,273.011932 516.340393,272.654846 
	C514.927124,272.463898 513.962769,272.681396 512.998474,272.898895 
	C509.632263,272.954926 506.266083,273.010956 502.449524,272.973328 
	C503.962280,270.819519 505.925385,268.759338 508.338745,266.350250 
	C510.163757,266.436066 511.603699,266.729858 512.901794,267.330353 
	C518.075317,269.723541 523.296814,271.378876 528.968018,269.081696 
	C530.046021,269.070526 531.123962,269.059357 532.666809,269.505249 
	C533.673035,270.553864 534.188660,271.633392 534.759583,271.663452 
	C542.681274,272.080688 550.608337,272.467987 558.538696,272.617554 
	C562.916016,272.700134 567.337891,271.918793 571.671326,272.301086 
	C574.476074,272.548553 577.157349,274.195770 580.487549,275.437012 
	C581.658691,279.965759 581.381104,280.287811 576.122437,280.262238 
	C575.099670,280.257233 574.086975,281.076599 573.045349,281.447510 
	C570.599792,282.318359 568.175415,283.722778 565.678040,283.878632 
	C559.871887,284.240997 553.939331,284.618927 548.224182,283.812622 
	C542.763306,283.042206 537.392456,281.083710 532.229248,279.026764 
	C530.189087,278.214020 528.816956,275.724335 527.140686,273.998199 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M690.010071,45.000175 
	C690.010071,45.000179 689.781250,44.759827 689.852539,44.265972 
	C689.682312,43.193874 689.440796,42.615631 689.199280,42.037384 
	C689.094910,41.289547 688.990601,40.541718 689.141968,39.248291 
	C688.915649,38.140820 688.433716,37.578938 687.951782,37.017052 
	C687.605530,36.643963 687.259216,36.270874 686.930298,35.204945 
	C685.631714,31.341780 684.315857,28.171448 683.000000,25.001125 
	C683.473999,18.992765 687.651123,14.404122 694.850403,14.189510 
	C710.324036,13.728242 725.821533,14.058248 741.308960,14.128496 
	C741.813416,14.130785 742.314758,14.831637 742.665039,15.767160 
	C742.341187,16.875299 742.169922,17.423124 741.630615,18.070356 
	C740.508423,18.113943 739.754211,18.058121 738.951294,17.668137 
	C737.935059,17.552921 736.967407,17.771872 735.538574,18.083397 
	C722.718323,18.113932 710.359253,18.051889 697.950867,17.656826 
	C696.934326,17.544773 695.967041,17.765741 694.782349,18.061684 
	C694.564941,18.136656 694.114624,18.042707 693.761230,18.053028 
	C692.360413,18.630041 691.313049,19.196732 690.786316,19.481712 
	C693.349670,23.431110 695.633545,26.964302 697.932251,30.487812 
	C700.874634,34.998104 703.828918,39.500587 706.880188,44.365440 
	C707.031860,45.467323 707.081421,46.210182 706.846985,46.965584 
	C706.373779,46.985470 706.184448,46.992809 705.609009,46.954987 
	C699.827942,45.391354 699.127197,45.883099 698.998962,51.266785 
	C698.971619,52.413113 699.249695,53.699429 698.823364,54.674850 
	C698.028687,56.493084 696.819885,58.130363 695.783386,59.842930 
	C694.522278,58.561943 693.261108,57.280968 692.001221,55.583595 
	C692.324036,53.447788 693.169556,51.615257 692.829163,50.038788 
	C692.440796,48.240215 690.995850,46.669765 690.010071,45.000175 
M689.279968,27.254089 
	C689.805298,26.987478 690.330688,26.720865 690.856018,26.454248 
	C690.312561,26.027670 689.769043,25.601091 689.225586,25.174507 
	C689.135437,25.694048 689.045349,26.213589 689.279968,27.254089 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M754.007324,59.997917 
	C755.757446,60.242661 757.507629,60.487404 759.614014,60.781963 
	C760.386292,63.901173 761.236084,67.333420 762.085938,70.765663 
	C762.514832,70.708443 762.943665,70.651222 763.372559,70.594002 
	C763.749573,69.083160 763.811951,67.377052 764.593750,66.117424 
	C765.346436,64.904793 766.842834,64.153755 768.012451,63.199863 
	C768.997131,64.414459 770.615906,65.535057 770.813293,66.866394 
	C771.087646,68.717064 770.471069,70.776772 769.921814,72.655190 
	C769.022461,75.731102 767.580627,78.262550 770.767700,81.503883 
	C772.159058,82.918892 770.501282,87.264557 770.351318,90.285423 
	C770.244324,92.440498 770.272949,94.609138 770.377625,96.765083 
	C770.432739,97.899963 771.115479,99.080368 770.927063,100.135254 
	C770.532776,102.342590 769.749207,104.480385 769.125488,106.646729 
	C767.496460,105.840981 765.880371,105.007004 764.229614,104.248619 
	C763.796326,104.049545 763.255310,104.084709 762.259033,103.719109 
	C760.905457,102.294930 760.056580,101.163956 759.118347,99.662720 
	C759.011780,98.531517 758.994629,97.770561 759.271118,96.734444 
	C760.694458,90.822311 759.567139,85.671638 756.145630,80.608734 
	C756.000122,79.164284 755.942139,78.107521 756.197388,76.776154 
	C756.673706,74.402916 757.277893,72.213921 756.871826,70.231880 
	C756.491211,68.373932 755.041443,66.735016 753.910522,64.794418 
	C753.779724,64.264343 753.800537,63.939568 753.822449,63.318714 
	C753.625244,62.694260 753.427063,62.365883 753.153320,61.702881 
	C753.113831,60.914120 753.149719,60.459984 753.390991,60.004707 
	C753.596436,60.003563 754.007324,59.997917 754.007324,59.997917 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M432.827942,185.002533 
	C432.927002,185.431747 433.026093,185.860947 432.816772,186.872437 
	C432.178284,190.302750 430.349762,193.605087 434.797363,195.004608 
	C434.902527,195.439041 435.007660,195.873459 434.825378,196.649109 
	C434.357025,196.993759 434.176056,196.997177 433.995117,197.000610 
	C433.196991,196.783737 432.408295,196.427399 431.599213,196.372696 
	C426.490753,196.027374 422.400055,194.545837 422.877747,187.417618 
	C420.351166,188.798218 418.720337,189.689331 415.602478,191.393021 
	C416.977448,186.190033 418.066650,182.068405 419.315338,177.343246 
	C415.260284,176.841843 411.863281,176.353470 408.451080,176.021027 
	C405.601654,175.743408 403.130768,176.007370 402.720703,179.814316 
	C402.634796,180.611938 400.336243,182.033981 399.956909,181.786972 
	C398.368439,180.752380 397.122162,179.192368 395.706787,177.781265 
	C393.606995,179.324966 391.157562,181.125717 388.363708,182.553040 
	C388.348450,179.169922 388.677643,176.160233 389.006836,173.150528 
	C389.756805,173.046188 390.506744,172.941849 391.664459,173.276886 
	C392.650757,174.354355 393.229309,174.992447 393.807861,175.630539 
	C394.205841,174.763931 394.603821,173.897324 395.001770,173.030701 
	C406.028931,172.966782 417.056091,172.902863 428.539856,173.391479 
	C429.268799,177.978333 427.682739,182.656540 432.827942,185.002533 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M338.000000,187.000610 
	C337.282196,183.318466 336.245972,179.663300 335.931244,175.947037 
	C335.578094,171.776642 336.523224,167.421906 335.716431,163.384567 
	C334.853516,159.066330 334.573364,155.681976 339.010162,153.368546 
	C339.656647,153.031464 339.977417,152.069702 341.090637,151.153381 
	C342.460907,149.837265 343.189423,148.764740 343.917969,147.692200 
	C342.945709,147.128326 341.973450,146.564468 341.001221,146.000610 
	C337.252258,142.374588 336.460938,132.700333 339.588257,127.246582 
	C343.414825,128.435532 343.482635,131.049744 342.372253,134.227737 
	C341.441711,136.891022 340.608521,139.588303 339.385040,143.333099 
	C341.997864,142.575500 343.185730,142.231064 343.847382,142.039230 
	C344.416321,137.821945 344.920929,134.081726 345.550446,129.415329 
	C347.613098,130.956177 348.617126,131.706192 349.810059,133.116730 
	C348.080688,143.761627 346.256134,153.765427 344.196289,163.720551 
	C343.411621,167.512924 342.028748,171.177979 341.024048,174.929337 
	C339.951050,178.935684 339.002747,182.975449 338.000000,187.000610 
M346.587585,144.517029 
	C346.587585,144.517029 346.476959,144.582977 346.587585,144.517029 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M386.000214,223.003235 
	C386.188324,226.874207 383.487885,227.961395 380.528198,227.924271 
	C364.806671,227.727188 349.085968,227.377243 333.372681,226.849319 
	C332.455566,226.818512 331.606354,224.765335 331.112488,223.326004 
	C331.499390,222.998703 331.997559,222.999390 332.373535,222.998840 
	C333.832611,222.334000 334.915680,221.669678 336.467529,221.004120 
	C350.994293,221.002335 365.053528,220.911880 379.109283,221.090973 
	C381.414001,221.120316 383.703613,222.335907 386.000214,223.003235 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M364.695892,78.109703 
	C364.522888,78.844940 364.349915,79.580185 363.667908,80.734024 
	C362.106354,83.101784 361.053802,85.050941 360.001221,87.000107 
	C358.778290,84.020042 357.555389,81.039978 355.788269,76.733757 
	C356.718353,75.936501 358.741425,74.202423 361.350006,71.966446 
	C360.573669,71.633141 359.175018,71.032646 358.948303,70.935318 
	C356.759399,67.660103 354.820801,65.180244 353.383026,62.437832 
	C352.617401,60.977455 352.126770,58.895184 352.553650,57.388786 
	C353.391296,54.432877 354.710785,51.528370 356.346466,48.926720 
	C356.978699,47.921062 358.972656,47.610146 360.414337,47.310089 
	C362.998108,46.772346 365.633453,46.482441 368.614136,46.455788 
	C368.970490,50.208660 368.959961,53.594692 368.949463,56.980728 
	C368.954468,57.432468 368.959473,57.884209 368.510437,58.666843 
	C366.423065,60.519325 363.168671,63.022572 363.404602,63.412209 
	C366.064270,67.804306 363.619110,71.473579 362.516113,75.413429 
	C362.335602,76.058243 363.924683,77.198456 364.695892,78.109703 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M787.000000,56.000046 
	C783.481934,54.154354 781.632996,49.200130 782.168457,44.689659 
	C782.741516,39.861992 782.400879,34.911263 783.155457,30.124014 
	C783.856506,25.676628 785.475098,21.373863 786.837646,16.475504 
	C789.315857,18.041546 790.809692,18.985529 792.651733,20.401728 
	C792.800049,22.675287 792.482483,24.472847 792.424438,26.278749 
	C792.297668,30.219156 792.302979,34.163803 792.243042,38.439148 
	C792.093628,39.191509 791.955505,39.611443 791.530762,40.208328 
	C791.181152,41.931309 791.118103,43.477333 791.077637,45.364090 
	C790.999207,46.147095 790.898193,46.589367 790.496460,47.216267 
	C789.130493,50.267281 788.065247,53.133663 787.000000,56.000046 
M788.566772,34.295650 
	C788.294739,34.010685 788.022766,33.725719 787.750732,33.440735 
	C787.632812,33.553524 787.406128,33.758144 787.412659,33.765881 
	C787.666443,34.066475 787.941589,34.349056 788.566772,34.295650 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M839.000000,17.999458 
	C843.221191,18.466255 848.102844,25.743753 847.201477,29.762577 
	C846.867065,31.253738 847.549866,32.973286 847.770020,34.588524 
	C848.467041,39.702648 850.611572,44.743786 848.074890,49.981083 
	C846.716858,52.784912 845.753723,55.780022 844.271729,58.495716 
	C843.041016,56.866695 842.151001,55.430775 841.171326,53.593269 
	C840.988647,51.823250 840.895691,50.454807 841.119629,48.839951 
	C841.496094,47.155998 841.720703,45.703064 841.588196,44.283447 
	C840.770142,35.518372 839.871704,26.760798 839.000000,18.000366 
	C839.000000,18.000000 839.000000,17.999458 839.000000,17.999458 
M843.798218,26.392639 
	C843.794067,26.786119 843.789917,27.179600 843.785767,27.573080 
	C843.949036,27.574604 844.254211,27.587067 844.255005,27.576069 
	C844.285217,27.184551 844.286926,26.790836 843.798218,26.392639 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1012.004883,75.999390 
	C1013.453552,75.810463 1014.902222,75.621529 1017.803650,75.243111 
	C1018.862183,80.164429 1019.897888,85.149239 1021.023193,90.113731 
	C1021.382385,91.698296 1021.997681,93.223488 1022.463562,94.785469 
	C1023.321350,97.661140 1024.485596,100.495667 1024.915039,103.436028 
	C1025.444458,107.060158 1020.957764,110.599037 1024.559692,114.411560 
	C1023.619507,114.937759 1022.679382,115.463951 1021.238647,115.684555 
	C1019.479614,109.565346 1018.246399,103.746094 1016.956238,97.939491 
	C1015.330139,90.620705 1013.657471,83.312286 1012.004883,75.999390 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M937.911743,18.160595 
	C935.889771,18.172586 933.867737,18.184576 931.373901,17.760773 
	C929.934692,17.550255 928.967346,17.775530 928.000000,18.000809 
	C913.306641,18.067686 898.613342,18.134556 883.440674,17.803289 
	C882.640991,16.936737 882.320496,16.468323 882.000000,15.999915 
	C883.288452,15.333467 884.572388,14.097108 885.865967,14.086945 
	C902.472229,13.956467 919.080444,13.930074 935.686584,14.067493 
	C938.649109,14.092009 941.604553,14.979513 944.784607,16.085499 
	C945.026245,17.170097 945.046570,17.637733 945.066833,18.105370 
	C945.066833,18.105370 945.018677,18.018097 944.657349,18.020840 
	C942.167969,18.069252 940.039856,18.114923 937.911743,18.160595 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M753.185547,60.005852 
	C753.149719,60.459984 753.113831,60.914120 753.119629,62.006233 
	C753.381409,62.967739 753.601440,63.291267 753.821411,63.614792 
	C753.800537,63.939568 753.779724,64.264343 753.881226,65.223534 
	C754.630432,69.588890 755.257263,73.319824 755.884155,77.050766 
	C755.942139,78.107521 756.000122,79.164284 756.089355,81.010559 
	C757.072815,86.869926 758.025146,91.939774 758.977478,97.009613 
	C758.994629,97.770561 759.011780,98.531517 759.051270,100.060013 
	C759.712646,103.886658 760.351624,106.945747 760.990601,110.004845 
	C760.997437,110.452080 761.004272,110.899323 761.037231,112.084503 
	C761.385620,115.087563 761.666138,117.359535 762.037170,119.616615 
	C763.649902,129.427338 765.289001,139.233719 766.896423,149.374420 
	C766.982605,150.136734 767.090942,150.566284 767.134888,151.408173 
	C767.356750,154.886047 767.643127,157.951599 767.929504,161.017120 
	C766.954895,159.155518 765.392090,157.380661 765.102600,155.417984 
	C763.421814,144.022598 762.147278,132.567902 760.531616,121.162132 
	C758.453796,106.493668 756.316284,91.830879 753.941284,77.208412 
	C752.709473,69.624573 750.551514,62.183025 749.489319,54.582531 
	C748.737610,49.203506 749.121704,43.665756 749.451294,38.496857 
	C750.255676,40.869759 750.607544,42.944664 750.932068,45.394642 
	C751.015320,46.511868 751.125916,47.254025 751.169739,48.397289 
	C751.797180,52.534218 752.491333,56.270039 753.185547,60.005852 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M851.233826,117.050293 
	C851.108948,115.993568 850.984070,114.936844 851.155640,113.277435 
	C853.797363,108.987442 853.386169,106.008438 849.141479,103.606003 
	C848.953918,102.168236 848.846375,101.118439 849.115479,100.050941 
	C849.994751,100.022568 850.497375,100.011894 851.000000,100.001221 
	C851.669861,99.445656 852.392151,98.941368 852.997925,98.323059 
	C854.016968,97.282921 854.962036,96.170326 855.938232,95.088226 
	C856.931519,96.226982 858.515564,97.232986 858.806885,98.529480 
	C859.668152,102.362167 860.018555,106.309639 860.568726,110.212204 
	C860.614685,110.538429 860.792480,110.917656 860.686279,111.183060 
	C858.725342,116.081665 856.730591,120.966721 854.372559,125.527939 
	C853.079407,122.484268 852.156616,119.767281 851.233826,117.050293 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M386.320618,222.863388 
	C383.703613,222.335907 381.414001,221.120316 379.109283,221.090973 
	C365.053528,220.911880 350.994293,221.002335 336.091675,221.003418 
	C334.163940,221.669083 333.080750,222.334229 331.997559,222.999390 
	C331.997559,222.999390 331.499390,222.998703 331.250305,222.998352 
	C329.911804,222.665176 328.822388,222.332367 327.166321,221.723694 
	C325.733093,219.965424 324.866547,218.483017 324.000000,217.000610 
	C325.139801,216.355240 326.279602,215.709869 327.753571,215.060547 
	C327.725189,216.270035 327.362610,217.483490 326.896667,219.042969 
	C346.933472,219.042969 366.591064,219.042969 386.817169,219.042969 
	C386.254120,216.416977 385.754059,214.084824 385.257385,211.370300 
	C386.469513,211.429291 387.678284,211.870651 388.981934,213.019440 
	C389.384552,216.484161 389.692261,219.241470 390.000000,221.998779 
	C388.880341,222.240372 387.760651,222.481949 386.320618,222.863388 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M865.821655,146.676559 
	C866.497803,158.370728 863.299988,176.012344 859.243896,178.287140 
	C858.452393,174.790451 857.614319,171.602905 857.025818,168.369919 
	C856.088989,163.223434 855.227112,158.059509 854.500732,152.879501 
	C853.579773,146.311234 852.813599,139.721054 852.004578,133.137375 
	C851.448242,128.610306 850.932495,124.078232 850.818115,119.620834 
	C851.787537,121.804977 852.337341,123.916679 852.887085,126.028397 
	C852.911316,126.480278 852.935486,126.932167 853.006714,128.056610 
	C853.284302,129.849762 853.514832,130.970337 853.740356,132.424744 
	C853.872803,133.179916 854.010193,133.601273 854.085510,134.418488 
	C854.407898,136.877258 854.792297,138.940201 855.176636,141.003143 
	C855.147522,141.460205 855.118408,141.917252 855.108887,143.011932 
	C855.395081,144.159485 855.661560,144.669418 855.928040,145.179352 
	C855.928040,145.179352 855.931030,145.626221 855.970825,146.219727 
	C856.319702,148.550705 856.628723,150.288162 856.937805,152.025620 
	C856.966675,152.468094 856.995483,152.910568 857.042358,154.086243 
	C857.869995,159.489517 858.679565,164.159607 859.489136,168.829712 
	C859.986328,168.782913 860.483459,168.736130 860.980591,168.689331 
	C862.250366,162.456589 863.520142,156.223846 864.789917,149.991104 
	C864.883545,149.247040 864.977173,148.502975 865.244507,147.301697 
	C865.418213,146.844498 865.821655,146.676559 865.821655,146.676559 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M430.000000,222.000000 
	C428.926514,222.752350 427.616486,223.312866 426.822571,224.291901 
	C424.121368,227.623047 422.077545,227.533966 420.077087,223.864075 
	C419.378571,222.582565 418.651764,221.248672 417.653931,220.212021 
	C415.480164,217.953781 415.636932,216.037430 417.686066,213.725037 
	C419.478119,211.702805 420.837158,209.296844 422.889008,206.336960 
	C424.457245,208.396362 425.593231,209.888168 426.870544,212.070343 
	C428.007935,215.840469 429.003967,218.920242 430.000000,222.000000 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M764.000000,113.999924 
	C765.564819,113.509003 767.222351,113.201088 768.665222,112.469574 
	C769.831909,111.878098 770.739990,110.776421 772.714355,109.086624 
	C771.915710,114.889046 771.289978,119.434853 770.582764,124.573006 
	C773.065063,124.573006 774.761169,124.573006 777.010620,124.543472 
	C777.752319,126.055305 777.940613,127.596680 778.156738,129.110596 
	C778.184631,129.083145 778.207092,129.158112 777.905884,129.104950 
	C777.401550,129.034744 777.198364,129.017685 776.629150,129.004852 
	C773.175415,129.339600 770.087708,129.670105 766.611938,129.914337 
	C765.259949,124.792816 764.287354,119.759155 763.364990,114.716309 
	C763.329590,114.522705 763.777344,114.240723 764.000000,113.999924 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1032.240356,176.994934 
	C1032.101196,176.264343 1031.962158,175.533752 1032.137573,174.207520 
	C1031.977905,171.073547 1031.503662,168.535248 1031.029297,165.996948 
	C1035.726074,165.425903 1036.791138,163.198898 1035.895874,156.921219 
	C1035.634766,155.090485 1036.279663,153.130539 1036.509155,151.229828 
	C1037.175903,151.213699 1037.842529,151.197571 1038.509155,151.181442 
	C1038.945557,152.460785 1039.460571,153.719650 1039.800293,155.024170 
	C1040.335571,157.079468 1040.919434,159.145859 1041.166748,161.244781 
	C1041.359619,162.882690 1041.754395,165.232880 1040.884766,166.137436 
	C1036.129395,171.084045 1035.751465,172.414307 1038.952637,178.616943 
	C1037.339600,179.997971 1035.679199,180.994705 1033.654541,181.993118 
	C1033.177856,181.248795 1033.065430,180.502777 1033.201538,179.223236 
	C1033.046753,178.124802 1032.643555,177.559875 1032.240356,176.994934 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M442.934448,18.069967 
	C419.227203,18.089361 395.576477,18.051565 371.461121,17.632645 
	C370.997681,16.834303 370.998840,16.417089 371.000000,15.999874 
	C394.687347,16.000046 418.374725,16.000219 442.535461,16.318153 
	C443.040955,17.057196 443.073120,17.478477 443.105255,17.899759 
	C443.105255,17.899759 442.990936,18.012777 442.934448,18.069967 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M791.817383,40.031380 
	C791.955505,39.611443 792.093628,39.191509 792.475952,38.121086 
	C793.504395,31.314966 794.288574,25.159338 795.072754,19.003712 
	C795.072754,19.003712 795.281006,18.826014 795.564697,18.640652 
	C796.231750,18.296227 796.615234,18.137167 797.421021,18.067669 
	C799.562317,18.101723 801.281250,18.046213 803.461365,18.083374 
	C813.281616,18.112106 822.640686,18.048166 832.328369,18.076313 
	C833.104736,18.110357 833.552368,18.052313 834.268616,18.093987 
	C834.916016,18.169855 835.294861,18.146008 835.734253,18.509346 
	C835.809692,19.933937 835.824585,20.971342 835.839478,22.008743 
	C832.363159,21.339211 828.905151,20.238762 825.406738,20.090567 
	C818.299438,19.789511 811.167480,19.878813 804.051086,20.042698 
	C798.033997,20.181265 796.738281,22.014635 795.911011,27.963568 
	C794.937561,34.963638 793.271057,41.867325 791.451599,48.586727 
	C790.931152,47.917652 790.864197,47.474648 790.797119,47.031639 
	C790.898193,46.589367 790.999207,46.147095 791.330811,45.093369 
	C791.646790,42.998405 791.732117,41.514896 791.817383,40.031380 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M835.673645,18.122158 
	C835.294861,18.146008 834.916016,18.169855 834.231262,17.796141 
	C833.518921,17.079409 833.112488,16.760241 832.706055,16.441090 
	C832.470642,16.955484 832.235168,17.469860 831.999695,17.984232 
	C822.640686,18.048166 813.281616,18.112106 803.408325,17.739748 
	C801.929382,16.868946 800.964661,16.434439 800.000000,15.999933 
	C801.279663,15.333506 802.549866,14.111280 803.840515,14.089324 
	C813.435791,13.926081 823.041992,13.810555 832.627869,14.152656 
	C834.687927,14.226174 836.679260,16.223772 838.851135,17.667248 
	C839.000000,17.999458 839.000000,18.000000 838.584229,18.001675 
	C837.336914,18.042950 836.505249,18.082554 835.673645,18.122158 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M338.000732,187.386139 
	C339.002747,182.975449 339.951050,178.935684 341.024048,174.929337 
	C342.028748,171.177979 343.411621,167.512924 344.196289,163.720551 
	C346.256134,153.765427 348.080688,143.761627 349.999481,133.388916 
	C350.697571,132.998489 351.395111,132.996368 352.441406,132.992828 
	C348.752930,152.157990 344.715729,171.324570 340.420868,190.765167 
	C339.442627,189.950012 338.722046,188.860840 338.000732,187.386139 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1012.003052,75.626755 
	C1013.657471,83.312286 1015.330139,90.620705 1016.956238,97.939491 
	C1018.246399,103.746094 1019.479614,109.565346 1020.870300,115.689789 
	C1021.160828,116.863251 1021.319153,117.725891 1021.768494,119.125977 
	C1022.706360,120.775612 1023.353149,121.887810 1024.000000,123.000000 
	C1023.996704,125.416840 1023.993286,127.833679 1023.972534,130.928253 
	C1023.623108,132.077103 1023.291138,132.548233 1022.959106,133.019379 
	C1019.647217,118.395882 1016.331299,103.773293 1013.025513,89.148430 
	C1011.717285,83.360970 1010.431458,77.568451 1009.185059,71.385330 
	C1010.156860,72.412987 1011.078979,73.833557 1012.003052,75.626755 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M945.530273,18.097725 
	C945.046570,17.637733 945.026245,17.170097 945.002991,16.351570 
	C963.636475,16.000273 982.273193,15.966783 1000.909180,16.057068 
	C1002.545837,16.064997 1004.178528,16.883848 1005.860718,17.739254 
	C985.936768,18.131668 965.965271,18.110874 945.530273,18.097725 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M783.041077,102.996292 
	C782.936035,106.425339 783.080444,109.884521 782.670532,113.276726 
	C781.962219,119.138565 780.890320,124.955856 780.005859,130.797119 
	C778.306580,142.020691 776.647705,153.250427 774.911194,164.468170 
	C774.794922,165.219116 774.167542,165.890961 773.395752,166.379120 
	C773.156311,164.689438 773.240051,163.211716 773.451050,161.752380 
	C775.022217,150.885574 776.618652,140.022415 778.207153,129.158112 
	C778.207092,129.158112 778.184631,129.083145 778.457581,128.843018 
	C779.423828,123.078674 780.117188,117.554459 780.810486,112.030243 
	C780.921265,111.593689 781.032043,111.157143 781.360168,110.099648 
	C782.065308,107.317894 782.553223,105.157097 783.041077,102.996292 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M877.098816,69.021767 
	C877.083130,69.468185 877.067444,69.914612 876.658569,70.703156 
	C875.842712,71.363731 875.420166,71.682175 874.997559,72.000610 
	C872.589050,70.820396 870.180603,69.640175 867.550110,68.351181 
	C867.120911,70.127594 866.455566,72.881569 865.637634,76.266983 
	C858.494934,68.946114 864.634521,64.530502 867.693481,59.459087 
	C870.235901,62.630798 872.754822,65.773155 875.909180,68.963181 
	C876.729370,69.014481 876.914062,69.018127 877.098816,69.021767 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M464.813934,273.082703 
	C464.161530,273.130249 463.509125,273.177795 462.465668,272.742676 
	C462.078339,270.716492 462.082062,269.173035 462.448181,267.634399 
	C463.530701,267.710754 464.250824,267.782288 465.021484,268.209625 
	C466.044312,269.042023 467.016602,269.518555 467.988922,269.995117 
	C469.676910,269.531433 471.423615,269.213348 473.036469,268.563293 
	C474.662872,267.907837 476.156403,266.922821 478.899658,265.436707 
	C478.899658,269.350800 479.470581,272.635162 478.751007,275.607056 
	C477.906189,279.096191 475.934052,278.165344 473.789642,276.037170 
	C472.755951,275.011292 471.271973,270.280396 468.973633,275.707275 
	C468.377106,275.578949 467.803314,275.082062 466.938629,274.048218 
	C466.036499,273.368439 465.425201,273.225586 464.813934,273.082703 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1002.873962,35.945255 
	C1002.930603,35.204441 1002.987244,34.463631 1003.467896,33.358475 
	C1004.451538,32.602577 1005.011047,32.211029 1005.570618,31.819492 
	C1005.047974,31.546579 1004.525269,31.273655 1004.002625,31.000725 
	C1004.012817,30.549915 1004.023010,30.099112 1004.449097,29.264648 
	C1005.576660,27.920460 1006.288330,26.959932 1007.000000,25.999409 
	C1013.715149,33.049191 1009.474060,40.485123 1007.375061,47.532764 
	C1005.611755,43.471001 1004.242859,39.708130 1002.873962,35.945255 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M390.004791,222.365356 
	C389.692261,219.241470 389.384552,216.484161 389.039032,213.363129 
	C391.077667,212.904343 393.154114,212.809280 396.421417,212.659698 
	C396.527191,212.545898 397.589691,211.402985 399.255646,209.610886 
	C399.255646,214.796204 399.255646,219.126740 399.255646,223.457275 
	C398.918671,223.755936 398.581665,224.054581 398.244690,224.353241 
	C397.532654,222.714905 396.820618,221.076584 395.665527,218.418869 
	C394.066223,222.138596 393.284882,223.955872 391.920319,227.129593 
	C390.917633,224.821869 390.463593,223.776901 390.004791,222.365356 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M528.708984,268.782532 
	C523.296814,271.378876 518.075317,269.723541 512.901794,267.330353 
	C511.603699,266.729858 510.163757,266.436066 508.394501,266.000671 
	C506.235809,265.024078 504.471619,264.048187 502.353699,263.036133 
	C500.429047,261.198090 498.858063,259.396179 497.143433,256.880737 
	C496.994263,254.779770 496.988770,253.392288 496.983276,252.004822 
	C496.983246,252.004822 496.994019,252.001099 497.244568,252.000488 
	C497.495117,251.999893 497.988647,252.000320 497.988647,252.000320 
	C503.061005,264.009613 514.154785,265.834930 525.048889,267.985809 
	C526.171631,268.207458 527.315735,268.320160 528.708984,268.782532 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M430.333313,222.000122 
	C429.003967,218.920242 428.007935,215.840469 427.006531,212.381577 
	C430.533417,212.067368 432.487305,210.613159 432.125488,206.109894 
	C432.500946,204.607513 432.750732,203.804367 433.000519,203.001221 
	C433.408203,202.998642 433.815857,202.996063 434.529205,202.993256 
	C433.884338,208.154953 432.933807,213.316864 431.850006,219.202545 
	C435.592438,219.202545 438.850952,219.202545 442.568909,219.130188 
	C443.019501,219.793167 443.010681,220.528503 443.000916,221.631607 
	C440.958344,221.999588 438.916656,221.999786 436.037506,222.000214 
	C434.133331,222.000687 433.066681,222.000961 432.000000,222.001221 
	C431.555542,222.000900 431.111115,222.000565 430.333313,222.000122 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1031.019775,165.560577 
	C1031.503662,168.535248 1031.977905,171.073547 1032.160278,173.834518 
	C1029.251099,162.571167 1026.634277,151.085052 1024.015869,139.599274 
	C1023.725647,138.326355 1023.426453,137.055466 1023.481384,135.424072 
	C1024.554199,135.709656 1025.277100,136.354813 1026.000000,136.999985 
	C1026.000732,138.714157 1026.001465,140.428299 1026.040527,142.929138 
	C1027.431519,147.361435 1028.730347,151.028992 1030.190308,154.631119 
	C1030.434326,155.233215 1031.379028,155.551285 1032.000000,156.000610 
	C1031.670044,159.041809 1031.340088,162.082993 1031.019775,165.560577 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M783.095093,97.049164 
	C783.076477,97.498009 783.057800,97.946846 782.700317,98.951614 
	C781.573547,101.338432 780.785522,103.169319 779.997559,105.000214 
	C777.926819,101.860733 775.856079,98.721252 773.112793,94.562103 
	C781.603271,95.172981 779.328125,87.122749 783.905273,84.062042 
	C784.704041,84.009750 784.880920,84.012863 785.083008,84.391365 
	C784.998352,85.509361 784.888611,86.251968 784.409180,87.061737 
	C780.684021,90.146225 781.552063,93.565575 783.095093,97.049164 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M763.869202,113.722893 
	C763.777344,114.240723 763.329590,114.522705 763.364990,114.716309 
	C764.287354,119.759155 765.259949,124.792816 766.617554,130.298569 
	C767.340393,135.814697 767.739502,140.857544 767.940735,145.908249 
	C767.981750,146.937515 767.278625,147.996429 766.918579,149.041672 
	C765.289001,139.233719 763.649902,129.427338 762.037170,119.616615 
	C761.666138,117.359535 761.385620,115.087563 761.133057,112.407806 
	C762.047974,112.477402 762.893188,112.961632 763.869202,113.722893 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M865.829285,146.264755 
	C865.821655,146.676559 865.418213,146.844498 865.215820,146.926849 
	C864.798523,143.476028 864.150208,139.903305 864.468079,136.418716 
	C864.972900,130.885574 866.122925,125.411293 867.280701,119.197067 
	C868.642883,115.654236 869.724304,112.826439 870.805725,109.998642 
	C869.403137,120.145187 868.001099,130.291809 866.597351,140.438202 
	C866.347534,142.243637 866.090576,144.048065 865.829285,146.264755 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M360.143066,98.141693 
	C358.308655,107.257278 356.474274,116.372864 354.091492,125.541061 
	C353.695770,123.062653 353.848511,120.531631 354.001221,118.000610 
	C354.002228,114.281525 354.003265,110.562431 354.312195,106.269058 
	C355.197479,104.341049 356.464783,102.848145 356.224915,101.659286 
	C355.456024,97.847931 357.539581,97.851089 360.143066,98.141693 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1007.911133,63.983475 
	C1007.911133,63.983482 1007.735901,63.791245 1007.787109,63.385662 
	C1007.637390,62.665939 1007.436340,62.351799 1007.235291,62.037666 
	C1007.122070,61.292793 1007.008789,60.547916 1007.187805,59.195709 
	C1007.320007,56.392307 1007.160034,54.196228 1007.000000,52.000160 
	C1007.978027,53.147369 1009.336304,54.144936 1009.858398,55.471573 
	C1011.116882,58.669441 1011.075745,62.400726 1015.530884,63.754742 
	C1016.381042,64.013123 1016.064941,68.108765 1016.396057,71.576683 
	C1013.632141,69.874496 1012.169800,68.973892 1010.351807,67.682968 
	C1009.301147,66.189590 1008.606140,65.086533 1007.911133,63.983475 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M360.126923,98.114456 
	C357.539581,97.851089 355.456024,97.847931 356.224915,101.659286 
	C356.464783,102.848145 355.197479,104.341049 354.311279,105.847717 
	C353.170990,105.310966 351.899017,104.777763 351.588318,103.903099 
	C350.533203,100.932709 356.152100,90.991669 359.614441,90.371918 
	C360.331390,91.820129 360.663544,92.909050 360.995667,93.997971 
	C360.980682,94.770523 360.965698,95.543076 360.656616,96.820007 
	C360.166870,97.561920 360.074219,97.834488 360.084564,98.142090 
	C360.084564,98.142090 360.110779,98.087227 360.126923,98.114456 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M750.959412,45.019577 
	C750.607544,42.944664 750.255676,40.869759 749.800293,38.391079 
	C749.781494,37.542934 749.866211,37.098557 750.049561,35.955265 
	C750.835938,31.890076 751.523865,28.523796 752.211731,25.157516 
	C752.211731,25.157516 752.178650,25.047043 752.453857,24.880617 
	C753.084778,22.619425 753.440430,20.524660 754.020203,18.092043 
	C755.829529,17.503021 757.414795,17.251852 759.000000,17.000685 
	C759.000000,17.444950 759.000000,17.889214 758.964600,19.016088 
	C757.286987,22.799200 755.644714,25.899700 754.002441,29.000198 
	C754.001648,29.444544 754.000793,29.888889 753.714966,30.882217 
	C752.954163,33.287563 752.478271,35.143925 752.002441,37.000286 
	C752.002869,39.041611 752.003296,41.082939 751.889893,43.786297 
	C751.503784,44.638748 751.231628,44.829166 750.959412,45.019577 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M840.802734,49.086365 
	C840.895691,50.454807 840.988647,51.823250 841.140747,53.980156 
	C841.825012,57.512375 842.450195,60.256126 843.075317,62.999886 
	C843.070679,63.446308 843.065979,63.892735 843.076660,65.076813 
	C843.963074,71.547478 844.834167,77.280495 845.705200,83.013504 
	C844.471558,78.600014 843.027649,74.232445 842.051514,69.762711 
	C840.416809,62.276962 839.013367,54.737850 837.686096,47.190678 
	C837.472046,45.973618 838.156250,44.598583 838.994507,43.405205 
	C839.976807,45.371342 840.389771,47.228851 840.802734,49.086365 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M874.000000,23.000000 
	C872.170105,23.874695 870.340271,24.749388 868.510376,25.624079 
	C867.721375,23.439449 866.514160,21.275621 866.403809,19.057247 
	C866.371643,18.409828 869.409851,17.401930 871.141479,16.924728 
	C873.561523,16.257818 876.075989,15.933703 878.775146,16.069469 
	C879.005981,17.126207 879.011780,17.576399 879.017578,18.026590 
	C879.017578,18.026590 879.002441,17.998320 878.623169,18.004333 
	C875.259399,18.930614 872.274841,19.850885 869.290283,20.771154 
	C869.286377,21.174345 869.282471,21.577534 869.278564,21.980724 
	C870.852356,22.320482 872.426208,22.660240 874.000000,23.000000 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M330.001221,206.000290 
	C328.009857,204.324295 324.263153,202.488144 324.398376,201.006165 
	C324.666687,198.065186 327.075287,195.319489 328.599365,192.493088 
	C329.233368,192.715988 329.867371,192.938873 330.501373,193.161774 
	C330.501373,195.104614 330.405548,197.053665 330.530853,198.988388 
	C330.636322,200.616501 330.999023,202.227966 331.503815,204.189804 
	C331.173615,205.021973 330.587402,205.511139 330.001221,206.000290 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M864.415894,150.006790 
	C863.520142,156.223846 862.250366,162.456589 860.980591,168.689331 
	C860.483459,168.736130 859.986328,168.782913 859.489136,168.829712 
	C858.679565,164.159607 857.869995,159.489517 857.136475,154.407562 
	C858.136536,155.463821 859.060486,156.931946 859.797119,158.102386 
	C861.158936,155.510132 862.600342,152.766312 864.415894,150.006790 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M876.000000,59.999989 
	C874.640869,58.935955 872.402527,58.071491 872.106262,56.766228 
	C871.495667,54.075859 871.560669,51.062897 872.206543,48.390182 
	C872.346558,47.810890 876.104248,48.105801 878.422546,48.338814 
	C878.099609,50.460278 877.447449,52.217274 877.027649,54.028141 
	C876.572327,55.992611 876.333374,58.007244 876.000000,59.999989 
M873.704468,53.566868 
	C873.989380,53.294895 874.274353,53.022926 874.559265,52.750980 
	C874.446533,52.633003 874.333801,52.515026 874.221069,52.397034 
	C873.936096,52.669338 873.651184,52.941643 873.704468,53.566868 
M873.412231,49.483250 
	C873.412231,49.483250 873.522888,49.417202 873.412231,49.483250 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M480.688782,281.156677 
	C483.454010,283.392670 483.993469,286.592896 481.546722,288.015747 
	C479.097382,289.440125 475.520782,291.529663 471.873718,288.176453 
	C474.865479,285.653931 477.588043,283.358368 480.688782,281.156677 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M496.925598,272.638641 
	C496.789520,273.217407 496.584076,273.431335 495.850677,273.826355 
	C493.987213,274.147705 492.657745,274.293793 491.328278,274.439850 
	C491.248718,274.834473 491.169159,275.229095 491.089569,275.623688 
	C492.985718,276.738068 494.881866,277.852478 496.778015,278.966858 
	C496.906860,279.698334 497.035706,280.429810 496.912903,281.694855 
	C496.440430,282.818970 496.219604,283.409485 495.998779,284.000000 
	C492.458282,282.948395 489.389160,281.147705 489.162903,277.129059 
	C489.067230,275.429993 489.980865,272.897186 491.269623,272.111969 
	C492.603058,271.299500 494.947754,272.146729 496.925598,272.638641 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M329.994751,206.388000 
	C330.587402,205.511139 331.173615,205.021973 331.878662,204.266083 
	C334.365082,200.382034 336.732605,196.764694 339.430511,193.071594 
	C339.832031,199.649475 335.244476,204.139633 332.157684,209.321167 
	C331.053375,211.174850 329.831329,212.958420 328.360657,214.926804 
	C328.701599,212.311646 329.344940,209.543686 329.994751,206.388000 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M383.188171,200.996796 
	C383.135345,200.551758 383.082489,200.106720 383.453491,199.246460 
	C384.918610,195.887299 385.959900,192.943344 387.001221,189.999390 
	C387.334106,189.991287 387.667603,189.993195 387.999817,189.973450 
	C389.896912,189.860703 392.725067,187.949860 392.724731,191.749405 
	C392.724670,192.695755 390.230682,193.641907 388.460052,194.885101 
	C388.014526,197.256012 387.397369,200.540192 386.136230,203.720154 
	C384.724243,202.742905 383.956207,201.869843 383.188171,200.996796 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M874.362183,23.006010 
	C872.426208,22.660240 870.852356,22.320482 869.278564,21.980724 
	C869.282471,21.577534 869.286377,21.174345 869.290283,20.771154 
	C872.274841,19.850885 875.259399,18.930614 878.635376,18.015913 
	C879.549133,22.435135 880.071411,26.848789 880.638184,31.639416 
	C880.308777,32.012466 879.934875,32.008541 879.089233,31.751389 
	C874.445679,30.550898 878.140686,28.506954 877.990234,26.999107 
	C876.901611,25.670088 875.812988,24.341055 874.362183,23.006010 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M870.912964,109.678360 
	C869.724304,112.826439 868.642883,115.654236 867.280762,118.740997 
	C865.796387,117.448654 864.592712,115.897339 863.389038,114.346039 
	C866.071533,112.605751 862.809814,106.974602 868.861450,107.112427 
	C870.033325,107.486198 870.538879,107.751083 871.044434,108.015976 
	C871.036316,108.463348 871.028259,108.910713 870.912964,109.678360 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M875.994263,60.380455 
	C876.333374,58.007244 876.572327,55.992611 877.027649,54.028141 
	C877.447449,52.217274 878.099609,50.460278 878.823486,48.339485 
	C879.386658,47.996212 879.775757,47.993011 880.456604,47.988686 
	C879.699524,54.799885 878.650696,61.612213 877.350342,68.723160 
	C876.914062,69.018127 876.729370,69.014481 876.267456,69.004120 
	C875.989624,66.251900 875.989075,63.506405 875.994263,60.380455 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M790.496460,47.216270 
	C790.864197,47.474648 790.931152,47.917652 791.084473,48.686386 
	C791.087952,49.450214 791.004944,49.888306 790.707581,50.964390 
	C789.360718,58.075802 788.228149,64.549217 786.834473,70.861572 
	C786.381409,70.133568 786.189453,69.566628 785.997559,68.999695 
	C786.331665,64.936356 786.665833,60.873016 787.000000,56.404861 
	C788.065247,53.133663 789.130493,50.267281 790.496460,47.216270 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M574.595947,17.000380 
	C576.053650,20.373514 576.970703,23.797274 578.201904,27.104073 
	C579.289612,30.025461 577.574768,30.026323 575.128418,29.935614 
	C574.646179,29.870296 574.639526,29.640125 574.731873,29.198349 
	C576.457520,23.234547 571.301147,21.387392 568.999634,17.995066 
	C570.730408,17.663427 572.461121,17.331789 574.595947,17.000380 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M553.002441,17.908720 
	C552.543823,17.297770 552.085144,16.686825 551.313232,16.037788 
	C554.682068,12.115771 562.411255,13.292512 565.509766,18.666857 
	C564.509705,19.180218 563.920044,19.297947 562.995789,19.411118 
	C561.775208,19.200340 560.889160,18.994120 559.920288,18.448767 
	C558.219238,18.088459 556.601013,18.067282 554.650269,18.107407 
	C553.879395,18.082050 553.440918,17.995388 553.002441,17.908720 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1025.316895,69.038712 
	C1024.034668,70.245354 1022.975586,71.148125 1020.485474,73.270691 
	C1020.066345,69.261284 1019.654175,66.742134 1019.703308,64.232025 
	C1019.710632,63.861401 1022.734131,62.971222 1022.942383,63.279785 
	C1024.056641,64.930481 1024.720581,66.885216 1025.316895,69.038712 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M369.224060,56.714539 
	C368.959961,53.594692 368.970490,50.208660 368.988708,46.411003 
	C369.330505,44.257980 369.664642,42.516582 370.251648,40.222412 
	C371.277130,39.112286 372.049774,38.554928 372.822449,37.997574 
	C371.714508,44.147831 370.606567,50.298092 369.224060,56.714539 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M874.861938,72.321388 
	C875.420166,71.682175 875.842712,71.363731 876.542297,71.020035 
	C875.741394,78.809959 874.663513,86.625145 873.321228,94.731712 
	C872.735718,93.153656 871.967163,91.232399 872.176514,89.424408 
	C872.827087,83.807236 873.848694,78.233032 874.861938,72.321388 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M758.930237,16.628372 
	C757.414795,17.251852 755.829529,17.503021 753.593994,18.042757 
	C750.629211,18.217192 748.314758,18.103060 745.980591,17.692234 
	C745.640564,16.930349 745.320251,16.465157 745.000000,15.999963 
	C747.824219,12.987888 754.037476,13.102692 758.930237,16.628372 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M845.824097,83.374283 
	C844.834167,77.280495 843.963074,71.547478 843.163574,65.401917 
	C843.627930,64.991425 844.020630,64.993492 844.707886,65.401978 
	C845.668335,68.205498 846.334167,70.602592 847.000000,72.999695 
	C847.223694,76.747490 847.447327,80.495285 847.832397,84.970741 
	C847.619019,86.477234 847.244263,87.256058 846.659302,87.832886 
	C846.240479,87.085747 846.031738,86.540627 845.822998,85.995499 
	C845.863037,85.242012 845.903015,84.488541 845.824097,83.374283 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1032.162720,155.626892 
	C1031.379028,155.551285 1030.434326,155.233215 1030.190308,154.631119 
	C1028.730347,151.028992 1027.431519,147.361435 1026.041748,143.357544 
	C1033.348755,146.313950 1034.048828,147.671158 1032.162720,155.626892 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M869.000000,103.000008 
	C868.167725,102.995415 867.020142,103.336517 866.563599,102.920021 
	C865.297058,101.764816 864.318176,100.294258 863.225647,98.948280 
	C864.594604,98.380486 865.922241,97.602646 867.347717,97.322441 
	C868.224365,97.150131 869.250610,97.738480 870.603760,98.359039 
	C870.331909,100.155212 869.665955,101.577614 869.000000,103.000008 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M785.630493,69.043671 
	C786.189453,69.566628 786.381409,70.133568 786.861511,71.198898 
	C787.035095,72.130783 786.920471,72.564255 786.471069,73.073540 
	C786.167542,74.464752 786.198730,75.780159 786.239258,77.463745 
	C786.108276,78.553078 785.968079,79.274216 785.523193,79.996384 
	C784.812317,79.998268 784.406189,79.999130 784.000000,80.000000 
	C780.730713,76.154449 781.150024,72.532883 785.630493,69.043671 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M846.869446,88.034882 
	C847.244263,87.256058 847.619019,86.477234 847.996887,85.349197 
	C852.044373,87.168030 853.067139,91.184784 850.073120,95.918488 
	C848.977539,95.997162 848.568604,95.995979 848.035278,95.694214 
	C847.879578,94.963860 847.848145,94.534088 848.103027,93.899315 
	C847.996216,92.462029 847.602966,91.229744 847.105957,89.678635 
	C846.957947,88.918159 846.913696,88.476524 846.869446,88.034882 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M563.330322,19.415680 
	C563.920044,19.297947 564.509705,19.180218 565.549683,19.027826 
	C566.787170,18.677195 567.574402,18.361225 568.680603,18.020161 
	C571.301147,21.387392 576.457520,23.234547 574.487061,28.929979 
	C572.869629,23.276091 568.176453,21.260475 563.330322,19.415680 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M794.765869,19.019958 
	C794.288574,25.159338 793.504395,31.314966 792.487305,37.788658 
	C792.302979,34.163803 792.297668,30.219156 792.424438,26.278749 
	C792.482483,24.472847 792.800049,22.675287 793.000000,20.436935 
	C793.486328,19.678688 793.972656,19.357447 794.765869,19.019958 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M866.156982,33.927017 
	C864.996399,32.729836 863.287537,31.010422 863.616882,30.411301 
	C864.458679,28.879793 866.208313,27.847357 867.594849,26.615294 
	C868.263428,27.106636 868.932007,27.597980 869.600586,28.089323 
	C868.579407,30.031225 867.558228,31.973127 866.156982,33.927017 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1002.617371,36.226456 
	C1004.242859,39.708130 1005.611755,43.471001 1006.990356,47.617012 
	C1006.623962,48.680470 1006.247986,49.360786 1005.485962,50.184772 
	C1004.186890,45.721512 1003.273804,41.114582 1002.617371,36.226456 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M877.609802,27.043854 
	C878.140686,28.506954 874.445679,30.550898 878.808716,31.749325 
	C873.986755,35.251308 873.498718,35.032616 872.235474,28.698347 
	C873.874634,28.169966 875.552002,27.629288 877.609802,27.043854 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M388.648102,173.239716 
	C388.677643,176.160233 388.348450,179.169922 388.010864,182.590118 
	C387.299530,184.631821 386.596619,186.263016 385.541595,187.949783 
	C386.222778,183.113190 387.256073,178.221054 388.648102,173.239716 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M682.623169,25.000614 
	C684.315857,28.171448 685.631714,31.341780 686.907837,34.942535 
	C684.627197,32.142056 682.386169,28.911144 680.064941,25.343304 
	C680.738464,25.004284 681.492371,25.002197 682.623169,25.000614 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M847.316284,72.841171 
	C846.334167,70.602592 845.668335,68.205498 845.002441,65.403900 
	C850.624817,66.171707 851.403442,68.446342 847.316284,72.841171 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M353.635742,118.043442 
	C353.848511,120.531631 353.695770,123.062653 353.843689,125.833466 
	C354.126038,126.508774 354.107727,126.944290 353.664307,127.687958 
	C352.826141,127.997414 352.413055,127.998711 352.000000,128.000000 
	C348.842377,124.300529 349.205078,121.469765 353.635742,118.043442 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M754.352661,28.963518 
	C755.644714,25.899700 757.286987,22.799200 758.964600,19.349289 
	C759.452332,19.769957 760.482910,20.915903 760.265381,21.250572 
	C758.545410,23.896526 756.587524,26.387924 754.352661,28.963518 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M780.510315,112.223099 
	C780.117188,117.554459 779.423828,123.078674 778.429688,128.870483 
	C777.940613,127.596680 777.752319,126.055305 777.281982,124.256821 
	C778.070068,120.138458 779.140137,116.277206 780.510315,112.223099 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M750.932068,45.394646 
	C751.231628,44.829166 751.503784,44.638748 751.891663,44.223717 
	C756.524475,46.061684 757.167480,48.133209 754.237183,52.599815 
	C752.893494,50.903198 752.065002,49.449692 751.236511,47.996185 
	C751.125916,47.254025 751.015320,46.511868 750.932068,45.394646 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M369.133698,18.954136 
	C370.083466,23.365263 371.033203,27.776392 371.637634,32.598404 
	C370.861572,33.006191 370.430786,33.003098 370.000000,33.000000 
	C369.999084,31.933620 369.998138,30.867243 369.862701,29.089615 
	C369.152130,26.918901 368.576050,25.459436 368.000000,23.999968 
	C367.999634,22.611000 367.999268,21.222036 368.282684,19.407135 
	C368.755524,18.972178 368.944611,18.963158 369.133698,18.954136 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M501.598114,263.000824 
	C500.904938,264.627350 500.040741,266.535767 498.588715,267.728577 
	C498.085083,268.142273 496.124084,266.781769 494.828766,266.231659 
	C494.857422,265.697205 494.886047,265.162720 494.914673,264.628265 
	C497.008514,264.086060 499.102356,263.543854 501.598114,263.000824 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M398.047211,187.375046 
	C398.574097,189.920074 398.744812,192.309601 398.979095,195.589615 
	C394.021759,191.933197 393.996552,190.329025 398.047211,187.375046 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M869.000000,103.400002 
	C869.665955,101.577614 870.331909,100.155212 870.997681,98.366226 
	C871.420044,97.681473 871.842468,97.363312 872.541809,97.019470 
	C872.406738,100.479942 871.994690,103.966080 871.313538,107.734085 
	C870.538879,107.751083 870.033325,107.486198 869.263855,107.110657 
	C869.000000,105.933327 869.000000,104.866661 869.000000,103.400002 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M369.645630,33.064529 
	C370.430786,33.003098 370.861572,33.006191 371.615417,33.012276 
	C372.218536,33.499855 372.498596,33.984444 372.609619,34.861450 
	C372.315247,35.581833 372.425720,35.820835 372.772034,35.970867 
	C372.843567,36.422318 372.915100,36.873768 372.904541,37.661396 
	C372.049774,38.554928 371.277130,39.112286 370.252258,39.834900 
	C369.763733,37.709789 369.527466,35.419426 369.645630,33.064529 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M361.260498,93.723198 
	C360.663544,92.909050 360.331390,91.820129 359.999023,90.365448 
	C359.999329,89.249802 359.999908,88.499901 360.000854,87.375053 
	C361.053802,85.050941 362.106354,83.101784 363.502808,81.076248 
	C363.072876,85.149384 362.299103,89.298904 361.260498,93.723198 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M468.872925,282.525299 
	C469.003265,281.247101 469.133636,279.968903 469.488098,278.114807 
	C469.811798,277.024323 469.911407,276.509674 470.010986,275.995056 
	C470.622589,276.600128 471.688293,277.157990 471.757080,277.819489 
	C471.933685,279.517822 472.302948,281.776825 471.423401,282.862122 
	C470.235962,284.327393 467.952271,284.904266 465.705933,285.757812 
	C465.838379,285.105469 466.403900,284.557678 467.603149,283.856812 
	C468.448883,283.310913 468.660889,282.918121 468.872925,282.525299 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M323.768707,216.731750 
	C324.866547,218.483017 325.733093,219.965424 326.800415,221.723602 
	C322.532196,224.572037 322.163544,219.956146 320.207062,218.123993 
	C321.317200,217.570282 322.427307,217.016586 323.768707,216.731750 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M443.440735,17.972708 
	C443.073120,17.478477 443.040955,17.057196 443.004059,16.318480 
	C445.635101,15.785733 448.270905,15.570421 450.953369,15.677694 
	C449.683441,17.086887 448.366882,18.173494 446.685852,19.560913 
	C446.026001,19.217293 444.901093,18.631475 443.440735,17.972708 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M326.979065,241.777359 
	C326.979065,241.777359 326.527069,241.943573 325.924927,242.029022 
	C324.552124,244.406052 323.781525,246.697632 323.010925,248.989197 
	C323.010925,248.989197 323.003357,248.996674 323.001678,248.998337 
	C322.473846,247.744339 321.157837,245.831009 321.559906,245.348160 
	C323.197937,243.380829 321.663696,238.545990 326.781311,240.038239 
	C326.990326,240.992477 326.984711,241.384918 326.979065,241.777359 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1007.000366,25.632746 
	C1006.288330,26.959932 1005.576660,27.920460 1004.557983,28.942558 
	C1004.804260,25.638605 1005.357605,22.273083 1005.878296,18.506788 
	C1006.692627,18.315701 1007.539612,18.525385 1008.693359,19.200895 
	C1009.002502,20.111383 1009.004944,20.556049 1009.007385,21.000723 
	C1008.338501,22.422518 1007.669617,23.844301 1007.000366,25.632746 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M458.720825,240.011047 
	C455.802216,243.889603 451.441345,241.252823 447.258728,241.853729 
	C449.944153,241.167511 453.015869,240.584167 456.543793,239.997040 
	C457.458954,239.993958 457.917938,239.994629 458.720825,240.011047 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M850.494629,20.311663 
	C845.600647,20.604717 847.106750,17.813509 847.533813,15.512447 
	C848.097900,15.294686 848.661926,15.076926 849.226013,14.859165 
	C849.733154,16.579388 850.240295,18.299610 850.494629,20.311663 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M869.304871,84.510262 
	C867.473022,84.254013 865.932373,83.752518 864.391724,83.251038 
	C865.117065,82.528351 865.842407,81.805656 866.567749,81.082962 
	C867.577209,82.143646 868.586609,83.204338 869.304871,84.510262 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M751.169739,48.397293 
	C752.065002,49.449692 752.893494,50.903198 753.860962,52.678352 
	C754.001343,55.041130 754.002686,57.082264 754.005676,59.560654 
	C754.007324,59.997917 753.596436,60.003563 753.390991,60.004711 
	C752.491333,56.270039 751.797180,52.534218 751.169739,48.397293 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M326.076172,265.934601 
	C324.933502,264.317535 323.790802,262.700500 322.327637,261.035278 
	C322.769104,260.320099 323.531067,259.653107 324.644592,258.955566 
	C325.179932,258.944458 325.363647,258.963928 325.771118,259.443542 
	C326.021973,261.914001 326.049072,263.924316 326.076172,265.934601 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M496.148132,284.304382 
	C496.219604,283.409485 496.440430,282.818970 496.943237,282.065704 
	C499.077271,284.391876 500.929382,286.880768 502.896881,289.685181 
	C500.774017,288.203369 498.535736,286.406097 496.148132,284.304382 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M386.643982,189.996643 
	C385.959900,192.943344 384.918610,195.887299 383.527832,198.916351 
	C383.567047,196.173843 383.955688,193.346222 384.600952,190.242218 
	C385.333984,189.975174 385.810364,189.984543 386.643982,189.996643 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1026.341187,136.945160 
	C1025.277100,136.354813 1024.554199,135.709656 1023.529663,135.030396 
	C1023.117310,134.561844 1023.006531,134.127426 1022.927490,133.356186 
	C1023.291138,132.548233 1023.623108,132.077103 1023.977539,131.302979 
	C1026.854736,132.070633 1029.079346,133.428162 1026.341187,136.945160 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M761.276489,109.745056 
	C760.351624,106.945747 759.712646,103.886658 759.140625,100.430267 
	C760.056580,101.163956 760.905457,102.294930 761.877136,103.712952 
	C761.854065,105.828423 761.708191,107.656845 761.276489,109.745056 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M843.400513,62.949028 
	C842.450195,60.256126 841.825012,57.512375 841.230408,54.381737 
	C842.151001,55.430775 843.041016,56.866695 843.966736,58.651611 
	C843.910217,60.299797 843.817993,61.598984 843.400513,62.949028 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M289.416626,289.439117 
	C287.963165,289.034973 286.756897,288.387390 285.550659,287.739807 
	C286.283813,287.013397 287.016968,286.286987 287.750122,285.560547 
	C288.388031,286.772278 289.025970,287.983978 289.416626,289.439117 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M779.997559,105.393936 
	C780.785522,103.169319 781.573547,101.338432 782.601074,99.274078 
	C782.880249,100.095566 782.919800,101.150528 783.000244,102.600891 
	C782.553223,105.157097 782.065308,107.317894 781.340515,109.761108 
	C780.734863,108.624901 780.366211,107.206284 779.997559,105.393936 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M680.326111,17.949596 
	C678.540344,17.423876 677.080750,16.847843 675.310547,16.135830 
	C676.486511,12.255521 678.761108,15.918735 680.591309,15.405004 
	C680.611633,16.236431 680.631897,17.067860 680.326111,17.949596 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M853.166504,126.021072 
	C852.337341,123.916679 851.787537,121.804977 851.091064,119.344254 
	C850.986084,118.559303 851.027832,118.123383 851.151672,117.368881 
	C852.156616,119.767281 853.079407,122.484268 854.003540,125.600784 
	C853.818604,126.004791 853.632324,126.009277 853.166504,126.021072 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M880.709839,35.270386 
	C881.190369,37.485695 881.386658,39.969124 881.323914,42.739334 
	C880.851746,40.530247 880.638672,38.034374 880.709839,35.270386 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M469.022888,258.995941 
	C469.839844,260.209717 470.656799,261.423492 471.733215,262.819336 
	C471.521149,263.907166 471.049622,264.812897 469.950012,265.861511 
	C468.868378,265.998962 468.414856,265.993530 468.112366,265.621368 
	C468.516602,263.168396 468.769745,261.082153 469.022888,258.995941 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M777.923950,109.952957 
	C777.418457,111.145744 776.898193,111.967979 776.377991,112.790215 
	C775.973083,112.663246 775.568176,112.536278 775.163330,112.409309 
	C775.379456,111.066582 775.595581,109.723862 775.811646,108.381134 
	C776.510864,108.781555 777.210022,109.181976 777.923950,109.952957 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M341.000916,146.417175 
	C341.973450,146.564468 342.945709,147.128326 343.917969,147.692200 
	C343.189423,148.764740 342.460907,149.837265 341.366821,150.954590 
	C341.001007,149.610840 341.000824,148.222290 341.000916,146.417175 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M327.411194,241.872238 
	C326.984711,241.384918 326.990326,240.992477 327.000427,240.305817 
	C329.274017,240.364380 331.543152,240.717148 333.913849,241.433136 
	C331.958038,241.853271 329.900665,241.910202 327.411194,241.872238 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M383.132812,201.371628 
	C383.956207,201.869843 384.724243,202.742905 385.745514,203.807983 
	C385.837250,205.188095 385.675720,206.376190 385.233704,207.792114 
	C384.327942,205.928772 383.702698,203.837616 383.132812,201.371628 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M323.998230,278.375000 
	C323.997498,277.555298 323.997742,277.110565 324.311707,276.326111 
	C325.040009,275.947174 325.454620,275.907990 325.921875,275.921936 
	C325.974518,275.975067 326.079407,276.081787 326.079407,276.081787 
	C326.057648,278.073730 326.035889,280.065643 326.043457,282.479950 
	C326.072784,282.902313 325.982788,283.049866 325.712952,283.065674 
	C325.443146,283.081451 325.014801,283.066162 325.014801,283.066162 
	C324.845734,283.051117 324.676666,283.036102 324.252991,282.677917 
	C323.994812,281.889771 323.991302,281.444794 323.987793,280.999786 
	C323.991608,280.249847 323.995422,279.499908 323.998230,278.375000 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1006.553833,51.974033 
	C1007.160034,54.196228 1007.320007,56.392307 1007.188721,58.817841 
	C1006.287476,56.886589 1005.677551,54.725880 1005.141052,52.242840 
	C1005.512207,51.929646 1005.809937,51.938778 1006.553833,51.974033 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M367.645020,24.064684 
	C368.576050,25.459436 369.152130,26.918901 369.860443,28.689735 
	C366.562042,28.780586 364.605011,27.742607 367.645020,24.064684 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M496.617371,252.011047 
	C496.988770,253.392288 496.994263,254.779770 496.999268,256.583618 
	C493.487732,254.739532 493.344482,253.784546 496.617371,252.011047 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M369.292480,18.726254 
	C368.944611,18.963158 368.755524,18.972178 368.282593,18.990444 
	C366.191223,15.872694 367.493225,15.022720 370.788818,15.738886 
	C370.998840,16.417089 370.997681,16.834303 370.996674,17.564369 
	C370.481628,18.084270 369.966431,18.291321 369.292480,18.726254 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M875.428833,44.996506 
	C872.551880,43.412979 872.551880,43.412979 877.372681,40.972218 
	C876.569580,42.778282 876.122375,43.784081 875.428833,44.996506 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M855.500793,140.935028 
	C854.792297,138.940201 854.407898,136.877258 854.322754,134.530457 
	C855.081421,134.497559 855.540710,134.748550 856.000000,134.999542 
	C855.941589,136.955322 855.883240,138.911118 855.500793,140.935028 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M799.666687,16.000034 
	C800.964661,16.434439 801.929382,16.868946 802.947083,17.647078 
	C801.281250,18.046213 799.562317,18.101723 797.440613,17.772223 
	C797.358521,16.924749 797.679260,16.462286 798.000000,15.999834 
	C798.444458,15.999944 798.888916,16.000040 799.666687,16.000034 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1024.272217,122.801636 
	C1023.353149,121.887810 1022.706360,120.775612 1022.031006,119.331406 
	C1023.130676,119.080002 1024.258911,119.160614 1025.864136,119.275299 
	C1025.332153,120.616638 1024.938232,121.609955 1024.272217,122.801636 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M752.348938,36.939865 
	C752.478271,35.143925 752.954163,33.287563 753.714966,31.215481 
	C753.565186,32.959656 753.130310,34.919552 752.348938,36.939865 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M797.954102,15.676561 
	C797.679260,16.462286 797.358521,16.924749 797.018188,17.682659 
	C796.615234,18.137167 796.231750,18.296227 795.539795,18.506042 
	C795.124023,17.429199 795.016785,16.301601 794.909546,15.174003 
	C795.909058,15.233769 796.908630,15.293535 797.954102,15.676561 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M320.114258,196.429871 
	C321.116760,196.604004 322.062103,197.112778 323.007416,197.621552 
	C322.572906,198.078796 322.138397,198.536041 321.703888,198.993286 
	C321.154938,198.250351 320.606018,197.507416 320.114258,196.429871 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1025.584961,83.849739 
	C1025.737915,85.231674 1025.667969,86.345032 1025.598022,87.458389 
	C1025.063110,87.351318 1024.528076,87.244247 1023.993164,87.137169 
	C1024.449463,85.951836 1024.905762,84.766502 1025.584961,83.849739 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M857.212280,151.811951 
	C856.628723,150.288162 856.319702,148.550705 856.067993,146.420898 
	C856.579285,147.885147 857.033081,149.741714 857.212280,151.811951 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M777.925659,84.574646 
	C777.622314,83.507034 777.645691,82.511757 777.669128,81.516495 
	C778.225159,81.703072 778.781250,81.889648 779.337280,82.076202 
	C778.975586,82.933128 778.613953,83.790062 777.925659,84.574646 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M856.264771,134.778320 
	C855.540710,134.748550 855.081421,134.497559 854.384888,134.134598 
	C854.010193,133.601273 853.872803,133.179916 854.006592,132.312683 
	C854.520935,131.577484 854.764160,131.288147 855.007324,130.998795 
	C855.514771,132.184891 856.022156,133.371017 856.264771,134.778320 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M848.159607,95.994797 
	C848.568604,95.995979 848.977539,95.997162 849.693237,95.999329 
	C850.312378,97.103577 850.624817,98.206841 850.968628,99.655663 
	C850.497375,100.011894 849.994751,100.022568 848.892700,99.777527 
	C848.248718,98.346146 848.204163,97.170471 848.159607,95.994797 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M432.813965,202.768097 
	C432.750732,203.804367 432.500946,204.607513 432.125610,205.705322 
	C431.475189,205.429688 430.950409,204.859390 430.425598,204.289078 
	C431.159546,203.704376 431.893463,203.119675 432.813965,202.768097 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M336.551788,283.107849 
	C336.866791,283.477600 336.672119,283.697815 336.201660,283.946655 
	C333.951996,284.034729 331.906464,284.030792 329.420410,283.822083 
	C328.979919,283.617310 328.982056,283.192505 328.982056,283.192505 
	C331.359314,283.135559 333.736603,283.078613 336.551788,283.107849 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M327.914062,287.238464 
	C328.303589,288.001526 328.384735,288.681122 328.465912,289.360748 
	C327.919403,289.267761 327.372864,289.174805 326.826355,289.081818 
	C327.086151,288.439545 327.345917,287.797272 327.914062,287.238464 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M352.975098,68.470192 
	C352.505524,67.527405 352.364960,66.588387 352.224396,65.649376 
	C352.687073,65.627777 353.149750,65.606178 353.612427,65.584587 
	C353.509644,66.547714 353.406860,67.510841 352.975098,68.470192 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M432.061035,222.347626 
	C433.066681,222.000961 434.133331,222.000687 435.600006,222.000519 
	C435.160461,224.765869 433.810150,224.674500 432.061035,222.347626 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M784.000000,80.400169 
	C784.406189,79.999130 784.812317,79.998268 785.588257,80.349419 
	C785.657959,81.806282 785.357910,82.911133 785.057861,84.015976 
	C784.880920,84.012863 784.704041,84.009750 784.261597,84.002922 
	C783.997437,82.932915 783.998718,81.866623 784.000000,80.400169 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M879.411377,18.110022 
	C879.011780,17.576399 879.005981,17.126207 879.000122,16.338388 
	C879.750000,16.000542 880.500000,16.000320 881.625000,16.000004 
	C882.320496,16.468323 882.640991,16.936737 882.980713,17.703335 
	C881.935059,18.065495 880.870056,18.129473 879.411377,18.110022 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M744.666687,16.000023 
	C745.320251,16.465157 745.640564,16.930349 745.586304,17.783083 
	C744.140747,18.104063 743.069641,18.037500 741.998657,17.970943 
	C742.169922,17.423124 742.341187,16.875299 742.756226,16.163570 
	C743.444458,15.999804 743.888916,15.999943 744.666687,16.000023 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M433.996948,197.377930 
	C434.176056,196.997177 434.357025,196.993759 434.809448,196.985046 
	C435.282379,198.208832 435.483795,199.437866 435.416656,200.863251 
	C434.764954,199.958130 434.381866,198.856689 433.996948,197.377930 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M467.992249,269.746429 
	C467.016602,269.518555 466.044312,269.042023 465.189270,267.970093 
	C465.867706,267.239075 466.428894,267.103424 467.099792,267.230164 
	C467.473389,267.995148 467.737305,268.497772 468.001221,269.000397 
	C468.001221,269.000397 467.995544,269.497742 467.992249,269.746429 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M577.956787,15.687341 
	C577.225342,16.332747 576.450623,16.664753 575.338013,16.998722 
	C575.331909,15.313471 575.606628,13.523872 577.956787,15.687341 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M468.345459,268.947449 
	C467.737305,268.497772 467.473389,267.995148 467.199036,267.011047 
	C467.374481,266.246826 467.632050,266.066345 467.961304,265.988129 
	C468.414856,265.993530 468.868378,265.998962 469.662170,266.004639 
	C469.564850,266.968079 469.127258,267.931274 468.345459,268.947449 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M854.983276,130.690674 
	C854.764160,131.288147 854.520935,131.577484 854.011597,131.978867 
	C853.514832,130.970337 853.284302,129.849762 853.119995,128.378601 
	C853.777222,128.812851 854.368225,129.597687 854.983276,130.690674 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1007.899170,64.345741 
	C1008.606140,65.086533 1009.301147,66.189590 1010.000488,67.646454 
	C1009.813232,68.006042 1009.621582,68.011826 1009.142334,68.027481 
	C1008.532227,66.927582 1008.209717,65.817795 1007.899170,64.345741 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M497.838531,242.969650 
	C497.877563,244.707520 497.916565,246.445389 497.656982,248.611801 
	C497.358368,249.040344 496.927734,249.032455 496.927734,249.032455 
	C496.942841,247.302139 496.957947,245.571838 497.184387,243.406647 
	C497.395721,242.971756 497.838531,242.969650 497.838531,242.969650 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M352.001434,128.336761 
	C352.413055,127.998711 352.826141,127.997414 353.548950,127.996063 
	C353.724701,128.844193 353.590729,129.692398 353.219055,130.767792 
	C352.655212,130.221176 352.329041,129.447342 352.001434,128.336761 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1039.493408,145.667221 
	C1040.036377,146.011429 1040.362061,146.570343 1040.687744,147.129242 
	C1040.409302,147.256500 1040.130737,147.383759 1039.852295,147.511017 
	C1039.660156,146.967972 1039.468018,146.424957 1039.493408,145.667221 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M323.666840,281.045685 
	C323.991302,281.444794 323.994812,281.889771 323.998566,282.668579 
	C323.467926,283.117157 322.937042,283.231873 322.406189,283.346619 
	C322.719421,282.594940 323.032654,281.843262 323.666840,281.045685 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M321.693665,211.475189 
	C322.448303,211.469315 322.983826,211.674362 323.519348,211.879395 
	C323.384552,212.153625 323.249756,212.427841 323.114990,212.702057 
	C322.568146,212.363403 322.021301,212.024734 321.693665,211.475189 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1009.305176,20.961422 
	C1009.004944,20.556049 1009.002502,20.111383 1009.000061,19.333214 
	C1009.517822,19.232548 1010.035583,19.465384 1010.553406,19.698215 
	C1010.236572,20.106182 1009.919739,20.514154 1009.305176,20.961422 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M328.655518,283.116089 
	C328.982056,283.192505 328.979919,283.617310 328.981995,283.830200 
	C328.348541,283.938293 327.713013,283.833557 327.039703,283.452362 
	C327.444275,283.130493 327.886627,283.085052 328.655518,283.116089 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M497.001678,240.252197 
	C497.464386,239.675308 497.924866,239.350632 498.680542,239.010162 
	C498.846191,239.564926 498.716614,240.135483 498.057312,240.861481 
	C497.346069,241.014160 497.164551,241.011383 496.983032,241.008621 
	C496.988464,240.840561 496.993927,240.672501 497.001678,240.252197 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M496.761902,241.151001 
	C497.164551,241.011383 497.346069,241.014160 497.799957,241.017456 
	C498.041779,241.454788 498.011292,241.891602 497.909668,242.649017 
	C497.838531,242.969650 497.395721,242.971756 497.173828,242.974304 
	C496.814850,242.415695 496.677795,241.854538 496.761902,241.151001 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M496.713959,249.179550 
	C496.927734,249.032455 497.358368,249.040344 497.573669,249.039581 
	C497.842041,249.468597 497.895142,249.898407 497.689392,250.659485 
	C497.430573,250.990784 496.987274,250.997498 496.987274,250.997498 
	C496.824890,250.440552 496.662537,249.883606 496.713959,249.179550 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M321.535156,207.390411 
	C321.651001,206.941620 321.933624,206.666489 322.234253,206.412643 
	C322.241913,206.406143 322.446564,206.632904 322.559357,206.750854 
	C322.274353,207.022873 321.989319,207.294876 321.535156,207.390411 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M461.331055,286.014801 
	C460.999939,285.832336 461.001953,285.649597 461.018616,285.192993 
	C462.109711,284.993958 463.186157,285.068787 464.116425,285.492950 
	C463.645630,285.907654 463.321045,285.973022 462.996460,286.038391 
	C462.552368,286.030426 462.108307,286.022461 461.331055,286.014801 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M463.125977,286.295410 
	C463.321045,285.973022 463.645630,285.907654 464.455750,285.717041 
	C464.941223,285.591797 464.972351,285.615448 464.992798,285.591187 
	C464.820435,286.136902 464.627625,286.706879 464.434845,287.276855 
	C464.041748,287.035400 463.648621,286.793945 463.125977,286.295410 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M469.728943,276.005981 
	C469.911407,276.509674 469.811798,277.024323 469.531311,277.731201 
	C469.192963,277.524597 469.035492,277.125732 468.881836,276.402313 
	C469.072693,276.057495 469.259766,276.037231 469.728943,276.005981 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M855.943665,144.883423 
	C855.661560,144.669418 855.395081,144.159485 855.188599,143.347687 
	C855.485474,143.559692 855.722412,144.073608 855.943665,144.883423 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M504.978577,291.709076 
	C504.872925,291.978027 504.696960,291.987366 504.256470,291.993927 
	C503.993073,291.823639 503.994232,291.656158 503.997955,291.237061 
	C504.303101,291.140106 504.605713,291.294769 504.978577,291.709076 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M325.087097,283.212891 
	C325.014801,283.066162 325.443146,283.081451 325.651459,283.126404 
	C325.859772,283.171326 325.561401,283.505951 325.561401,283.505951 
	C325.561401,283.505951 325.159363,283.359589 325.087097,283.212891 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M496.993042,251.247833 
	C496.987274,250.997498 497.430573,250.990784 497.650360,250.983673 
	C497.924500,251.143188 497.978821,251.309814 498.010895,251.738373 
	C497.988647,252.000320 497.495117,251.999893 497.248413,251.999420 
	C497.000732,251.832001 496.999756,251.665070 496.993042,251.247833 
z"/>
<path fill="#5AB956" opacity="1.000000" stroke="none" 
	d="
M799.530884,224.868622 
	C800.749390,224.848541 801.504944,224.903427 802.633118,224.880142 
	C807.362244,224.851227 811.718628,224.900467 816.866638,224.951431 
	C818.107910,224.903259 818.557617,224.853378 819.007324,224.803497 
	C829.673157,224.923035 840.338989,225.042587 851.759705,225.404541 
	C857.347534,227.211975 862.672729,227.958298 866.913391,230.508301 
	C876.464478,236.251480 876.412842,247.715897 867.399048,254.318100 
	C865.851990,255.451248 864.408386,256.725677 862.153992,258.553497 
	C869.423706,261.539246 875.938049,264.754547 878.815247,272.069397 
	C878.851746,275.404572 878.888306,278.739746 878.713562,282.782654 
	C876.956360,286.293610 875.410400,289.096863 873.864502,291.900085 
	C873.864441,291.900085 873.894958,291.882080 873.875854,291.869019 
	C867.691895,295.678436 861.192322,298.565796 853.116699,299.063385 
	C852.081970,299.297028 851.755005,299.494781 851.428040,299.692566 
	C851.095947,299.677307 850.763794,299.662048 850.200806,299.754303 
	C819.980286,299.883148 789.990662,299.904480 759.539185,299.891663 
	C759.035522,296.536407 758.993713,293.215302 758.873047,289.443909 
	C758.794189,267.881989 758.794189,246.770386 758.794189,224.943573 
	C772.572449,224.943573 785.820190,224.943573 799.530884,224.868622 
M805.501587,268.992889 
	C800.060730,268.992889 794.619934,268.992889 788.674683,268.992889 
	C788.674683,272.446136 788.889648,275.255371 788.623962,278.018341 
	C788.232300,282.090973 789.631775,283.516144 793.890015,283.428558 
	C806.864197,283.161835 819.847229,283.349030 832.826660,283.303009 
	C835.981567,283.291809 839.167053,283.294891 842.281860,282.873444 
	C845.742004,282.405304 848.009338,280.404480 848.315308,276.649750 
	C848.621338,272.895844 846.889404,270.586884 843.323364,269.723419 
	C841.576904,269.300476 839.746094,269.043427 837.951477,269.032135 
	C827.468384,268.966431 816.984680,268.995697 805.501587,268.992889 
M817.500000,240.867447 
	C808.077026,240.867447 798.654053,240.867447 789.061401,240.867447 
	C789.061401,245.199966 789.061401,248.879211 789.061401,252.910324 
	C805.229248,252.910324 820.981018,252.916168 836.732666,252.889328 
	C837.374695,252.888245 838.007812,252.528595 838.659241,252.422470 
	C842.163086,251.851501 844.876587,250.112549 844.579590,246.322922 
	C844.263245,242.287460 841.147644,241.026917 837.438477,241.006577 
	C831.125610,240.971954 824.812805,240.914948 817.500000,240.867447 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M937.902710,18.508135 
	C940.039856,18.114923 942.167969,18.069252 944.700073,18.046600 
	C943.350830,20.889217 941.896973,23.958763 939.789429,26.482357 
	C933.006592,34.604206 930.782959,44.288563 928.992554,54.381741 
	C923.581665,84.883766 917.763062,115.313240 912.174866,145.784103 
	C907.752075,169.900421 903.434753,194.036072 899.046265,218.569244 
	C895.734131,219.661087 892.451599,220.908890 889.157532,220.939865 
	C870.142395,221.118698 851.124573,221.029236 832.107971,220.942978 
	C831.139221,220.938599 830.174072,220.123764 829.483887,219.333191 
	C830.175720,218.983475 830.591003,218.986816 831.006287,218.990158 
	C831.446655,219.059845 831.887024,219.129517 833.115540,219.230896 
	C853.481262,219.262604 873.058838,219.262604 892.673401,219.262604 
	C895.509033,204.123642 898.326965,189.506683 900.963074,174.857025 
	C902.749023,164.931747 904.279358,154.960495 905.924072,145.009796 
	C907.938110,134.633194 910.043030,124.273216 911.947754,113.876595 
	C916.386230,89.650681 920.356873,65.329628 925.383423,41.227840 
	C926.569946,35.538582 931.178589,30.562658 934.215027,25.259769 
	C935.438293,23.123388 936.667297,20.990269 937.902710,18.508135 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M879.142700,271.916443 
	C875.938049,264.754547 869.423706,261.539246 862.153992,258.553497 
	C864.408386,256.725677 865.851990,255.451248 867.399048,254.318100 
	C876.412842,247.715897 876.464478,236.251480 866.913391,230.508301 
	C862.672729,227.958298 857.347534,227.211975 852.224792,225.399002 
	C864.101807,218.688644 875.974670,231.850677 876.087280,240.629105 
	C876.162659,246.500275 875.291016,251.588547 870.183838,255.307571 
	C869.582153,255.745728 869.539307,258.203827 870.151306,258.824524 
	C872.095154,260.796143 874.541565,262.259399 876.661743,264.073425 
	C878.995056,266.069824 882.846375,267.635193 879.142700,271.916443 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M899.013855,219.398865 
	C913.699097,219.021362 928.373169,219.063339 943.505737,219.009644 
	C943.481995,220.789291 942.999756,222.664581 942.280518,225.461609 
	C938.944641,219.703186 933.962524,221.058365 929.377502,221.012177 
	C924.912354,220.967194 920.443359,220.897308 915.981567,221.028519 
	C910.484436,221.190186 904.428772,219.171036 900.395813,225.599411 
	C900.081116,225.342850 899.766357,225.086273 899.451660,224.829712 
	C899.302002,223.159241 899.152344,221.488785 899.013855,219.398865 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M823.354614,175.155640 
	C823.354614,175.155640 823.316406,175.580322 823.483765,176.207214 
	C824.052551,180.891144 824.453918,184.948196 824.855347,189.005249 
	C823.911499,187.075409 822.462585,185.232925 822.111206,183.200684 
	C820.570312,174.288025 819.250732,165.333054 818.076843,156.363846 
	C817.784485,154.130630 818.334778,151.787140 819.009521,149.241974 
	C819.521240,148.989609 819.917114,149.039307 819.917114,149.039307 
	C819.924072,149.479614 819.931030,149.919922 819.968811,151.098053 
	C821.118042,159.609146 822.236328,167.382385 823.354614,175.155640 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M942.346802,208.735519 
	C938.863770,202.537201 939.723267,195.956787 941.347046,189.339294 
	C942.667114,183.959549 943.951233,178.570999 945.627380,173.118423 
	C944.925903,179.200867 943.584961,185.320831 942.869812,191.513046 
	C942.442139,195.216415 943.065613,199.032745 943.076111,202.798676 
	C943.081421,204.699478 942.774109,206.601166 942.346802,208.735519 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M824.975586,189.307755 
	C824.453918,184.948196 824.052551,180.891144 823.612183,176.377411 
	C824.328735,176.948853 825.578552,177.897018 825.759888,179.017990 
	C826.966858,186.481125 827.985718,193.975830 828.984741,201.471069 
	C829.722412,207.005508 830.337463,212.556290 831.006714,218.544983 
	C830.591003,218.986816 830.175720,218.983475 829.447266,218.924744 
	C829.134033,218.869354 829.080627,218.910034 829.128113,218.506607 
	C828.846191,216.042282 828.516785,213.981400 828.187378,211.920517 
	C828.148376,211.485107 828.109375,211.049698 828.006348,209.931488 
	C827.667786,207.815216 827.393250,206.381760 827.118713,204.948303 
	C827.100525,204.507904 827.082336,204.067490 827.010376,202.893799 
	C826.381409,198.406326 825.806274,194.652145 825.231201,190.897964 
	C825.186096,190.468719 825.140991,190.039490 824.975586,189.307755 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M810.721191,162.596725 
	C809.674072,169.432190 808.626648,175.930862 807.323059,182.727509 
	C806.419739,182.147095 805.079956,181.118912 805.232483,180.413437 
	C806.753601,173.378784 808.520874,166.397354 810.606995,159.524780 
	C811.056091,160.099976 811.116821,160.551254 811.177551,161.002548 
	C811.025391,161.421677 810.873230,161.840805 810.721191,162.596725 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M816.920776,132.042831 
	C817.007812,132.479172 817.094788,132.915497 817.158691,134.077744 
	C817.681335,138.204803 818.227051,141.605972 818.772827,145.007111 
	C817.132935,145.307663 815.492981,145.608215 813.432739,145.545609 
	C813.263794,142.450104 813.515259,139.717743 813.766724,136.985382 
	C814.163513,135.576782 814.560364,134.168167 815.311340,132.383911 
	C816.083923,132.019806 816.502380,132.031326 816.920776,132.042831 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M904.083984,283.242432 
	C907.006409,284.911865 909.552429,285.053711 913.261475,283.762787 
	C917.433167,282.310791 922.412109,283.177917 927.495300,283.121826 
	C925.391846,283.472992 922.838318,283.840851 920.272522,283.976929 
	C917.399109,284.129364 914.768616,283.648529 912.663086,286.933136 
	C910.769592,289.886963 906.204834,287.950439 904.083984,283.242432 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M990.853333,193.061249 
	C994.178162,198.269150 991.624817,203.445618 990.430176,208.907303 
	C989.578430,204.134995 991.013184,198.721451 986.938416,194.172745 
	C988.232117,193.817368 989.390686,193.499146 990.853333,193.061249 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M813.413208,137.079071 
	C813.515259,139.717743 813.263794,142.450104 813.075989,145.593201 
	C813.100342,146.174484 813.061035,146.345047 812.952393,147.147339 
	C812.281128,150.525955 811.679260,153.272842 811.077393,156.019745 
	C810.958618,149.655411 809.730957,143.174469 813.413208,137.079071 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M988.439453,179.233246 
	C989.441040,182.435211 990.169495,185.826523 990.838928,189.613678 
	C986.700256,190.223450 984.386963,189.496933 987.334229,184.803635 
	C988.220459,183.392303 987.921997,181.237045 988.439453,179.233246 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M818.848267,145.396729 
	C818.227051,141.605972 817.681335,138.204803 817.269043,134.392639 
	C818.214844,134.677750 819.651428,135.306503 819.735046,136.081284 
	C820.181152,140.214020 820.288879,144.383270 820.215332,148.789597 
	C819.917114,149.039307 819.521240,148.989609 819.322876,148.968872 
	C819.057678,147.894196 818.990723,146.840256 818.848267,145.396729 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M905.834839,273.080566 
	C904.711731,275.931427 902.002136,278.474030 904.128174,282.532288 
	C898.988098,282.173523 901.373901,278.113281 901.253967,275.471863 
	C901.220215,274.728088 903.933899,273.859558 905.834839,273.080566 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M811.100342,156.393250 
	C811.679260,153.272842 812.281128,150.525955 813.005310,147.404480 
	C815.115967,151.854645 814.768616,156.465775 811.520874,160.922775 
	C811.116821,160.551254 811.056091,160.099976 810.908203,159.317993 
	C810.921814,158.247116 811.022522,157.506927 811.100342,156.393250 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M874.266724,291.933838 
	C875.410400,289.096863 876.956360,286.293610 878.732544,283.238525 
	C879.156555,285.425598 879.350342,287.864532 879.588806,290.864990 
	C878.001709,291.220673 876.335266,291.594147 874.266724,291.933838 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M824.905273,191.150589 
	C825.806274,194.652145 826.381409,198.406326 826.895142,202.581543 
	C826.082275,199.136139 825.330872,195.269669 824.905273,191.150589 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M897.939941,285.933014 
	C897.490295,284.674805 897.322266,283.644165 897.154236,282.613495 
	C897.483093,282.437836 897.811951,282.262177 898.140808,282.086517 
	C899.811401,283.963867 901.481995,285.841217 903.152588,287.718597 
	C902.830505,288.110352 902.508484,288.502136 902.186462,288.893890 
	C900.864746,287.982788 899.543091,287.071655 897.939941,285.933014 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M903.816284,258.153015 
	C904.067261,260.126190 904.011536,262.194763 904.006958,264.612732 
	C903.174255,264.222504 901.475769,263.361298 901.564514,262.766937 
	C901.798706,261.198944 902.808472,259.746735 903.816284,258.153015 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M818.495911,171.710464 
	C818.635132,173.282883 818.524658,174.564835 818.414185,175.846786 
	C816.014771,174.041946 814.119507,172.274872 818.495911,171.710464 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M827.874023,212.167725 
	C828.516785,213.981400 828.846191,216.042282 829.159363,218.511734 
	C825.359680,217.543854 827.240173,214.789642 827.874023,212.167725 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M903.059814,244.977936 
	C904.138550,246.549118 905.217285,248.120300 906.387817,249.974823 
	C903.210999,251.359360 902.506165,249.187469 902.118225,246.152161 
	C902.506836,245.359360 902.783325,245.168640 903.059814,244.977936 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M817.612183,212.522629 
	C815.099548,212.961090 815.099548,212.961090 815.772888,208.040817 
	C816.816162,210.206146 817.298035,211.206238 817.612183,212.522629 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M984.997559,173.426178 
	C987.842163,172.337097 988.099060,174.111923 988.008179,176.602936 
	C986.992798,175.939636 985.995178,174.876114 984.997559,173.426178 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M909.948425,255.412323 
	C908.978638,255.954849 907.944336,256.102386 906.527344,256.329041 
	C906.111450,256.174133 906.078247,255.940079 906.022583,255.353012 
	C906.000000,255.000000 905.997742,255.002441 905.997742,255.002441 
	C906.333008,254.228256 906.668213,253.454086 907.283936,252.146851 
	C907.763184,251.204636 907.962097,250.795456 908.161011,250.386292 
	C908.161011,250.386292 908.377869,250.248871 908.505066,250.248047 
	C909.049438,251.837265 909.466675,253.427307 909.948425,255.412323 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M905.206970,239.183228 
	C904.941650,240.368317 904.315918,241.499527 903.150391,242.830414 
	C902.408447,243.019684 902.206177,243.009277 902.003967,242.998871 
	C902.951477,241.709030 903.898987,240.419205 905.206970,239.183228 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M906.000549,267.288879 
	C908.069458,266.932312 910.132568,266.917603 912.555115,266.867279 
	C912.685730,267.372986 912.457031,267.914307 912.114136,268.727814 
	C912.000000,269.000000 912.000000,268.998779 912.000000,268.998779 
	C910.611755,268.998901 909.223450,268.999023 907.418823,268.997955 
	C906.835754,268.996216 906.669128,268.995605 906.251221,268.997498 
	C906.000000,269.000000 906.002441,268.997559 906.002441,268.997559 
	C905.999878,268.541931 905.997375,268.086304 906.000549,267.288879 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M826.830322,205.170227 
	C827.393250,206.381760 827.667786,207.815216 827.882568,209.625778 
	C827.395813,208.465958 826.968872,206.929047 826.830322,205.170227 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M986.533691,201.255005 
	C985.844360,200.665482 985.375671,199.841248 984.906921,199.017014 
	C985.369385,198.842300 985.831848,198.667572 986.294312,198.492859 
	C986.447632,199.335342 986.600952,200.177826 986.533691,201.255005 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M819.004883,224.451202 
	C818.557617,224.853378 818.107910,224.903259 817.325378,224.876648 
	C817.118164,223.712479 817.243774,222.624802 817.369385,221.537140 
	C817.649597,221.490082 817.929749,221.443039 818.209961,221.395996 
	C818.474060,222.296967 818.738220,223.197937 819.004883,224.451202 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M820.533813,206.255005 
	C819.831970,205.643509 819.350769,204.797348 818.869568,203.951172 
	C819.344421,203.798447 819.819336,203.645721 820.294189,203.492996 
	C820.447571,204.335449 820.600952,205.177887 820.533813,206.255005 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M901.721436,243.136963 
	C902.206177,243.009277 902.408447,243.019684 902.913940,243.050537 
	C903.199219,243.496323 903.181213,243.921677 903.111511,244.662476 
	C902.783325,245.168640 902.506836,245.359360 902.111572,245.776413 
	C901.808105,245.093506 901.623535,244.184265 901.721436,243.136963 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M814.506104,190.332550 
	C813.963318,189.988403 813.637878,189.429596 813.312439,188.870819 
	C813.590820,188.743637 813.869202,188.616455 814.147583,188.489288 
	C814.339478,189.032150 814.531433,189.575012 814.506104,190.332550 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M944.020630,216.936615 
	C943.487488,216.295410 943.244690,215.479141 943.054321,214.330475 
	C943.508179,214.919250 943.909607,215.840393 944.020630,216.936615 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M897.206665,260.797119 
	C897.570435,260.195404 897.996948,259.892700 898.423401,259.589996 
	C898.537964,260.036865 898.652527,260.483704 898.767151,260.930542 
	C898.267883,260.985748 897.768555,261.040924 897.206665,260.797119 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M982.045471,208.395279 
	C982.286865,208.790970 982.285095,209.184570 982.254822,209.575974 
	C982.253967,209.586975 981.948853,209.574463 981.785645,209.572937 
	C981.789856,209.179581 981.794128,208.786240 982.045471,208.395279 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M983.395386,178.954529 
	C983.791016,178.713120 984.184631,178.714828 984.575989,178.745026 
	C984.586975,178.745865 984.574463,179.050949 984.572876,179.214172 
	C984.179626,179.209976 983.786316,179.205795 983.395386,178.954529 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M906.534668,234.391205 
	C906.650635,233.942245 906.933533,233.667007 907.234314,233.412994 
	C907.242004,233.406540 907.446716,233.633347 907.559509,233.751343 
	C907.274353,234.023468 906.989136,234.295578 906.534668,234.391205 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M907.887817,250.296814 
	C907.962097,250.795456 907.763184,251.204636 907.284607,251.808136 
	C906.770996,251.664749 906.537109,251.327042 906.411499,250.671875 
	C906.884766,250.305405 907.249695,250.256393 907.887817,250.296814 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M942.848328,212.255142 
	C942.637695,212.228928 942.426147,211.923615 942.250488,211.333374 
	C942.473389,211.357635 942.660400,211.666840 942.848328,212.255142 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M904.854797,257.094818 
	C904.927429,257.301880 904.749939,257.615082 904.315552,258.018677 
	C904.240723,257.806396 904.422729,257.503662 904.854797,257.094818 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M906.001221,254.999054 
	C905.881836,254.858917 905.761169,254.719757 905.640503,254.580597 
	C905.723755,254.630173 905.807129,254.679733 905.944092,254.865875 
	C905.997742,255.002441 906.000000,255.000000 906.001221,254.999054 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M907.805847,238.418945 
	C907.927246,238.512268 907.508789,238.784271 907.259277,238.781067 
	C907.118286,238.431046 907.343201,238.280304 907.805847,238.418945 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M899.550415,273.467346 
	C899.583618,273.523041 899.517151,273.411682 899.550415,273.467346 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M911.933167,238.749390 
	C911.826599,238.912201 911.653625,238.918961 911.258667,238.791107 
	C911.313354,238.635452 911.590088,238.614395 911.933167,238.749390 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M906.001221,268.998291 
	C905.909668,269.033020 905.816956,269.069519 905.863281,269.051758 
	C906.002441,268.997559 906.000000,269.000000 906.001221,268.998291 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M904.111450,265.171631 
	C904.316528,265.119232 904.603027,265.298492 904.921326,265.737152 
	C904.626221,265.893005 904.372803,265.695251 904.111450,265.171631 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M519.998901,224.996597 
	C518.805542,224.437515 517.651611,223.759995 516.409790,223.346420 
	C514.411194,222.680786 512.354553,222.189362 510.217712,220.971680 
	C510.863220,219.115524 512.481506,217.671616 512.225403,216.746826 
	C510.291473,209.763901 507.340485,203.238754 501.993561,197.540833 
	C501.937561,179.389175 501.991425,161.695114 502.374634,143.950378 
	C502.483826,142.932785 502.263702,141.965897 501.948792,140.670288 
	C501.918915,139.894669 501.983826,139.447784 502.399658,138.937561 
	C504.260834,135.214142 507.232269,133.669586 511.335815,133.131927 
	C512.495544,133.107086 513.271973,133.159515 514.369751,133.463074 
	C518.125244,133.810898 521.592957,133.649750 524.982605,134.089737 
	C527.479370,134.413818 529.874268,135.523453 532.873291,136.456955 
	C532.970337,136.178284 533.488159,134.691742 534.375916,133.124802 
	C535.493652,133.112778 536.241394,133.181168 536.992188,133.622864 
	C538.005981,134.994202 539.016785,135.992264 540.214478,137.245697 
	C541.214539,137.679657 542.027649,137.858231 542.955750,138.271973 
	C543.146851,138.845673 543.223022,139.184204 543.187256,139.888428 
	C543.027405,140.503372 542.979431,140.752609 542.554321,141.082855 
	C542.586853,142.256287 542.996521,143.348694 543.464233,144.870850 
	C543.465515,147.125992 543.344604,148.952026 543.362000,150.776749 
	C543.505432,165.840729 543.674500,180.904449 543.618774,195.970001 
	C543.401794,195.971741 542.968201,195.990723 542.646118,196.040161 
	C542.225464,197.062805 542.126831,198.035995 542.037598,199.440399 
	C542.146851,200.438248 542.246826,201.004898 542.357666,201.825638 
	C542.219177,202.379379 542.069824,202.679016 541.689819,202.967712 
	C541.459167,202.956772 540.996948,202.943253 540.782959,202.705963 
	C539.765503,203.016312 538.961975,203.563980 538.158508,204.111649 
	C538.868408,204.843460 539.578369,205.575287 540.285034,206.602478 
	C540.140503,207.263824 539.999268,207.629807 539.641174,208.148590 
	C539.332520,208.633545 539.240601,208.965714 539.135315,209.483734 
	C539.121948,209.669586 538.917542,209.981155 538.596924,210.029190 
	C537.523682,211.054184 536.771057,212.031143 535.653503,213.045410 
	C533.305237,214.016495 531.321777,214.950287 528.783081,216.145508 
	C530.790588,217.631332 532.420288,218.837509 534.046021,220.437714 
	C534.027466,221.882263 534.012939,222.932785 533.998352,223.983322 
	C533.998352,223.983322 533.989441,224.006653 533.852539,223.977112 
	C533.540039,223.954453 533.378052,224.005020 533.229736,224.099289 
	C532.744507,224.280807 532.259216,224.462341 531.275513,224.529999 
	C530.514832,224.544571 530.252502,224.673004 529.990234,224.801453 
	C528.381714,224.928238 526.773254,225.055038 524.424561,225.101990 
	C523.455322,224.979126 523.226318,224.936081 522.838623,224.684464 
	C522.121155,224.644333 521.562195,224.812759 520.752258,224.988434 
	C520.333801,224.995987 520.166321,224.996292 519.998901,224.996597 
M535.784424,199.445984 
	C535.258118,200.404282 534.731812,201.362579 534.205566,202.320877 
	C534.786499,202.521713 535.367432,202.722549 535.948364,202.923386 
	C536.112732,201.803345 536.277100,200.683304 535.784424,199.445984 
M536.471130,142.657333 
	C536.954468,143.302155 537.437866,143.946976 537.921204,144.591797 
	C538.215942,144.286362 538.510681,143.980942 538.805420,143.675507 
	C538.191284,143.198532 537.577087,142.721573 536.471130,142.657333 
M539.921631,194.479462 
	C539.776367,194.235809 539.631042,193.992157 539.485779,193.748489 
	C539.351135,193.999741 539.054626,194.316086 539.122864,194.485733 
	C539.207825,194.696960 539.593811,194.787079 539.921631,194.479462 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M611.503662,247.987762 
	C610.761353,248.697952 609.644653,249.918823 608.336792,250.178894 
	C604.986145,250.845169 601.550598,251.084290 598.148987,251.494232 
	C598.077454,252.288864 598.005981,253.083511 597.934448,253.878143 
	C601.604675,255.250031 605.242798,256.716949 608.953552,257.968445 
	C613.094360,259.365021 617.181580,267.699493 615.252991,271.869537 
	C610.687378,257.534241 598.282715,255.394424 586.194031,254.178406 
	C571.490845,252.699387 556.640503,252.712540 541.861328,251.936111 
	C538.758911,251.773132 535.487793,251.472305 532.675476,250.311676 
	C530.796875,249.536453 529.592529,247.127213 528.086487,245.449234 
	C529.690796,244.020294 531.195618,241.501266 532.916016,241.344208 
	C542.158630,240.500488 551.451294,240.092392 560.734741,239.864471 
	C564.178345,239.779938 567.684570,240.391479 571.085388,241.070465 
	C573.322083,241.517059 576.301147,242.021240 577.426270,243.590530 
	C581.743347,249.611664 587.799133,248.126511 593.552368,248.159378 
	C599.381958,248.192688 605.212158,248.098907 611.503662,247.987762 
M594.485718,252.274368 
	C595.186401,251.873917 595.887085,251.473465 597.162476,250.744537 
	C594.302612,250.744537 592.262390,250.744537 590.222107,250.744537 
	C590.164368,251.188889 590.106628,251.633240 590.048889,252.077591 
	C591.305603,252.241440 592.562317,252.405304 594.485718,252.274368 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M527.123535,274.355469 
	C528.816956,275.724335 530.189087,278.214020 532.229248,279.026764 
	C537.392456,281.083710 542.763306,283.042206 548.224182,283.812622 
	C553.939331,284.618927 559.871887,284.240997 565.678040,283.878632 
	C568.175415,283.722778 570.599792,282.318359 573.045349,281.447510 
	C574.086975,281.076599 575.099670,280.257233 576.122437,280.262238 
	C581.381104,280.287811 581.658691,279.965759 580.487549,275.437012 
	C577.157349,274.195770 574.476074,272.548553 571.671326,272.301086 
	C567.337891,271.918793 562.916016,272.700134 558.538696,272.617554 
	C550.608337,272.467987 542.681274,272.080688 534.759583,271.663452 
	C534.188660,271.633392 533.673035,270.553864 533.063354,269.585510 
	C548.210022,270.136230 563.438721,270.901031 578.622192,272.196991 
	C580.521545,272.359100 583.527283,275.280304 583.588928,277.009766 
	C583.656067,278.892456 581.192627,281.303833 579.260681,282.684418 
	C577.632080,283.848175 575.184875,284.315369 573.099548,284.316498 
	C561.332642,284.322937 549.542725,284.476013 537.809082,283.771698 
	C532.694336,283.464722 528.419922,280.396698 527.123535,274.355469 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M541.466309,223.324081 
	C540.763306,223.515350 540.060364,223.706619 539.098450,223.420303 
	C538.898865,221.801880 538.958191,220.661026 539.479675,219.449280 
	C549.830811,219.298233 559.719727,219.218079 569.722534,219.108612 
	C569.836426,219.079285 570.060364,219.000732 570.295288,219.001205 
	C570.530151,219.001678 571.000000,218.999664 571.000000,218.999664 
	C571.000000,218.999664 571.004089,219.004181 571.003662,219.402649 
	C571.005432,220.854706 571.007568,221.908264 570.540161,222.973892 
	C560.535828,223.098663 551.001038,223.211380 541.466309,223.324081 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M597.737061,229.212265 
	C598.006775,228.804901 598.223450,228.320618 598.583374,227.994354 
	C600.377563,226.367706 603.206238,223.088852 603.857544,223.472229 
	C606.886536,225.255112 609.707153,227.872910 608.115784,232.238647 
	C608.023376,232.492249 608.266785,232.868240 608.246399,232.793640 
	C611.186340,234.379578 613.876221,235.830627 616.341187,237.160339 
	C615.016174,240.481171 613.821411,243.475586 612.375183,246.716690 
	C611.258423,240.902435 608.080566,236.275101 603.316895,232.069672 
	C603.777893,230.235382 604.182556,228.997162 604.587158,227.758911 
	C603.846436,227.569290 602.541199,227.077866 602.455383,227.238403 
	C601.837830,228.393402 601.467529,229.680634 601.018799,230.925934 
	C600.463745,230.788116 599.908630,230.650299 599.051147,230.045502 
	C598.419128,229.461639 598.089478,229.344757 597.759827,229.227890 
	C597.759827,229.227890 597.749939,229.208160 597.737061,229.212265 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M615.093140,274.414429 
	C616.408508,277.836456 618.577515,281.716888 618.412476,285.495422 
	C618.342102,287.106354 613.395325,288.504303 610.257812,289.995544 
	C611.568054,284.938568 613.285034,279.885101 615.093140,274.414429 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M571.009705,222.961838 
	C571.007568,221.908264 571.005432,220.854706 571.007751,219.406494 
	C574.820740,218.698898 578.345154,218.977341 579.960693,223.867859 
	C579.992554,224.440216 579.978333,224.864655 579.978333,224.864655 
	C577.896729,224.679947 575.815186,224.495239 573.208008,223.921875 
	C572.124878,223.342758 571.567322,223.152298 571.009705,222.961838 
M576.411865,221.483353 
	C576.411865,221.483353 576.523193,221.416763 576.411865,221.483353 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M570.540161,222.973892 
	C571.567322,223.152298 572.124878,223.342758 572.838623,223.790649 
	C562.724243,223.993317 552.453735,223.938568 541.824829,223.603943 
	C551.001038,223.211380 560.535828,223.098663 570.540161,222.973892 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M518.312012,298.041901 
	C513.299744,298.048798 508.400665,297.180542 505.035736,292.310364 
	C509.423676,293.863800 513.804626,295.799164 518.312012,298.041901 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M539.017456,219.520187 
	C538.958191,220.661026 538.898865,221.801880 538.726379,223.367676 
	C537.300171,223.898453 535.987183,224.004257 534.336243,224.046692 
	C534.012939,222.932785 534.027466,221.882263 534.277405,220.326813 
	C534.954712,219.564621 535.396606,219.307343 536.239929,219.092682 
	C537.433350,219.263596 538.225403,219.391891 539.017456,219.520187 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M496.839020,278.608215 
	C494.881866,277.852478 492.985718,276.738068 491.089569,275.623688 
	C491.169159,275.229095 491.248718,274.834473 491.328278,274.439850 
	C492.657745,274.293793 493.987213,274.147705 495.715363,274.002869 
	C496.376068,275.419281 496.638062,276.834412 496.839020,278.608215 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M587.741516,226.253540 
	C589.856079,223.657120 593.265259,224.159225 596.005859,227.787750 
	C595.267517,228.064941 595.062927,228.106781 594.858337,228.148605 
	C594.858337,228.148605 594.944153,228.081573 594.744019,227.812561 
	C593.574890,227.423065 592.605957,227.302567 591.637024,227.182068 
	C590.971313,227.121033 590.305542,227.059982 589.167969,226.714905 
	C588.414429,226.198822 588.101868,226.144836 587.758484,226.268967 
	C587.758484,226.268967 587.746094,226.251160 587.741516,226.253540 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M601.172974,231.180420 
	C601.467529,229.680634 601.837830,228.393402 602.455383,227.238403 
	C602.541199,227.077866 603.846436,227.569290 604.587158,227.758911 
	C604.182556,228.997162 603.777893,230.235382 603.166260,231.774200 
	C602.415222,231.861496 601.871155,231.648209 601.172974,231.180420 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M507.856201,230.330048 
	C507.509064,229.368378 507.420013,228.390015 507.330933,227.411682 
	C508.574707,227.563156 509.818451,227.714630 511.124084,228.241455 
	C511.073425,228.870407 510.881378,229.025742 510.166290,229.108612 
	C509.484314,229.356262 509.245850,229.578125 509.007385,229.799957 
	C508.709656,229.971085 508.411987,230.142227 507.856201,230.330048 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M580.302490,225.007965 
	C579.978333,224.864655 579.992554,224.440216 580.001160,224.228241 
	C581.127380,224.248993 582.244995,224.481720 583.686890,224.840714 
	C584.011108,224.966980 584.033203,225.392975 584.035400,225.606201 
	C582.900696,225.596710 581.763672,225.373993 580.302490,225.007965 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M513.368652,272.989502 
	C513.962769,272.681396 514.927124,272.463898 515.946350,272.568420 
	C515.247131,272.953674 514.492981,273.016907 513.368652,272.989502 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M521.003296,224.981201 
	C521.562195,224.812759 522.121155,224.644333 522.817871,224.895782 
	C522.955688,225.315643 522.946594,225.737518 522.946594,225.737518 
	C522.520569,225.809555 522.094604,225.881577 521.340088,225.696381 
	C521.011536,225.439148 521.003296,224.981186 521.003296,224.981201 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M499.470520,238.224182 
	C499.552429,237.615402 499.906525,236.853363 500.607513,236.040802 
	C500.550537,236.683823 500.146637,237.377365 499.470520,238.224182 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M591.897766,227.426086 
	C592.605957,227.302567 593.574890,227.423065 594.735229,227.866302 
	C594.003906,228.016068 593.081177,227.843079 591.897766,227.426086 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M520.248962,272.978088 
	C520.164062,272.496063 520.330139,272.082916 520.496277,271.669769 
	C520.653259,271.922180 520.810242,272.174622 520.984619,272.668457 
	C520.834656,272.955536 520.667297,273.001251 520.248962,272.978088 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M517.330933,226.936096 
	C517.587219,226.615128 518.169128,226.354889 519.085327,226.164948 
	C518.831970,226.489105 518.244263,226.742981 517.330933,226.936096 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M530.250977,224.845535 
	C530.252502,224.673004 530.514832,224.544571 530.885986,224.545349 
	C530.833801,224.746231 530.672791,224.817917 530.250977,224.845535 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M533.343262,224.217926 
	C533.378052,224.005020 533.540039,223.954453 533.843140,223.968475 
	C533.884399,224.231430 533.713074,224.347168 533.343262,224.217926 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M520.752258,224.988434 
	C521.003296,224.981186 521.011536,225.439148 521.015503,225.667175 
	C520.858276,225.959473 520.697144,226.023712 520.276367,226.073959 
	C520.010925,225.882797 520.005066,225.705643 519.999023,225.262543 
	C520.166321,224.996292 520.333801,224.995987 520.752258,224.988434 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M523.217102,225.784729 
	C522.946594,225.737518 522.955688,225.315643 522.976440,225.104340 
	C523.226318,224.936081 523.455322,224.979126 524.021851,225.119202 
	C524.068787,225.421463 523.778198,225.626709 523.217102,225.784729 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M587.888733,226.487961 
	C588.101868,226.144836 588.414429,226.198822 588.844971,226.627136 
	C588.668823,226.784561 588.343933,226.745758 587.888733,226.487961 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M514.266052,227.768829 
	C514.282654,227.535980 514.565918,227.339935 515.116333,227.168549 
	C515.099915,227.397324 514.816345,227.601471 514.266052,227.768829 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M594.980164,228.368408 
	C595.062927,228.106781 595.267517,228.064941 595.786743,228.014343 
	C596.101257,228.005569 596.070251,228.392380 596.060791,228.585876 
	C595.734863,228.715652 595.418396,228.651932 594.980164,228.368408 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M509.260437,229.791656 
	C509.245850,229.578125 509.484314,229.356262 509.974426,229.115921 
	C509.988495,229.326065 509.751007,229.554703 509.260437,229.791656 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M597.877808,229.468079 
	C598.089478,229.344757 598.419128,229.461639 598.860779,229.827942 
	C598.647095,229.954346 598.321411,229.831314 597.877808,229.468079 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M501.495422,235.356552 
	C501.418915,235.110321 501.617859,234.714325 502.164520,234.264130 
	C502.265137,234.542236 502.018036,234.874527 501.495422,235.356552 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M634.120239,283.567017 
	C634.144470,283.234161 634.082092,282.918457 633.956055,282.318268 
	C633.979187,282.016663 634.083252,281.891388 634.417114,281.961731 
	C636.168762,282.695190 637.586609,283.358307 639.112915,284.235748 
	C639.419922,284.716705 639.677856,284.899658 639.995422,285.384155 
	C639.975037,286.539734 639.954285,287.310120 639.625854,287.996948 
	C638.262207,287.214264 637.206360,286.515198 636.150452,285.816101 
	C636.150452,285.816101 636.077820,285.896545 636.049194,285.631897 
	C635.708984,285.188202 635.397400,285.009186 635.085754,284.830170 
	C635.085754,284.830170 635.057739,284.935913 635.036987,284.640808 
	C634.717590,284.086151 634.418945,283.826599 634.120239,283.567017 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M621.432129,253.443497 
	C620.238037,253.100830 619.292603,252.512512 618.347229,251.924210 
	C618.877625,251.398895 619.408081,250.873581 619.938477,250.348267 
	C620.519226,251.298141 621.100037,252.248001 621.432129,253.443497 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M636.107422,286.200623 
	C637.206360,286.515198 638.262207,287.214264 639.641968,287.976562 
	C639.965759,288.039795 639.917114,288.038940 639.992188,288.293091 
	C640.382446,288.680664 640.697754,288.814117 641.074341,289.158203 
	C641.135742,289.368805 641.195862,289.803375 641.195862,289.803375 
	C641.195862,289.803375 641.061401,289.939545 640.994141,290.008911 
	C639.306030,288.913910 637.685181,287.749512 636.107422,286.200623 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M615.475281,259.305969 
	C615.469543,258.551483 615.674561,258.016052 615.879639,257.480652 
	C616.153809,257.615387 616.427917,257.750122 616.702087,257.884888 
	C616.363403,258.431610 616.024780,258.978363 615.475281,259.305969 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M633.924255,282.076782 
	C633.605408,281.937500 633.341431,281.738129 633.049194,281.259399 
	C633.020813,280.980072 633.075806,280.927277 633.280762,281.046082 
	C633.737793,281.363586 633.936951,281.605743 634.083252,281.891388 
	C634.083252,281.891388 633.979187,282.016663 633.924255,282.076782 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M632.316406,280.969574 
	C632.098938,280.961090 632.082153,280.480652 632.336853,280.357361 
	C632.815552,280.421417 632.976990,280.652496 633.075806,280.927277 
	C633.075806,280.927277 633.020813,280.980072 632.993164,281.006714 
	C632.965515,281.033386 632.533813,280.978058 632.316406,280.969574 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M635.171448,285.093719 
	C635.397400,285.009186 635.708984,285.188202 636.103394,285.633484 
	C635.876465,285.718903 635.566833,285.538086 635.171448,285.093719 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M634.175049,283.872833 
	C634.418945,283.826599 634.717590,284.086151 635.091553,284.636627 
	C634.854492,284.677917 634.542175,284.428284 634.175049,283.872833 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M916.738342,258.571533 
	C916.079956,258.738861 915.188477,258.676056 914.108765,258.325439 
	C914.782166,258.138885 915.643677,258.240112 916.738342,258.571533 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M920.821899,258.596924 
	C920.540649,258.837952 920.104919,258.888916 919.345703,258.868530 
	C919.570679,258.667084 920.119080,258.536987 920.821899,258.596924 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M912.874023,256.736023 
	C912.685791,256.828278 912.361816,256.691528 911.913818,256.315521 
	C912.105957,256.219818 912.422180,256.363403 912.874023,256.736023 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M912.075928,249.904251 
	C911.925537,249.585159 912.138489,249.346100 912.733398,249.236816 
	C912.835449,249.539886 912.610107,249.745789 912.075928,249.904251 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M730.057251,294.652527 
	C723.961548,296.339935 717.923157,297.679901 711.479980,299.047791 
	C711.075317,299.075775 710.982788,298.974365 711.084106,298.584961 
	C716.603882,295.851166 722.022400,293.506775 727.699158,291.171661 
	C728.330139,290.862244 728.702820,290.543549 729.348633,290.147644 
	C729.832886,289.775848 730.044128,289.481262 730.194885,289.188782 
	C730.134460,289.190918 730.200134,289.292358 730.464966,289.259338 
	C730.940430,288.963379 731.151062,288.700439 731.414429,288.435272 
	C731.467102,288.433044 731.571960,288.422119 731.869568,288.318298 
	C733.816895,286.479034 735.466614,284.743561 737.197510,282.943970 
	C737.278625,282.879822 737.269836,282.673157 737.600830,282.534363 
	C740.174683,278.842407 742.417603,275.289246 744.660461,271.736084 
	C744.660461,271.736053 744.860474,271.897644 744.959473,271.981079 
	C744.388428,275.400299 743.718384,278.736115 743.004150,282.291992 
	C743.727844,282.291992 745.043701,282.291992 746.283081,282.291992 
	C746.526428,282.762238 746.874573,283.194366 746.789917,283.310089 
	C746.189880,284.130737 744.972229,285.602936 744.915894,285.561371 
	C738.364502,280.723663 737.224060,288.766571 733.489807,290.454987 
	C732.073975,291.095154 731.223022,292.984894 730.057251,294.652527 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M749.206726,276.797485 
	C749.569702,276.196716 749.995422,275.894653 750.421143,275.592590 
	C750.535828,276.038910 750.650574,276.485229 750.765259,276.931580 
	C750.266663,276.986420 749.768066,277.041290 749.206726,276.797485 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M918.660278,275.529907 
	C917.398621,275.729919 915.843384,275.690399 913.997681,275.382874 
	C915.260376,275.173340 916.813599,275.231873 918.660278,275.529907 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M912.000000,269.000488 
	C912.120911,269.141785 912.241821,269.282593 912.362793,269.423401 
	C912.277832,269.373810 912.192871,269.324219 912.053955,269.136719 
	C912.000000,268.998779 912.000000,269.000000 912.000000,269.000488 
z"/>
<path fill="#58B953" opacity="1.000000" stroke="none" 
	d="
M729.075500,290.224884 
	C728.702820,290.543549 728.330139,290.862244 727.290771,291.121216 
	C726.079407,291.041382 725.534668,291.021301 724.953125,290.704590 
	C724.512268,290.091431 724.108154,289.774933 723.704041,289.458435 
	C723.475098,289.972717 723.246094,290.486969 722.871765,291.289612 
	C721.815918,292.056244 720.905457,292.534454 719.630005,293.009216 
	C718.179138,293.337585 717.093201,293.669403 715.754089,294.005981 
	C715.331970,294.013977 715.163147,294.017212 714.871094,293.755737 
	C714.270325,293.260956 713.792786,293.030914 713.315247,292.800903 
	C713.210205,293.533936 713.105103,294.266968 712.737183,295.283691 
	C695.727722,298.897766 678.948425,299.373810 662.108643,296.244843 
	C661.471252,296.126373 660.692200,296.770782 659.979187,297.059692 
	C659.529541,297.042480 659.079956,297.025238 658.122803,296.705566 
	C657.076782,296.268921 656.538269,296.134705 655.999756,296.000519 
	C655.999756,296.000519 656.000000,296.000000 655.868896,295.716675 
	C654.824341,294.622009 653.910950,293.810638 652.955688,292.681274 
	C652.279419,291.923187 651.645142,291.483063 650.673706,291.033081 
	C649.887024,291.015839 649.437378,291.008514 648.957642,290.709839 
	C648.286743,290.283569 647.645935,290.148621 646.698547,289.879028 
	C645.249512,289.856140 644.107056,289.967957 642.964600,290.079742 
	C642.572449,290.015900 642.180237,289.952087 641.491943,289.845825 
	C641.195862,289.803375 641.135742,289.368805 640.952576,288.941742 
	C640.546204,288.247101 640.262085,288.088531 639.917114,288.038940 
	C639.917114,288.038940 639.965759,288.039795 639.949707,288.060150 
	C639.954285,287.310120 639.975037,286.539734 639.889893,285.167114 
	C639.587097,284.293549 639.327148,284.112427 639.004395,284.021423 
	C637.586609,283.358307 636.168762,282.695190 634.417114,281.961731 
	C633.936951,281.605743 633.737793,281.363586 633.280762,281.046082 
	C632.976990,280.652496 632.815552,280.421417 632.407227,280.127502 
	C632.222900,280.020966 631.952026,279.652191 631.964050,278.983948 
	C631.647461,276.121399 633.320740,272.699463 628.772949,273.092529 
	C628.513916,272.590424 628.254822,272.088348 628.337158,271.118011 
	C628.820801,269.178619 629.167419,267.690979 629.050842,266.240662 
	C628.921021,264.627563 628.064880,263.051178 628.050232,261.454010 
	C627.954590,250.982300 628.078247,240.508331 627.951965,230.037262 
	C627.916321,227.078445 628.791138,225.849945 631.913147,225.948029 
	C637.891541,226.135849 643.885986,226.160141 649.862183,225.939362 
	C653.238220,225.814606 654.103455,227.154037 654.055847,230.312881 
	C653.903198,240.450729 653.679138,250.608368 654.188660,260.722809 
	C654.345764,263.842987 656.763977,266.849213 658.146912,269.907593 
	C658.146912,269.907593 658.041077,269.972656 658.038208,270.295654 
	C658.694580,272.413391 659.353699,274.208130 660.116882,276.220581 
	C660.416992,276.709778 660.675720,276.892853 661.428833,276.989441 
	C663.015015,276.950134 664.169373,276.908844 665.323792,276.867554 
	C665.809570,276.991486 666.295349,277.115387 667.075562,277.796875 
	C669.304260,278.839478 671.238525,279.324554 673.172791,279.809601 
	C673.172791,279.809601 673.075073,279.839600 673.259644,280.150909 
	C674.643005,280.966827 675.872742,281.969635 677.035645,281.897247 
	C686.410156,281.313568 695.831299,280.950134 705.116333,279.649628 
	C710.969055,278.829895 714.555054,274.180237 714.772339,269.385071 
	C714.772339,269.385071 714.698853,269.158264 715.181763,269.068665 
	C719.396851,267.734222 718.268433,265.384308 717.022278,263.007629 
	C717.022278,262.559387 717.022278,262.111176 717.459351,261.279724 
	C718.303162,259.622009 718.975342,258.359283 719.032776,257.069214 
	C719.078735,256.037506 718.067566,254.975891 718.056091,253.921783 
	C717.967346,245.768082 718.070618,237.612213 717.971619,229.458740 
	C717.940063,226.859268 718.943542,225.926468 721.501221,225.969498 
	C727.823425,226.075867 734.151306,226.112762 740.471558,225.958191 
	C743.494568,225.884262 744.115601,227.327972 744.033691,229.930344 
	C743.908203,233.920502 744.023071,237.917694 743.998596,241.911652 
	C743.914124,255.699921 746.019287,269.859924 737.219666,282.185822 
	C737.148499,282.285522 737.249329,282.507996 737.269836,282.673157 
	C737.269836,282.673157 737.278625,282.879822 736.811951,282.944519 
	C733.272827,283.507233 730.722839,284.465912 731.571960,288.422119 
	C731.571960,288.422119 731.467102,288.433044 731.120483,288.392914 
	C730.582642,288.665985 730.391418,288.979187 730.200134,289.292358 
	C730.200134,289.292358 730.134460,289.190918 729.908691,289.239807 
	C729.480408,289.600769 729.277954,289.912811 729.075500,290.224884 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M719.994934,293.012695 
	C720.905457,292.534454 721.815918,292.056244 723.200378,291.289490 
	C724.112854,291.001038 724.551392,291.001129 724.989929,291.001221 
	C725.534668,291.021301 726.079407,291.041382 727.032471,291.111938 
	C722.022400,293.506775 716.603882,295.851166 711.038513,298.533630 
	C708.549133,298.933044 706.206726,298.994324 703.139526,299.085388 
	C702.086609,299.302185 701.758545,299.489227 701.430420,299.676270 
	C701.095947,299.651306 700.761414,299.626343 700.188782,299.698029 
	C690.236633,299.551270 680.522705,299.307831 670.388062,299.130615 
	C666.817932,298.666138 663.668518,298.135406 660.249146,297.332184 
	C660.692200,296.770782 661.471252,296.126373 662.108643,296.244843 
	C678.948425,299.373810 695.727722,298.897766 713.032898,295.263458 
	C714.059143,294.646515 714.526733,294.333496 714.994324,294.020447 
	C715.163147,294.017212 715.331970,294.013977 716.118408,294.005554 
	C717.822266,293.671173 718.908630,293.341949 719.994934,293.012695 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M643.138916,290.370667 
	C644.107056,289.967957 645.249512,289.856140 646.864990,290.120941 
	C647.887939,290.665436 648.437866,290.833313 648.987793,291.001221 
	C649.437378,291.008514 649.887024,291.015839 650.794434,291.292786 
	C651.833984,292.041351 652.415771,292.520325 652.997559,292.999268 
	C653.910950,293.810638 654.824341,294.622009 655.868774,295.716919 
	C651.770996,294.220856 647.542053,292.441223 643.138916,290.370667 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M656.160522,296.246613 
	C656.538269,296.134705 657.076782,296.268921 657.795044,296.621826 
	C657.423584,296.724579 656.872498,296.608643 656.160522,296.246613 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M851.722168,299.739502 
	C851.755005,299.494781 852.081970,299.297028 852.701355,299.141418 
	C852.667908,299.384521 852.342102,299.585510 851.722168,299.739502 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M701.721436,299.727875 
	C701.758545,299.489227 702.086609,299.302185 702.703979,299.156738 
	C702.666321,299.392059 702.339355,299.585785 701.721436,299.727875 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M180.892715,129.579254 
	C181.041946,131.018875 181.036224,132.149979 181.066956,133.702911 
	C181.078003,134.547089 181.052582,134.969421 180.726456,135.942383 
	C180.551392,137.986160 180.677032,139.479309 180.802673,140.972473 
	C180.776550,141.464828 180.750412,141.957199 180.446106,142.923492 
	C180.048431,143.622147 180.012314,143.859604 180.059540,144.109802 
	C180.059540,144.109802 180.088135,144.564301 179.681137,144.838440 
	C179.274704,146.102142 179.275269,147.091736 179.275833,148.081314 
	C178.940628,149.178040 178.605423,150.274750 177.968994,151.753174 
	C177.594986,152.225006 177.447495,152.332260 177.463470,152.402115 
	C177.511292,152.611084 177.623093,152.805389 177.709915,153.005432 
	C177.808136,153.444183 177.906372,153.882935 177.839203,154.801453 
	C177.433273,155.647507 177.192719,156.013779 176.627396,156.403564 
	C172.897858,160.451614 169.558746,164.535568 166.022598,168.441116 
	C165.445129,169.078918 163.997986,168.929306 162.952805,169.143631 
	C161.771255,167.965805 160.589722,166.787964 158.967957,165.503891 
	C158.527710,165.397659 158.103699,165.307159 158.103699,165.307159 
	C157.531708,163.943375 156.959717,162.579575 156.096283,161.264679 
	C155.804810,161.313568 155.818085,161.275009 155.752472,160.986313 
	C155.180725,160.444733 154.674622,160.191833 153.882538,159.804047 
	C152.743454,158.747345 151.890335,157.825531 150.989609,156.597015 
	C150.317993,155.856064 149.693954,155.421814 148.950439,154.919037 
	C148.830948,154.850510 148.775085,154.580750 148.703766,154.226578 
	C148.224930,153.658310 147.817429,153.444214 147.136612,153.170700 
	C145.972260,152.463760 145.081223,151.816208 144.190186,151.168671 
	C143.554367,150.520004 142.918549,149.871353 141.985886,149.049835 
	C143.194321,145.392563 145.326981,141.686630 140.811783,138.931976 
	C137.262833,136.766830 133.861572,137.549194 131.026764,140.654236 
	C130.025085,141.751404 128.918106,142.752426 127.289749,144.039825 
	C126.580902,144.623016 126.441498,144.963730 126.302094,145.304459 
	C126.302086,145.304459 126.372635,145.203827 126.061005,145.272110 
	C125.607101,145.673553 125.464836,146.006714 125.322571,146.339874 
	C125.322571,146.339874 125.252968,146.176712 124.999565,146.266205 
	C124.503403,146.910645 124.260643,147.465591 124.017883,148.020523 
	C124.017883,148.020523 124.000000,148.000000 123.690872,148.042297 
	C122.922050,148.710556 122.462349,149.336517 122.002655,149.962494 
	C121.495987,150.555008 120.989326,151.147537 119.972244,151.827271 
	C119.461815,151.914505 118.978043,151.923660 118.774170,151.683411 
	C117.958557,151.370193 117.346825,151.297226 116.735092,151.224243 
	C116.735092,151.224243 116.842201,151.009964 116.880234,150.765350 
	C116.949005,150.037338 116.979729,149.553940 117.275391,149.064087 
	C117.716423,149.043304 117.892509,149.028961 118.414833,148.940063 
	C119.114380,148.074661 119.467697,147.283783 119.855850,146.238266 
	C120.249298,145.648972 120.607903,145.314346 121.293671,144.942902 
	C122.067139,144.250671 122.513435,143.595230 122.980255,142.967560 
	C123.000778,142.995331 122.941010,142.960861 123.310593,142.932266 
	C124.396233,141.777191 125.112282,140.650696 125.899216,139.236023 
	C126.926613,137.704941 127.883133,136.462051 128.993073,134.893799 
	C129.462982,133.670212 129.779480,132.771988 130.479279,131.950745 
	C133.240829,131.350815 135.619064,130.673889 138.441956,129.998016 
	C140.681305,129.953262 142.475998,129.907440 144.614014,129.913773 
	C152.246231,133.918945 153.619446,138.775711 149.700699,145.894501 
	C149.033203,147.107086 149.221786,148.790924 149.014862,150.257050 
	C150.470596,149.522629 152.432526,149.163696 153.300644,147.993393 
	C159.350616,139.837418 154.438736,127.227417 144.538986,125.109215 
	C137.713028,123.648697 131.494659,126.583633 129.716461,132.008148 
	C128.745132,132.700348 128.187714,133.294174 127.429962,133.764313 
	C127.229630,133.640625 126.989319,133.235687 126.901848,132.974152 
	C126.356712,132.456528 125.899055,132.200455 125.176987,131.782578 
	C124.232727,130.851624 123.552856,130.082474 122.801834,128.981781 
	C122.036278,128.147766 121.341881,127.645271 120.371826,126.973343 
	C118.250153,125.143440 116.274330,123.604225 114.585747,121.796677 
	C111.294464,118.273521 108.164001,114.600143 105.326462,110.859657 
	C107.009521,111.598778 108.333069,112.467209 109.739746,113.390190 
	C110.960060,112.320190 111.844429,111.312424 112.933136,110.631241 
	C117.165627,107.983093 121.462997,105.438660 126.060242,102.888695 
	C126.926918,102.633507 127.469582,102.345261 128.016968,102.026413 
	C128.021713,101.995804 128.068436,102.036453 128.491974,102.036644 
	C132.309372,101.110222 135.703247,100.183609 139.095947,99.190872 
	C139.094788,99.124741 139.226883,99.117561 139.516373,99.047951 
	C139.971695,98.869286 140.137543,98.760223 140.383209,98.538857 
	C140.463028,98.426559 140.693375,98.275330 140.926666,98.658112 
	C141.993027,99.253563 142.826096,99.466240 143.659180,99.678925 
	C143.972534,99.604584 144.278870,99.626839 145.032959,100.135170 
	C148.170731,100.934227 150.853745,101.343826 153.536743,101.753418 
	C154.915451,102.204765 156.294174,102.656120 158.015854,103.664955 
	C159.254715,104.473404 160.150620,104.724365 161.046509,104.975327 
	C161.046509,104.975327 161.521744,104.970802 161.921021,105.218094 
	C162.850159,105.644653 163.380035,105.823921 164.086487,106.293930 
	C168.177261,110.124153 172.091461,113.663628 175.989105,117.535622 
	C176.162430,118.898979 176.352295,119.929817 176.542160,120.960648 
	C177.940704,123.730675 179.339233,126.500702 180.892715,129.579254 
M142.411667,105.711327 
	C140.249573,105.794746 138.067963,105.722412 135.928955,105.990204 
	C128.947632,106.864212 122.838707,109.710770 117.854958,114.694450 
	C117.120552,115.428856 117.164948,116.942070 116.849014,118.094955 
	C118.149178,117.957916 119.463455,117.892845 120.741379,117.642357 
	C121.153870,117.561508 121.446869,116.963326 121.835022,116.652351 
	C130.105179,110.026413 139.505936,108.125580 149.534714,111.057755 
	C171.403641,117.451714 178.216599,143.476410 162.686737,160.191162 
	C161.925034,161.010956 162.283829,162.871796 162.120865,164.247879 
	C163.443558,163.914291 165.362823,164.017700 165.983749,163.170044 
	C168.428055,159.833221 170.971832,156.411972 172.567810,152.636963 
	C181.748260,130.922470 167.015274,107.447777 142.411667,105.711327 
M154.372009,118.990753 
	C151.306290,117.763893 148.339233,116.034325 145.155365,115.409531 
	C137.301819,113.868385 130.428284,116.528809 124.484932,121.615005 
	C123.780479,122.217857 123.922432,123.809761 123.672035,124.943192 
	C124.702065,124.960098 125.829720,125.248566 126.736504,124.922607 
	C127.782486,124.546600 128.627792,123.620331 129.570190,122.946938 
	C141.684769,114.290367 158.980133,121.893822 161.127960,136.717407 
	C162.050858,143.086761 160.155807,148.410095 156.230469,153.306885 
	C155.565384,154.136566 155.684448,155.649673 155.653275,156.852524 
	C155.649948,156.980560 157.309814,157.518906 157.794861,157.217636 
	C158.880295,156.543518 159.856293,155.566818 160.623871,154.526657 
	C169.193466,142.913757 166.966385,129.199417 154.372009,118.990753 
z"/>
<path fill="#C2D982" opacity="1.000000" stroke="none" 
	d="
M135.064758,189.839355 
	C135.959183,190.255249 137.043808,190.472214 137.718811,191.117599 
	C145.135193,198.208542 152.487793,205.366211 160.747559,213.367828 
	C148.762436,215.163879 138.689240,213.113388 128.203552,209.928528 
	C126.853279,208.310257 125.974632,207.169983 124.850266,205.710815 
	C126.143684,204.597092 127.446228,203.819351 128.272186,202.692337 
	C129.963989,200.383926 132.771545,197.782333 132.595322,195.496048 
	C132.373383,192.616821 133.466858,191.380325 135.064758,189.839355 
z"/>
<path fill="#DAE8B0" opacity="1.000000" stroke="none" 
	d="
M117.986885,207.041382 
	C115.199280,205.793198 112.411674,204.545013 109.310974,202.751434 
	C109.096802,200.213516 108.392593,197.327835 109.437950,196.387848 
	C113.322327,192.895050 113.128983,186.236038 118.966911,184.390182 
	C119.409325,184.250290 119.835762,183.298218 119.855949,182.709488 
	C119.977211,179.171356 122.303261,178.828262 125.028313,178.979660 
	C126.644020,180.720123 128.259720,182.460602 129.726273,184.677826 
	C126.226318,190.041794 122.652061,194.796478 119.635826,199.882111 
	C118.445732,201.888687 118.499496,204.632996 117.986885,207.041382 
z"/>
<path fill="#E2EDC3" opacity="1.000000" stroke="none" 
	d="
M124.883591,178.677490 
	C122.303261,178.828262 119.977211,179.171356 119.855949,182.709488 
	C119.835762,183.298218 119.409325,184.250290 118.966911,184.390182 
	C113.128983,186.236038 113.322327,192.895050 109.437950,196.387848 
	C108.392593,197.327835 109.096802,200.213516 109.007919,202.582001 
	C105.772171,200.849869 102.526375,198.741776 99.174408,195.980713 
	C103.996880,188.649216 108.864983,181.924438 113.903946,175.330215 
	C114.943527,173.969757 116.619759,173.095764 118.002563,171.997559 
	C120.248001,174.123474 122.493439,176.249405 124.883591,178.677490 
z"/>
<path fill="#EAF1D1" opacity="1.000000" stroke="none" 
	d="
M117.865814,171.702698 
	C116.619759,173.095764 114.943527,173.969757 113.903946,175.330215 
	C108.864983,181.924438 103.996880,188.649216 99.023170,195.677917 
	C97.742416,195.210892 96.506737,194.393707 95.137466,192.913559 
	C96.339066,189.661057 97.450432,186.921478 99.062317,184.517471 
	C101.429749,180.986572 105.124008,178.032059 106.452484,174.206711 
	C107.757164,170.449875 109.622887,168.320938 113.000626,166.984924 
	C114.576767,168.459229 116.152916,169.933517 117.865814,171.702698 
z"/>
<path fill="#E9FAE7" opacity="1.000000" stroke="none" 
	d="
M112.859985,166.668488 
	C109.622887,168.320938 107.757164,170.449875 106.452484,174.206711 
	C105.124008,178.032059 101.429749,180.986572 99.062317,184.517471 
	C97.450432,186.921478 96.339066,189.661057 94.993706,192.626297 
	C93.658134,190.590317 92.332748,188.178619 91.274124,185.165070 
	C95.462456,178.113083 99.257767,171.579605 103.413658,165.284180 
	C104.231041,164.045990 106.440460,163.726730 108.007874,162.983658 
	C109.578369,164.106461 111.148857,165.229248 112.859985,166.668488 
z"/>
<path fill="#D1E39E" opacity="1.000000" stroke="none" 
	d="
M118.314842,207.199814 
	C118.499496,204.632996 118.445732,201.888687 119.635826,199.882111 
	C122.652061,194.796478 126.226318,190.041794 129.898315,184.967834 
	C131.809464,186.248535 133.399414,187.716019 135.027054,189.511414 
	C133.466858,191.380325 132.373383,192.616821 132.595322,195.496048 
	C132.771545,197.782333 129.963989,200.383926 128.272186,202.692337 
	C127.446228,203.819351 126.143684,204.597092 124.850266,205.710815 
	C125.974632,207.169983 126.853279,208.310257 127.856712,209.765381 
	C124.868599,209.172897 121.755699,208.265564 118.314842,207.199814 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M118.068604,149.014603 
	C117.892509,149.028961 117.716423,149.043304 116.978912,149.196106 
	C116.162239,149.684326 115.906998,150.034119 115.651756,150.383911 
	C108.153008,143.113556 100.638992,135.858887 93.159393,128.568878 
	C83.916351,119.560143 74.701904,110.522087 65.232666,101.260628 
	C65.612442,100.469315 66.234650,99.914078 67.296432,99.232712 
	C78.119133,109.528923 88.420357,120.034676 98.916405,130.342026 
	C105.127357,136.441330 111.646179,142.227142 118.044556,148.368713 
	C118.062546,148.583527 118.068604,149.014603 118.068604,149.014603 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M107.968246,162.630554 
	C106.440460,163.726730 104.231041,164.045990 103.413658,165.284180 
	C99.257767,171.579605 95.462456,178.113083 91.274460,184.780853 
	C89.126938,180.791931 88.153244,176.922348 91.923981,172.846619 
	C94.692497,169.854172 96.734749,166.106125 98.709206,162.481567 
	C102.004234,156.432800 103.901169,156.309860 107.968246,162.630554 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M58.861771,99.308594 
	C60.955181,99.413414 62.898689,99.819504 64.924469,100.597275 
	C62.624207,101.409370 60.241673,101.849785 57.521996,102.419495 
	C57.693859,101.569153 58.202866,100.589508 58.861771,99.308594 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M55.283699,110.726929 
	C55.442390,108.388794 55.844635,105.766541 56.610058,103.079643 
	C56.491241,105.490936 56.009243,107.966873 55.283699,110.726929 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M54.019562,143.995331 
	C54.128403,141.865540 54.237244,139.735748 54.599586,137.315308 
	C55.232002,139.512970 55.610916,142.001251 55.505806,144.715515 
	C54.687714,144.626099 54.353638,144.310715 54.019562,143.995331 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M54.178417,122.019913 
	C54.094410,119.556000 54.203087,117.102242 54.560154,114.348534 
	C54.855145,116.419281 54.901745,118.789970 54.852669,121.597580 
	C54.756992,122.034508 54.371105,122.030052 54.178417,122.019913 
z"/>
<path fill="#58B953" opacity="1.000000" stroke="none" 
	d="
M53.657555,144.004135 
	C54.353638,144.310715 54.687714,144.626099 55.360519,145.054840 
	C55.699249,145.168198 55.776806,145.562775 55.723190,145.754120 
	C54.687283,145.940033 53.682133,146.055237 52.732903,145.875351 
	C52.295662,145.792480 51.967880,145.131989 51.590462,144.733429 
	C52.158829,144.493271 52.727192,144.253113 53.657555,144.004135 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M118.978043,151.923660 
	C118.978043,151.923660 119.461815,151.914505 119.701950,151.942566 
	C119.069992,152.167526 118.197899,152.364410 117.515991,152.269592 
	C118.130135,151.959824 118.554085,151.941742 118.978043,151.923660 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M122.277031,149.865433 
	C122.462349,149.336517 122.922050,148.710556 123.701393,148.051025 
	C123.531158,148.601105 123.041283,149.184738 122.277031,149.865433 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M115.788330,150.588852 
	C115.906998,150.034119 116.162239,149.684326 116.713974,149.202530 
	C116.979729,149.553940 116.949005,150.037338 116.846436,150.880264 
	C116.425468,151.216721 116.142227,151.068069 115.788330,150.588852 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M118.774170,151.683411 
	C118.554085,151.941742 118.130135,151.959824 117.387131,151.978470 
	C117.068077,151.979050 116.805290,151.643097 116.770187,151.433670 
	C117.346825,151.297226 117.958557,151.370193 118.774170,151.683411 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M124.289017,147.900482 
	C124.260643,147.465591 124.503403,146.910645 125.068222,146.322357 
	C125.113571,146.786148 124.836861,147.283295 124.289017,147.900482 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M125.578018,146.273346 
	C125.464836,146.006714 125.607101,145.673553 126.044327,145.331299 
	C126.170677,145.617065 126.002068,145.911942 125.578018,146.273346 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M126.561432,145.204926 
	C126.441498,144.963730 126.580902,144.623016 126.994125,144.187790 
	C127.118874,144.430664 126.969818,144.768036 126.561432,145.204926 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M54.882526,129.746613 
	C54.716354,129.732605 54.610703,129.469360 54.643360,129.108276 
	C54.835464,129.172729 54.889252,129.335037 54.882526,129.746613 
z"/>
<path fill="#5DB858" opacity="1.000000" stroke="none" 
	d="
M173.928223,93.861946 
	C178.235306,89.460144 182.458084,84.971428 186.877274,80.685219 
	C190.166367,77.495094 194.431259,75.399284 198.625092,77.656586 
	C201.472946,79.189423 204.523132,82.493294 205.102783,85.468811 
	C205.645340,88.253807 203.718491,92.194138 201.698273,94.658585 
	C197.917648,99.270554 193.283325,103.182716 188.440277,107.637619 
	C186.914383,107.265831 185.952347,106.642433 184.699509,105.857498 
	C181.611313,102.780380 178.813904,99.864792 175.842041,96.675461 
	C175.087799,95.555130 174.508011,94.708534 173.928223,93.861946 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M146.393112,88.712875 
	C146.393112,88.712875 146.735825,88.980820 146.944855,89.341743 
	C147.339066,89.932510 147.578995,90.037773 147.873657,90.018440 
	C148.059952,89.994621 148.246231,89.970802 148.747406,90.324905 
	C149.137589,91.269096 149.212860,91.835358 149.288132,92.401619 
	C147.840286,93.895576 146.392456,95.389534 144.247513,96.984772 
	C142.968140,97.042984 142.385864,96.999916 141.876678,96.692078 
	C141.981308,96.246925 142.012848,96.066551 142.036285,95.704834 
	C141.951492,95.285309 141.810837,95.094223 141.448212,94.834846 
	C141.290222,94.719467 141.075836,94.392151 141.015488,94.294159 
	C140.955139,94.196167 140.593536,93.918152 140.466034,93.840828 
	C140.338516,93.763504 140.196518,93.501244 140.161331,93.147858 
	C140.087326,91.842484 140.048492,90.890495 140.009674,89.938499 
	C140.902267,89.474350 141.794876,89.010201 143.265991,88.228836 
	C144.348969,87.729507 144.853455,87.547386 145.357925,87.365265 
	C145.357925,87.365265 145.491699,87.500954 145.489624,87.864090 
	C145.789398,88.389114 146.091263,88.550995 146.393112,88.712875 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M149.603699,92.278076 
	C149.212860,91.835358 149.137589,91.269096 149.024902,90.276352 
	C153.086624,86.192894 157.185776,82.535912 161.656311,78.926758 
	C157.991547,83.367897 153.955414,87.761208 149.603699,92.278076 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M184.990311,106.019035 
	C185.952347,106.642433 186.914383,107.265831 188.175507,107.862656 
	C184.557770,111.978233 180.640961,116.120392 176.633148,120.611595 
	C176.352295,119.929817 176.162430,118.898979 176.150482,117.230728 
	C177.762726,115.265854 179.382462,114.086082 180.586090,112.574898 
	C182.220856,110.522377 183.537323,108.216339 184.990311,106.019035 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M173.625122,94.005264 
	C174.508011,94.708534 175.087799,95.555130 175.471161,96.610184 
	C171.486450,99.880150 167.698181,102.941666 163.909912,106.003181 
	C163.380035,105.823921 162.850159,105.644653 162.158401,105.228737 
	C165.771698,101.377579 169.546860,97.763077 173.625122,94.005264 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M153.237457,101.483902 
	C150.853745,101.343826 148.170731,100.934227 145.243729,100.234390 
	C147.645874,100.367554 150.292007,100.790962 153.237457,101.483902 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M141.803589,96.956848 
	C142.385864,96.999916 142.968140,97.042984 143.985107,97.153412 
	C144.151199,97.865288 143.882568,98.509796 143.636566,99.416611 
	C142.826096,99.466240 141.993027,99.253563 140.962860,98.429199 
	C141.111725,97.530632 141.457657,97.243744 141.803589,96.956848 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M160.841187,104.713120 
	C160.150620,104.724365 159.254715,104.473404 158.252319,103.871574 
	C158.975815,103.830772 159.805847,104.140839 160.841187,104.713120 
z"/>
<path fill="#5DB858" opacity="1.000000" stroke="none" 
	d="
M173.019379,178.929779 
	C172.960724,178.566559 172.759415,178.319626 172.301544,177.764069 
	C176.555817,173.246796 180.924011,169.154480 185.624649,165.031525 
	C186.397141,164.930771 186.837189,164.860641 187.277252,164.790527 
	C192.858932,170.174606 198.751450,175.280930 203.904770,181.047806 
	C206.955444,184.461716 205.957153,189.688751 202.840485,192.825058 
	C199.387665,196.299637 194.682327,197.055084 190.563583,194.658157 
	C188.850800,193.661392 187.288773,192.405594 185.278183,190.869492 
	C184.607330,190.284607 184.316483,190.097824 184.025650,189.911026 
	C182.905899,188.682617 181.786163,187.454224 180.295868,185.844910 
	C179.617111,185.307999 179.308914,185.152008 179.000717,184.996017 
	C178.510101,184.411194 178.019485,183.826385 177.206985,182.860291 
	C176.597992,182.292114 176.310898,182.105179 176.023788,181.918259 
	C175.971680,181.582260 175.804855,181.318970 175.213867,180.804016 
	C174.607559,180.309174 174.310699,180.138687 174.013824,179.968201 
	C174.013824,179.968201 173.998779,180.001221 173.939499,179.740906 
	C173.593277,179.296982 173.306320,179.113388 173.019379,178.929779 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M185.292221,165.062164 
	C180.924011,169.154480 176.555817,173.246796 172.068466,177.676910 
	C168.990372,175.274902 163.019363,175.865219 162.965271,169.521820 
	C163.997986,168.929306 165.445129,169.078918 166.022598,168.441116 
	C169.558746,164.535568 172.897858,160.451614 176.783127,156.673309 
	C179.939835,159.633743 182.616028,162.347961 185.292221,165.062164 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M185.624649,165.031525 
	C182.616028,162.347961 179.939835,159.633743 177.107910,156.649780 
	C177.192719,156.013779 177.433273,155.647507 177.963593,155.116898 
	C181.221832,158.005203 184.190338,161.057831 187.218048,164.450500 
	C186.837189,164.860641 186.397141,164.930771 185.624649,165.031525 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M179.494751,147.834564 
	C179.275269,147.091736 179.274704,146.102142 179.599594,145.051041 
	C179.854584,145.855621 179.784119,146.721725 179.494751,147.834564 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M180.901947,140.568329 
	C180.677032,139.479309 180.551392,137.986160 180.668976,136.254486 
	C180.941864,137.398712 180.971527,138.781464 180.901947,140.568329 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M180.222382,143.988464 
	C180.012314,143.859604 180.048431,143.622147 180.279663,143.256775 
	C180.585464,143.367996 180.583389,143.618347 180.222382,143.988464 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M158.127548,165.490173 
	C158.103699,165.307159 158.527710,165.397659 158.670166,165.561615 
	C158.812637,165.725571 158.430496,165.957138 158.325653,165.996460 
	C158.220810,166.035767 158.151398,165.673203 158.127548,165.490173 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M177.859863,152.778168 
	C177.623093,152.805389 177.511292,152.611084 177.463470,152.402115 
	C177.447495,152.332260 177.594986,152.225006 177.831512,152.070709 
	C178.000107,152.188004 178.004959,152.369461 177.859863,152.778168 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M154.260010,169.909363 
	C154.085419,169.661346 154.251358,169.395889 154.782440,169.146454 
	C154.910172,169.380905 154.727814,169.624054 154.260010,169.909363 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M153.255615,170.906555 
	C153.083710,170.661728 153.250336,170.392090 153.777039,170.126663 
	C153.911453,170.362274 153.730469,170.612549 153.255615,170.906555 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M173.113342,179.168762 
	C173.306320,179.113388 173.593277,179.296982 173.955994,179.733398 
	C173.756958,179.793381 173.482117,179.600555 173.113342,179.168762 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M174.117508,180.202789 
	C174.310699,180.138687 174.607559,180.309174 174.986206,180.730408 
	C174.785706,180.799911 174.503448,180.618637 174.117508,180.202789 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M176.113144,182.156479 
	C176.310898,182.105179 176.597992,182.292114 176.941330,182.739914 
	C176.732544,182.798767 176.467514,182.596725 176.113144,182.156479 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M179.115295,185.225067 
	C179.308914,185.152008 179.617111,185.307999 180.008347,185.718628 
	C179.804214,185.800232 179.517029,185.627167 179.115295,185.225067 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M184.117828,190.152740 
	C184.316483,190.097824 184.607330,190.284607 184.964767,190.731323 
	C184.757584,190.792328 184.483795,190.593399 184.117828,190.152740 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M141.876678,96.692078 
	C141.457657,97.243744 141.111725,97.530632 140.729584,98.046425 
	C140.693375,98.275330 140.463028,98.426559 140.215790,98.465508 
	C139.721329,98.708824 139.474106,98.913200 139.226883,99.117569 
	C139.226883,99.117561 139.094788,99.124741 138.698456,99.181877 
	C134.976547,99.881424 131.650940,100.523834 127.961296,101.118057 
	C126.275856,100.219048 124.958954,99.361145 123.632195,98.518761 
	C120.193024,96.335167 116.749527,94.158409 113.131172,91.716370 
	C112.301163,90.643448 111.647743,89.833076 110.877724,88.788101 
	C110.464577,88.381981 110.168022,88.210464 109.587685,87.910522 
	C108.200798,86.854660 107.097702,85.927238 105.991493,84.777443 
	C105.988380,84.555069 105.967209,84.110786 105.876167,83.863815 
	C105.505852,83.416122 105.226578,83.215385 104.640213,82.873093 
	C103.160904,81.831856 101.990883,80.929291 100.816078,80.032997 
	C95.091232,75.665367 89.640892,75.869644 85.605171,80.601013 
	C81.603294,85.292702 82.322792,89.815498 87.999893,95.360229 
	C88.281052,95.830048 88.540390,95.984505 88.943954,96.305878 
	C89.343826,96.748718 89.658775,96.925072 90.116127,97.399460 
	C94.170731,101.470474 98.082939,105.243454 102.149223,109.182823 
	C102.303307,109.349205 102.756737,109.339691 102.822647,109.216965 
	C102.888557,109.094231 102.712387,109.310081 102.780106,109.637474 
	C103.563835,110.321495 104.279861,110.678116 105.002075,111.014374 
	C105.008263,110.994003 104.966942,110.988968 104.966942,110.988968 
	C108.164001,114.600143 111.294464,118.273521 114.585747,121.796677 
	C116.274330,123.604225 118.250153,125.143440 120.489487,127.274948 
	C121.546204,128.268433 122.209602,128.790863 122.872993,129.313309 
	C123.552856,130.082474 124.232727,130.851624 125.268234,132.035217 
	C126.079025,132.711670 126.534172,132.973679 126.989319,133.235687 
	C126.989319,133.235687 127.229630,133.640625 127.501556,133.983719 
	C128.128876,134.624268 128.484268,134.921722 128.839661,135.219162 
	C127.883133,136.462051 126.926613,137.704941 125.610977,139.397141 
	C124.481583,140.884583 123.711296,141.922729 122.941010,142.960861 
	C122.941010,142.960861 123.000778,142.995331 122.713974,143.097382 
	C121.940285,143.792862 121.453392,144.386276 120.966507,144.979706 
	C120.607903,145.314346 120.249298,145.648972 119.586533,146.391083 
	C118.863777,147.250336 118.445168,147.702118 118.026558,148.153915 
	C111.646179,142.227142 105.127357,136.441330 98.916405,130.342026 
	C88.420357,120.034676 78.119133,109.528923 67.443741,98.949005 
	C67.012245,97.977615 66.872993,97.163811 66.448761,95.786133 
	C65.821396,94.346275 65.479004,93.470306 65.136620,92.594330 
	C65.102722,92.225052 65.068825,91.855774 65.009285,90.793442 
	C64.935837,88.083138 64.888031,86.065887 64.840225,84.048637 
	C64.953026,83.610344 65.065819,83.172043 65.190002,82.394020 
	C66.695557,78.749382 68.189705,75.444481 69.786896,71.750023 
	C69.889931,71.360474 69.988319,71.308746 69.988319,71.308746 
	C74.514351,67.890358 78.700821,63.827236 83.653442,61.218830 
	C89.673698,58.048141 96.409248,58.204700 103.357422,60.496735 
	C104.636276,61.065907 105.508621,61.550060 106.863724,62.222771 
	C107.346489,62.411320 107.816437,62.536018 107.816437,62.536018 
	C112.984131,67.276291 118.151817,72.016563 123.739502,77.240265 
	C129.505173,82.982880 134.850845,88.242065 140.196518,93.501244 
	C140.196518,93.501244 140.338516,93.763504 140.423630,94.005142 
	C140.508743,94.246780 140.750412,94.481712 140.750412,94.481712 
	C140.750412,94.481712 141.075836,94.392151 141.075836,94.392151 
	C141.075836,94.392151 141.290222,94.719467 141.405823,95.027145 
	C141.592484,95.616394 141.766800,95.800179 142.044373,95.886169 
	C142.012848,96.066551 141.981308,96.246925 141.876678,96.692078 
z"/>
<path fill="#88C65F" opacity="1.000000" stroke="none" 
	d="
M69.967659,70.972687 
	C69.988319,71.308746 69.889931,71.360474 69.843231,71.383118 
	C69.350449,71.023277 68.904373,70.640785 68.458290,70.258301 
	C68.729591,70.011162 69.000893,69.764015 69.272194,69.516876 
	C69.497131,69.890129 69.722061,70.263382 69.967659,70.972687 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M140.161331,93.147858 
	C134.850845,88.242065 129.505173,82.982880 124.015839,77.423935 
	C124.922714,76.741119 125.973244,76.358078 127.357368,75.955521 
	C131.712540,80.399963 135.734131,84.863922 139.882690,89.633194 
	C140.048492,90.890495 140.087326,91.842484 140.161331,93.147858 
z"/>
<path fill="#80C35D" opacity="1.000000" stroke="none" 
	d="
M141.391022,32.465637 
	C140.941635,32.349625 140.666061,32.066441 140.411835,31.765230 
	C140.405243,31.757441 140.632309,31.552441 140.750412,31.439415 
	C141.022827,31.724972 141.295242,32.010529 141.391022,32.465637 
z"/>
<path fill="#80C35D" opacity="1.000000" stroke="none" 
	d="
M107.828178,62.307152 
	C107.816437,62.536018 107.346489,62.411320 107.165939,62.248634 
	C107.270226,62.083393 107.555077,62.080837 107.828178,62.307152 
z"/>
<path fill="#67BC55" opacity="1.000000" stroke="none" 
	d="
M150.449127,38.533653 
	C150.415588,38.477551 150.482666,38.589756 150.449127,38.533653 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M145.258728,87.096687 
	C144.853455,87.547386 144.348969,87.729507 143.445343,87.956818 
	C143.750641,87.610703 144.455093,87.219406 145.258728,87.096687 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M146.327209,88.487671 
	C146.091263,88.550995 145.789398,88.389114 145.525879,87.952202 
	C145.888199,87.763084 146.120560,87.958183 146.327209,88.487671 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M147.784821,89.837021 
	C147.578995,90.037773 147.339066,89.932510 147.067444,89.521408 
	C147.271622,89.326889 147.509949,89.432037 147.784821,89.837021 
z"/>
<path fill="#58B953" opacity="1.000000" stroke="none" 
	d="
M51.450027,137.533417 
	C51.417282,137.478256 51.482773,137.588577 51.450027,137.533417 
z"/>
<path fill="#80C35D" opacity="1.000000" stroke="none" 
	d="
M64.593376,84.320328 
	C64.888031,86.065887 64.935837,88.083138 64.918686,90.528351 
	C64.684669,88.834877 64.515594,86.713448 64.593376,84.320328 
z"/>
<path fill="#80C35D" opacity="1.000000" stroke="none" 
	d="
M65.099838,92.986984 
	C65.479004,93.470306 65.821396,94.346275 66.209068,95.579628 
	C65.857254,95.084549 65.460152,94.232094 65.099838,92.986984 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M143.930481,151.380737 
	C145.081223,151.816208 145.972260,152.463760 147.285461,153.467911 
	C148.063431,154.076599 148.419250,154.328674 148.775085,154.580750 
	C148.775085,154.580750 148.830948,154.850510 149.040085,155.193909 
	C149.845215,155.992783 150.441208,156.448257 151.037201,156.903717 
	C151.890335,157.825531 152.743454,158.747345 153.963577,160.081573 
	C154.826401,160.754318 155.322250,161.014664 155.818085,161.275009 
	C155.818085,161.275009 155.804810,161.313568 155.788605,161.327515 
	C155.341599,162.648071 155.220459,164.196167 154.423904,165.217529 
	C151.661438,168.759720 148.664291,172.118912 145.532150,175.812164 
	C140.714081,170.691559 137.011230,166.756210 132.280350,161.728287 
	C135.805161,158.591812 139.737961,155.092300 143.930481,151.380737 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M692.000000,55.999992 
	C693.261108,57.280968 694.522278,58.561943 695.783386,59.842930 
	C696.819885,58.130363 698.028687,56.493084 698.823364,54.674850 
	C699.249695,53.699429 698.971619,52.413113 698.998962,51.266785 
	C699.127197,45.883099 699.827942,45.391354 705.610352,47.288231 
	C705.997742,48.110970 705.997620,48.555302 705.616455,49.050171 
	C701.387329,52.779713 702.379456,57.413082 708.005127,62.396145 
	C708.786682,67.103165 711.704163,71.541878 706.601074,75.414650 
	C705.846741,75.987114 706.769348,78.769234 706.890015,80.216110 
	C709.332703,78.884430 711.055664,77.945114 712.860229,77.370804 
	C712.983093,78.480789 713.024353,79.225784 712.767578,80.235397 
	C713.619690,85.667107 714.769897,90.834198 716.019287,96.163025 
	C716.118469,96.324753 716.104675,96.703941 716.131348,96.951912 
	C716.260132,97.534027 716.461548,97.789902 716.846436,98.359222 
	C716.993591,99.815697 717.056641,100.880447 716.822693,102.182587 
	C716.244751,103.669762 716.334167,105.208847 715.621460,106.121170 
	C711.754883,111.070061 715.387634,110.954330 718.916382,111.028427 
	C719.023682,110.986534 719.227539,110.879097 719.227539,110.879097 
	C719.200562,111.336021 719.173584,111.792938 718.776245,112.668716 
	C714.986450,116.997444 718.800903,118.727547 720.875854,121.362885 
	C721.031311,122.445068 721.103210,123.171402 720.876343,124.128662 
	C721.073914,125.898529 721.570312,127.437477 722.354492,129.233368 
	C722.837708,131.307922 723.033203,133.125534 722.904907,135.184814 
	C722.728943,139.063110 723.366272,142.761902 722.913940,146.322281 
	C722.171204,152.167862 724.691650,156.620148 727.991211,161.214050 
	C728.148193,161.762634 728.153687,162.099289 728.161743,162.723846 
	C728.361572,163.328049 728.558838,163.644318 728.844116,164.348755 
	C729.025757,165.793488 729.119324,166.850082 728.897705,168.140991 
	C729.873779,176.557678 731.166809,184.739792 732.452209,192.923111 
	C732.523560,193.377411 732.530945,193.841797 732.589172,194.562134 
	C731.397705,194.562134 730.359070,194.562134 729.335083,194.562134 
	C731.194641,198.713943 732.909424,202.678055 734.761230,206.577103 
	C735.198975,207.498871 736.097351,208.201828 736.871155,209.331070 
	C736.970764,210.104141 736.983032,210.551559 736.995361,210.998978 
	C736.845459,214.043930 736.695618,217.088882 736.504639,220.968124 
	C733.368713,220.968124 729.162354,221.315704 725.077393,220.764420 
	C723.787781,220.590378 722.822754,218.010651 721.453369,216.288116 
	C721.096130,215.292648 720.994568,214.540985 721.201416,213.169769 
	C720.739868,209.698853 719.969788,206.847504 719.199707,203.996155 
	C719.093750,203.561966 718.987732,203.127777 719.232666,202.195938 
	C720.009094,200.855316 720.895935,199.945114 720.784424,199.180435 
	C720.119263,194.617035 719.223877,190.087219 718.300232,184.960648 
	C716.620300,185.123184 713.956177,184.799484 711.799255,185.696091 
	C704.659973,188.663879 697.245239,187.960617 689.873047,187.987579 
	C677.047363,188.034470 664.221252,188.027771 651.395508,187.986038 
	C646.835938,187.971207 641.843445,189.243088 638.731873,184.222534 
	C638.666809,184.117630 638.118652,184.312149 637.532166,184.411789 
	C637.215149,185.709717 636.874573,187.104141 636.261963,189.612488 
	C634.292786,187.902527 632.270081,186.907639 631.509583,185.311386 
	C630.296753,182.765900 629.923706,179.820297 629.195618,177.043869 
	C629.147339,165.662949 629.099060,154.282043 629.266602,142.345413 
	C629.664246,141.456558 629.570435,141.201126 629.200806,141.023392 
	C629.148621,140.258499 629.096497,139.493607 629.260681,138.260681 
	C629.663025,137.460220 629.571045,137.203690 629.201172,137.023071 
	C629.153076,136.572662 629.105042,136.122253 629.416321,135.295822 
	C630.185547,134.348709 630.595520,133.777618 631.005432,133.206543 
	C631.456055,133.156540 631.906616,133.106522 632.782104,133.266479 
	C633.206970,133.476456 633.648560,133.547836 633.648560,133.547836 
	C633.648560,133.547836 633.976685,133.201859 633.976685,133.201859 
	C634.748657,133.147614 635.520691,133.093369 636.992249,133.374542 
	C654.855469,133.797928 672.019104,133.893585 689.182800,133.941147 
	C689.772095,133.942795 690.362610,133.462555 690.952576,133.206909 
	C692.046875,133.152374 693.141113,133.097855 694.862305,133.373199 
	C698.246399,134.938324 700.905396,136.528976 703.784241,137.323776 
	C707.883728,138.455612 710.224060,136.447678 710.465271,131.956146 
	C710.695679,127.664665 707.261780,129.475601 705.220886,128.991104 
	C705.112549,128.252457 705.004272,127.513809 705.213928,126.137222 
	C705.634766,122.411835 708.035278,118.677963 703.139709,116.996819 
	C703.038818,116.569237 702.937988,116.141655 703.162292,115.133850 
	C703.333923,112.308670 703.283875,110.050850 703.007080,107.821182 
	C702.244873,101.681664 701.427551,101.002090 695.166626,101.000679 
	C676.737488,100.996536 658.308289,101.007019 639.879150,100.992981 
	C635.839417,100.989906 631.394958,101.987450 629.211609,96.995781 
	C629.158569,81.307541 629.105591,65.619308 629.460938,49.465038 
	C630.581299,48.402992 631.293213,47.806980 632.005188,47.210964 
	C647.040955,47.148571 662.076660,47.086182 677.752686,47.403343 
	C681.909851,50.744019 685.426819,53.705147 689.396790,57.047672 
	C689.338135,57.071281 690.669067,56.535637 692.000000,55.999992 
M700.734985,142.857681 
	C699.503235,141.884903 698.271484,140.912109 697.039734,139.939316 
	C696.703674,140.622009 696.367554,141.304703 696.031494,141.987411 
	C697.355835,142.314728 698.680237,142.642044 700.734985,142.857681 
M723.313843,164.474289 
	C722.975281,165.021103 722.636719,165.567917 722.298157,166.114731 
	C722.572327,166.249542 722.846558,166.384354 723.120728,166.519180 
	C723.325745,165.983688 723.530701,165.448196 723.313843,164.474289 
M711.704468,100.566681 
	C711.989319,100.294792 712.274231,100.022911 712.559143,99.751022 
	C712.446472,99.633110 712.241943,99.406509 712.234253,99.413002 
	C711.933777,99.666718 711.651245,99.941650 711.704468,100.566681 
M714.295654,126.433296 
	C714.010681,126.705223 713.725769,126.977150 713.440857,127.249107 
	C713.553589,127.367020 713.758118,127.593658 713.765808,127.587128 
	C714.066345,127.333389 714.348938,127.058441 714.295654,126.433296 
M639.607178,142.798325 
	C639.213806,142.794113 638.820374,142.789902 638.427002,142.785706 
	C638.425415,142.948975 638.412903,143.254150 638.423828,143.255005 
	C638.815308,143.285187 639.209045,143.286942 639.607178,142.798325 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M905.600342,145.201202 
	C904.279358,154.960495 902.749023,164.931747 900.963074,174.857025 
	C898.326965,189.506683 895.509033,204.123642 892.673401,219.262604 
	C873.058838,219.262604 853.481262,219.262604 833.443848,219.152130 
	C834.698425,218.694046 836.411133,218.054321 838.127380,218.044754 
	C852.573669,217.964081 867.020630,217.999008 881.467346,217.998703 
	C889.776245,217.998520 889.957275,218.047485 892.173218,209.918854 
	C893.040344,206.738205 892.575256,203.211563 893.283813,199.966278 
	C894.205688,195.743179 896.049316,191.712463 896.869202,187.477539 
	C899.411865,174.343613 901.642700,161.149460 904.052002,147.989212 
	C904.218567,147.079437 904.857300,146.256119 905.600342,145.201202 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M777.905884,129.104950 
	C776.618652,140.022415 775.022217,150.885574 773.451050,161.752380 
	C773.240051,163.211716 773.156311,164.689438 773.091858,166.584747 
	C773.062805,167.434250 772.955505,167.857666 772.577026,168.874817 
	C772.027649,171.146118 771.749512,172.823715 771.471375,174.501297 
	C771.172668,174.513031 770.873901,174.524765 770.575195,174.536514 
	C769.940063,170.715454 769.304932,166.894394 768.669800,163.073349 
	C768.565552,162.518234 768.461304,161.963135 768.143311,161.212585 
	C767.643127,157.951599 767.356750,154.886047 767.462708,151.455566 
	C768.963623,151.923569 770.072266,152.756516 771.473022,153.808884 
	C772.706543,150.066132 774.129089,146.754852 774.856750,143.297363 
	C775.847351,138.590240 776.307495,133.771484 776.995117,129.000610 
	C777.198364,129.017685 777.401550,129.034744 777.905884,129.104950 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M741.630615,18.070351 
	C743.069641,18.037500 744.140747,18.104063 745.606079,18.079777 
	C748.314758,18.103060 750.629211,18.217192 753.369873,18.380610 
	C753.440430,20.524660 753.084778,22.619425 752.440369,24.936655 
	C752.001831,23.685181 751.852051,22.211241 751.677673,20.495556 
	C750.453857,20.321215 749.341980,20.027054 748.229614,20.024817 
	C732.569580,19.993301 716.909424,20.011143 701.249329,19.989353 
	C698.983582,19.986200 696.697876,19.812145 696.701782,23.416798 
	C695.635071,21.787340 694.874878,19.915016 694.114624,18.042700 
	C694.114624,18.042707 694.564941,18.136656 695.151611,18.155800 
	C696.492249,18.113247 697.246216,18.051546 698.000183,17.989845 
	C710.359253,18.051889 722.718323,18.113932 735.907227,18.180901 
	C737.491394,18.124653 738.245728,18.063477 739.000000,18.002300 
	C739.754211,18.058121 740.508423,18.113943 741.630615,18.070351 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M729.212891,167.906693 
	C729.119324,166.850082 729.025757,165.793488 728.876892,164.054062 
	C728.600769,163.059464 728.379944,162.747696 728.159119,162.435944 
	C728.153687,162.099289 728.148193,161.762634 728.040955,160.798080 
	C727.226990,155.178650 726.514771,150.187119 725.802551,145.195587 
	C726.770874,146.303604 728.407593,147.303757 728.606384,148.535934 
	C731.197632,164.593048 733.594177,180.681686 736.017944,196.765656 
	C736.700623,201.296158 737.312500,205.837326 737.912842,210.853088 
	C737.868896,211.332581 737.429749,211.172012 737.212524,211.085495 
	C736.983032,210.551559 736.970764,210.104141 736.934143,208.928558 
	C734.344177,194.769150 731.778503,181.337921 729.212891,167.906693 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M705.997559,48.999634 
	C705.997620,48.555302 705.997742,48.110970 705.996460,47.333397 
	C706.184448,46.992809 706.373779,46.985470 707.034424,46.989216 
	C707.505798,47.000301 707.878479,47.063221 707.878540,47.063217 
	C707.914978,47.497925 707.951416,47.932636 708.024048,49.087646 
	C709.650513,57.897087 711.240845,65.986229 712.831116,74.075378 
	C712.870605,74.514320 712.910095,74.953255 712.959351,75.953720 
	C712.905640,76.678764 712.842163,76.842285 712.778687,77.005814 
	C711.055664,77.945114 709.332703,78.884430 706.890015,80.216110 
	C706.769348,78.769234 705.846741,75.987114 706.601074,75.414650 
	C711.704163,71.541878 708.786682,67.103165 708.005005,61.996849 
	C707.330750,57.134407 706.664124,53.067024 705.997559,48.999634 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M802.355957,215.072571 
	C802.876648,218.136795 801.450745,219.362411 798.245361,219.361984 
	C779.430420,219.359512 760.615417,219.572678 741.800720,219.534622 
	C737.196838,219.525314 738.518738,215.489319 738.003540,212.412125 
	C738.983154,212.471313 740.743591,212.949921 740.739075,213.411316 
	C740.687500,218.700027 744.371216,218.004929 747.692383,218.003159 
	C763.507935,217.994766 779.325256,218.113373 795.137085,217.872421 
	C797.443054,217.837280 799.723145,216.107468 802.355957,215.072571 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M785.083008,84.391373 
	C785.357910,82.911133 785.657959,81.806282 785.892944,80.348396 
	C785.968079,79.274216 786.108276,78.553078 786.459778,77.184845 
	C786.716003,75.357735 786.760925,74.177734 786.805786,72.997726 
	C786.920471,72.564255 787.035095,72.130783 787.122681,71.359970 
	C788.228149,64.549217 789.360718,58.075802 790.739990,51.298378 
	C791.153442,53.006824 791.627747,55.049835 791.431335,57.026199 
	C790.744202,63.940956 789.446350,70.815865 789.138611,77.739601 
	C788.956055,81.846863 788.638489,85.533867 785.306519,88.449203 
	C784.911194,87.892136 784.845032,87.443352 784.778931,86.994576 
	C784.888611,86.251968 784.998352,85.509361 785.083008,84.391373 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M693.761230,18.053020 
	C694.874878,19.915016 695.635071,21.787340 696.689209,23.840652 
	C696.983154,24.021639 696.996765,24.000477 697.028320,24.290567 
	C697.334595,24.835871 697.609436,25.091084 697.884216,25.346300 
	C698.075134,25.827333 698.266113,26.308361 698.751709,27.252781 
	C701.623657,33.146255 704.200867,38.576336 706.778076,44.006416 
	C703.828918,39.500587 700.874634,34.998104 697.932251,30.487812 
	C695.633545,26.964302 693.349670,23.431110 690.786316,19.481712 
	C691.313049,19.196732 692.360413,18.630041 693.761230,18.053020 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M725.814697,145.183945 
	C726.514771,150.187119 727.226990,155.178650 727.889526,160.586151 
	C724.691650,156.620148 722.171204,152.167862 722.913940,146.322281 
	C723.366272,142.761902 722.728943,139.063110 723.135742,135.442856 
	C724.089417,136.989731 724.488525,138.520233 724.887634,140.050735 
	C724.908752,140.496902 724.929871,140.943085 724.983765,142.050018 
	C725.295776,143.525574 725.575073,144.340378 725.854370,145.155182 
	C725.854309,145.155182 725.826782,145.172302 725.814697,145.183945 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M841.119629,48.839947 
	C840.389771,47.228851 839.976807,45.371342 839.314209,43.248600 
	C839.043213,42.540428 839.021973,42.097485 839.000671,40.918304 
	C838.055054,34.767433 837.109375,29.352802 836.163696,23.938171 
	C836.131348,23.501606 836.098999,23.065042 835.953064,22.318611 
	C835.824585,20.971342 835.809692,19.933937 835.734253,18.509346 
	C836.505249,18.082554 837.336914,18.042950 838.584229,18.002039 
	C839.871704,26.760798 840.770142,35.518372 841.588196,44.283447 
	C841.720703,45.703064 841.496094,47.155998 841.119629,48.839947 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M712.860229,77.370804 
	C712.842163,76.842285 712.905640,76.678764 713.062988,76.269440 
	C714.486694,80.028641 716.328857,83.945808 717.033875,88.057945 
	C718.293396,95.404137 718.866943,102.867950 719.477417,110.580841 
	C719.227539,110.879097 719.023682,110.986534 718.983398,110.625504 
	C718.335327,107.491371 717.727539,104.718285 717.119751,101.945190 
	C717.056641,100.880447 716.993591,99.815697 716.875977,98.093979 
	C716.582581,97.192650 716.343628,96.948303 716.104675,96.703949 
	C716.104675,96.703941 716.118469,96.324753 716.069519,95.739319 
	C715.035583,90.092857 714.050598,85.031822 713.065552,79.970779 
	C713.024353,79.225784 712.983093,78.480789 712.860229,77.370804 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M713.140320,73.838554 
	C711.240845,65.986229 709.650513,57.897087 708.130737,49.398582 
	C709.117432,51.093155 710.474365,53.118671 710.865356,55.316055 
	C711.942993,61.372814 712.616272,67.501503 713.140320,73.838554 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M706.880188,44.365440 
	C704.200867,38.576336 701.623657,33.146255 698.991211,27.369770 
	C704.699463,29.453880 709.666992,39.611446 708.181030,46.804646 
	C707.878479,47.063221 707.505798,47.000301 707.318359,46.976669 
	C707.081421,46.210182 707.031860,45.467323 706.880188,44.365440 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M848.035278,95.694206 
	C848.204163,97.170471 848.248718,98.346146 848.515991,99.795242 
	C848.846375,101.118439 848.953918,102.168236 849.078003,104.008072 
	C849.409485,107.527672 849.724487,110.257225 850.039429,112.986778 
	C847.685608,110.878273 844.874695,96.902290 846.833801,94.106201 
	C847.415955,94.058739 847.816711,94.104324 847.816711,94.104324 
	C847.848145,94.534088 847.879578,94.963860 848.035278,95.694206 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M823.686035,175.036438 
	C822.236328,167.382385 821.118042,159.609146 820.070312,151.414307 
	C820.588562,151.190964 821.401917,151.346420 821.430847,151.593643 
	C822.340088,159.362671 823.172852,167.140640 823.686035,175.036438 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M768.450439,163.402054 
	C769.304932,166.894394 769.940063,170.715454 770.575195,174.536514 
	C770.873901,174.524765 771.172668,174.513031 771.471375,174.501297 
	C771.749512,172.823715 772.027649,171.146118 772.579102,169.203857 
	C772.762085,171.364670 772.671753,173.790176 772.581360,176.215668 
	C771.390076,175.788422 769.253479,175.506287 769.161987,174.910202 
	C768.595886,171.222977 768.489807,167.465149 768.450439,163.402054 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M835.852661,24.187416 
	C837.109375,29.352802 838.055054,34.767433 838.913086,40.595871 
	C838.243286,40.511837 837.218994,40.069691 837.148193,39.507450 
	C836.516968,34.495892 836.054138,29.463156 835.852661,24.187416 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M725.186646,139.809067 
	C724.488525,138.520233 724.089417,136.989731 723.459473,135.201187 
	C723.033203,133.125534 722.837708,131.307922 722.345215,128.822311 
	C722.012878,126.786415 721.977600,125.418518 721.992432,124.005722 
	C722.042542,123.960831 722.144714,123.873367 722.144714,123.873360 
	C723.073303,124.554619 724.680420,125.124046 724.814270,125.936302 
	C725.427673,129.658112 725.667297,133.445023 725.945923,137.215591 
	C726.002747,137.983978 725.649719,138.782669 725.186646,139.809067 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M751.856018,25.114571 
	C751.523865,28.523796 750.835938,31.890076 749.998413,35.617764 
	C749.305603,34.930866 748.240662,33.839619 748.322693,32.842751 
	C748.522705,30.412357 749.197754,28.010422 749.839050,25.639629 
	C749.924561,25.323641 750.924072,25.254869 751.856018,25.114571 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M850.241089,113.019897 
	C849.724487,110.257225 849.409485,107.527672 849.157959,104.396042 
	C853.386169,106.008438 853.797363,108.987442 851.151428,112.877151 
	C850.850769,113.079552 850.442810,113.053009 850.241089,113.019897 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M784.409119,87.061729 
	C784.845032,87.443352 784.911194,87.892136 785.045288,88.675156 
	C785.002502,89.426369 784.891846,89.843330 784.542175,90.882935 
	C784.171204,93.325974 784.039246,95.146347 783.907288,96.966728 
	C783.907288,96.966728 783.501648,97.012932 783.298340,97.031052 
	C781.552063,93.565575 780.684021,90.146225 784.409119,87.061729 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M806.474976,185.088181 
	C806.405518,188.794312 805.966980,192.598663 805.282959,196.708679 
	C805.393616,193.071686 805.749817,189.129028 806.474976,185.088181 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M720.792236,121.007019 
	C718.800903,118.727547 714.986450,116.997444 718.780884,113.013519 
	C719.155884,112.939445 719.546204,112.959084 719.738525,113.401794 
	C720.218018,116.232010 720.505127,118.619514 720.792236,121.007019 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M804.553589,199.184494 
	C804.242798,203.397507 803.640869,207.778152 802.930725,212.559982 
	C801.790161,208.264816 801.927429,203.692215 804.553589,199.184494 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M817.197571,131.828949 
	C816.502380,132.031326 816.083923,132.019806 815.351685,132.015564 
	C815.037842,130.158432 815.037842,128.293991 815.037842,126.429565 
	C815.622253,126.411682 816.206665,126.393799 816.791077,126.375923 
	C817.018860,128.122299 817.246582,129.868683 817.197571,131.828949 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M720.875854,121.362885 
	C720.505127,118.619514 720.218018,116.232010 719.931274,113.434624 
	C720.620972,114.565491 721.587708,116.048470 721.923950,117.662773 
	C722.280029,119.372635 722.036560,121.207390 722.099854,123.430481 
	C722.144714,123.873367 722.042542,123.960831 721.803284,123.954498 
	C721.564026,123.948174 721.175049,123.897736 721.175049,123.897736 
	C721.103210,123.171402 721.031311,122.445068 720.875854,121.362885 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M846.659302,87.832878 
	C846.913696,88.476524 846.957947,88.918159 847.015991,90.011246 
	C846.946655,91.113304 846.863525,91.563919 846.780334,92.014542 
	C846.241028,90.053680 845.701660,88.092812 845.492676,86.063728 
	C846.031738,86.540627 846.240479,87.085747 846.659302,87.832878 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M784.284790,96.912453 
	C784.039246,95.146347 784.171204,93.325974 784.547485,91.207565 
	C784.748657,92.892426 784.705444,94.875298 784.284790,96.912453 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M846.885864,92.333618 
	C846.863525,91.563919 846.946655,91.113304 847.119812,90.330070 
	C847.602966,91.229744 847.996216,92.462029 848.103027,93.899315 
	C847.816711,94.104324 847.415955,94.058739 847.217285,94.023758 
	C847.009521,93.543411 847.000427,93.098053 846.885864,92.333618 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M928.368408,18.103157 
	C928.967346,17.775530 929.934692,17.550255 930.951050,17.658829 
	C930.245605,18.063622 929.491211,18.134565 928.368408,18.103157 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M725.902222,144.815216 
	C725.575073,144.340378 725.295776,143.525574 725.087646,142.370300 
	C725.422546,142.844955 725.686279,143.660095 725.902222,144.815216 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M697.836914,25.063066 
	C697.609436,25.091084 697.334595,24.835871 697.018555,24.295460 
	C697.248108,24.266787 697.518921,24.523314 697.836914,25.063066 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M960.016418,92.091515 
	C961.860596,82.578720 963.654480,73.492416 965.702881,63.117256 
	C966.786865,64.919090 967.740295,65.861885 967.951111,66.948746 
	C972.617981,91.007370 977.210938,115.080353 981.780090,139.157791 
	C982.022522,140.435425 981.931824,141.776291 982.018677,143.545944 
	C971.579590,143.545944 961.377991,143.545944 950.412659,143.545944 
	C953.598267,126.530746 956.782166,109.524368 960.016418,92.091515 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1003.773560,31.177914 
	C1004.525269,31.273655 1005.047974,31.546579 1005.570618,31.819492 
	C1005.011047,32.211029 1004.451538,32.602577 1003.575439,33.001808 
	C1003.354187,32.458027 1003.449341,31.906561 1003.773560,31.177914 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1032.200195,177.280411 
	C1032.643555,177.559875 1033.046753,178.124802 1033.225952,178.844604 
	C1032.721191,178.521622 1032.440674,178.043762 1032.200195,177.280411 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M1007.204468,62.332924 
	C1007.436340,62.351799 1007.637390,62.665939 1007.846191,63.269806 
	C1007.627258,63.249084 1007.400452,62.938637 1007.204468,62.332924 
z"/>
<path fill="#F8FDF7" opacity="1.000000" stroke="none" 
	d="
M401.861542,99.797310 
	C404.163177,87.625320 406.397705,75.877991 408.632233,64.130653 
	C409.253235,64.126816 409.874268,64.122978 410.495270,64.119141 
	C415.524445,90.485886 420.553589,116.852623 425.665009,143.650726 
	C414.937042,143.650726 404.735931,143.650726 393.503662,143.650726 
	C396.305908,128.971939 399.050171,114.596947 401.861542,99.797310 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M446.872772,27.478302 
	C447.597809,26.490383 448.340942,25.911175 450.456116,24.262608 
	C449.034454,33.680325 456.430969,38.944637 456.313324,47.798618 
	C455.658203,97.095299 456.008118,146.405212 455.973602,195.710587 
	C455.971680,198.422318 456.810883,201.705307 452.358398,202.993057 
	C452.594849,200.004761 452.971863,197.444565 452.973999,194.884048 
	C453.010284,151.575287 453.052399,108.266266 452.870636,64.958244 
	C452.859650,62.340672 450.984619,59.730915 449.625061,57.056892 
	C449.157715,56.254494 449.042236,55.512726 449.214844,54.184044 
	C450.456177,52.367657 451.409424,51.138180 452.606201,49.594593 
	C450.329987,44.781643 445.999451,40.093327 447.873138,33.589241 
	C447.992554,33.174675 447.264069,32.515839 446.930054,31.970629 
	C446.916992,30.609421 446.903931,29.248219 446.872772,27.478302 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M446.682922,32.254162 
	C447.264069,32.515839 447.992554,33.174675 447.873138,33.589241 
	C445.999451,40.093327 450.329987,44.781643 452.606201,49.594593 
	C451.409424,51.138180 450.456177,52.367657 449.230347,53.807762 
	C447.181091,46.968052 443.750641,40.111870 446.682922,32.254162 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M364.835815,77.784515 
	C363.924683,77.198456 362.335602,76.058243 362.516113,75.413429 
	C363.619110,71.473579 366.064270,67.804306 363.404602,63.412209 
	C363.168671,63.022572 366.423065,60.519325 368.425201,58.995232 
	C367.521240,65.148262 366.248505,71.303802 364.835815,77.784515 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M432.895416,184.602829 
	C427.682739,182.656540 429.268799,177.978333 428.996704,173.505325 
	C429.714996,174.050598 430.756348,174.937454 431.086761,176.037796 
	C431.888245,178.706680 432.358917,181.474915 432.895416,184.602829 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M434.863708,194.619171 
	C430.349762,193.605087 432.178284,190.302750 432.806763,187.203461 
	C433.713440,189.379379 434.321716,191.806564 434.863708,194.619171 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M394.632385,172.932556 
	C394.603821,173.897324 394.205841,174.763931 393.807861,175.630539 
	C393.229309,174.992447 392.650757,174.354355 392.035522,173.379852 
	C392.753540,172.973770 393.508270,172.904099 394.632385,172.932556 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M372.821655,35.733501 
	C372.425720,35.820835 372.315247,35.581833 372.557495,35.153381 
	C372.674377,35.052902 372.871246,35.496136 372.821655,35.733501 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M360.300720,98.043289 
	C360.074219,97.834488 360.166870,97.561920 360.532959,97.186783 
	C360.808044,97.382347 360.745911,97.680779 360.300720,98.043289 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M629.110962,177.499359 
	C629.923706,179.820297 630.296753,182.765900 631.509583,185.311386 
	C632.270081,186.907639 634.292786,187.902527 636.261963,189.612488 
	C636.874573,187.104141 637.215149,185.709717 637.532166,184.411789 
	C638.118652,184.312149 638.666809,184.117630 638.731873,184.222534 
	C641.843445,189.243088 646.835938,187.971207 651.395508,187.986038 
	C664.221252,188.027771 677.047363,188.034470 689.873047,187.987579 
	C697.245239,187.960617 704.659973,188.663879 711.799255,185.696091 
	C713.956177,184.799484 716.620300,185.123184 718.300232,184.960648 
	C719.223877,190.087219 720.119263,194.617035 720.784424,199.180435 
	C720.895935,199.945114 720.009094,200.855316 719.260864,201.860779 
	C717.788574,197.075058 716.638977,192.126862 715.356567,186.607025 
	C704.703735,193.804184 692.812805,190.283844 681.423584,190.660431 
	C665.798096,191.177078 650.142273,190.774094 634.499451,190.765427 
	C632.879089,190.764526 631.258789,190.765305 629.026306,190.765305 
	C629.026306,186.165802 629.026306,182.060333 629.110962,177.499359 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M629.128174,97.438576 
	C631.394958,101.987450 635.839417,100.989906 639.879150,100.992981 
	C658.308289,101.007019 676.737488,100.996536 695.166626,101.000679 
	C701.427551,101.002090 702.244873,101.681664 703.007080,107.821182 
	C703.283875,110.050850 703.333923,112.308670 703.183716,114.803734 
	C702.684265,114.791229 702.374329,114.554039 702.309448,114.262337 
	C700.046143,104.087822 700.049438,104.086769 689.425354,104.085335 
	C671.141968,104.082855 652.858582,104.080811 634.575195,104.078636 
	C632.940918,104.078438 631.306702,104.078606 629.044739,104.078606 
	C629.044739,101.734726 629.044739,99.808044 629.128174,97.438576 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M692.001221,55.583595 
	C690.669067,56.535637 689.338135,57.071281 689.396790,57.047672 
	C685.426819,53.705147 681.909851,50.744019 678.214966,47.498901 
	C682.171265,49.019745 686.305359,50.824577 690.794006,52.784168 
	C690.495483,50.295914 690.226562,48.054344 689.983887,45.406471 
	C690.995850,46.669765 692.440796,48.240215 692.829163,50.038788 
	C693.169556,51.615257 692.324036,53.447788 692.001221,55.583595 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M705.157959,129.362793 
	C707.261780,129.475601 710.695679,127.664665 710.465271,131.956146 
	C710.224060,136.447678 707.883728,138.455612 703.784241,137.323776 
	C700.905396,136.528976 698.246399,134.938324 695.267212,133.451782 
	C698.534668,133.766968 702.024170,134.333420 705.933716,134.968063 
	C705.687988,133.434418 705.391541,131.584457 705.157959,129.362793 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M690.494385,133.121338 
	C690.362610,133.462555 689.772095,133.942795 689.182800,133.941147 
	C672.019104,133.893585 654.855469,133.797928 637.372681,133.455276 
	C654.714417,133.145660 672.375244,133.090729 690.494385,133.121338 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M703.076416,117.396233 
	C708.035278,118.677963 705.634766,122.411835 705.234924,125.763809 
	C704.296326,123.284103 703.654724,120.539879 703.076416,117.396233 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M719.125732,204.393539 
	C719.969788,206.847504 720.739868,209.698853 721.220459,212.790359 
	C720.304626,210.283981 719.678162,207.537460 719.125732,204.393539 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M631.649658,47.139137 
	C631.293213,47.806980 630.581299,48.402992 629.548157,49.004406 
	C629.916016,48.362309 630.605103,47.714809 631.649658,47.139137 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M630.673828,133.144424 
	C630.595520,133.777618 630.185547,134.348709 629.491089,134.962433 
	C629.585144,134.364136 629.963745,133.723221 630.673828,133.144424 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M687.956238,37.312317 
	C688.433716,37.578938 688.915649,38.140820 689.154785,38.870079 
	C688.594910,38.560829 688.277771,38.084206 687.956238,37.312317 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M689.140015,42.362083 
	C689.440796,42.615631 689.682312,43.193874 689.889648,44.104240 
	C689.597229,43.853168 689.338989,43.269974 689.140015,42.362083 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M629.137146,137.260834 
	C629.571045,137.203690 629.663025,137.460220 629.339478,137.883316 
	C629.201843,137.973969 629.073120,137.498611 629.137146,137.260834 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M629.138550,141.261261 
	C629.570435,141.201126 629.664246,141.456558 629.345825,141.881104 
	C629.209351,141.972519 629.076294,141.499115 629.138550,141.261261 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M633.739014,133.137482 
	C633.976685,133.201859 633.648560,133.547836 633.648560,133.547836 
	C633.648560,133.547836 633.206970,133.476456 633.116455,133.338806 
	C633.025879,133.201141 633.501404,133.073120 633.739014,133.137482 
z"/>
<path fill="#59B854" opacity="1.000000" stroke="none" 
	d="
M539.479675,219.449280 
	C538.225403,219.391891 537.433350,219.263596 536.277100,218.738464 
	C535.930298,217.867432 535.947693,217.393234 536.251038,216.735962 
	C537.330444,214.362305 538.124023,212.171722 538.917542,209.981155 
	C538.917542,209.981155 539.121948,209.669586 539.366089,209.338226 
	C539.692749,208.669846 539.775391,208.332825 539.857971,207.995789 
	C539.999268,207.629807 540.140503,207.263824 540.278076,206.587982 
	C540.274292,206.278122 540.291687,206.261597 540.522949,206.131927 
	C540.835327,205.667709 540.916443,205.333160 541.100342,204.852234 
	C541.203064,204.705872 541.190002,204.348419 541.411377,204.181396 
	C541.728638,203.669144 541.824585,203.323898 541.920532,202.978668 
	C542.069824,202.679016 542.219177,202.379379 542.653564,201.588455 
	C543.237671,199.387527 543.536743,197.677887 543.835815,195.968262 
	C543.674500,180.904449 543.505432,165.840729 543.362000,150.776749 
	C543.344604,148.952026 543.465515,147.125992 543.680664,144.708771 
	C543.829529,143.088226 543.820068,142.059494 543.857300,140.746674 
	C543.702454,140.149307 543.500854,139.836014 543.299194,139.522736 
	C543.223022,139.184204 543.146851,138.845673 542.970398,137.937424 
	C541.969788,136.464523 541.069397,135.561356 539.968750,134.426666 
	C538.842041,133.879944 537.915588,133.564758 536.989136,133.249557 
	C536.241394,133.181168 535.493652,133.112778 533.913391,133.042572 
	C526.736755,133.097824 520.392578,133.154892 514.048462,133.211945 
	C513.271973,133.159515 512.495544,133.107086 510.890869,133.045532 
	C507.443329,133.036423 504.824005,133.036423 501.782074,133.036423 
	C501.888794,135.423325 501.968781,137.212112 502.048767,139.000916 
	C501.983826,139.447784 501.918915,139.894669 501.842896,141.038361 
	C501.902985,142.490463 501.974152,143.245758 502.045288,144.001068 
	C501.991425,161.695114 501.937561,179.389175 501.911194,197.924011 
	C504.413269,205.363800 506.887878,211.962799 509.515259,218.969208 
	C489.210510,218.969208 469.605408,218.969208 450.000305,218.969208 
	C449.987030,218.741837 449.941559,218.521088 449.849060,218.139923 
	C454.854309,208.528351 458.332306,199.009033 458.217133,187.773071 
	C457.718292,139.114822 458.062012,90.448341 457.855347,41.785885 
	C457.839172,37.977242 456.166809,34.099068 454.864929,30.393497 
	C453.503082,26.517277 451.658813,22.810551 449.605408,18.057432 
	C484.301300,18.057432 518.187988,18.057432 552.538574,17.983078 
	C553.440918,17.995388 553.879395,18.082050 554.953735,18.312792 
	C557.060730,18.567215 558.531921,18.677557 560.003113,18.787899 
	C560.889160,18.994120 561.775208,19.200340 562.995789,19.411121 
	C568.176453,21.260475 572.869629,23.276091 574.394653,29.371754 
	C574.639526,29.640125 574.646179,29.870296 574.736694,29.940933 
	C578.725647,37.407997 578.003845,45.425659 577.995605,53.356556 
	C577.978088,70.168526 577.807800,86.984138 578.126587,103.790176 
	C578.214783,108.441147 576.656006,110.966263 572.429443,112.238426 
	C568.645630,113.377342 564.879089,114.573799 561.338013,115.672539 
	C563.812195,116.443810 566.727600,117.352623 570.204956,118.514748 
	C571.194519,118.926025 571.622192,119.083992 572.049866,119.241959 
	C572.049866,119.241951 572.226562,119.315521 572.480347,119.524971 
	C573.131836,119.904564 573.529602,120.074715 573.927307,120.244865 
	C575.287109,122.251282 576.646912,124.257690 578.001404,127.094276 
	C578.056213,150.617889 578.116333,173.311340 578.176392,196.004791 
	C578.130432,196.454620 578.084412,196.904449 578.046692,197.925949 
	C578.055054,198.497620 578.150452,198.982956 578.150452,198.982956 
	C578.039124,199.326859 577.927795,199.670746 577.595581,200.452988 
	C577.288879,201.255356 577.203064,201.619370 577.117188,201.983368 
	C577.117249,201.983383 576.906982,202.335617 576.691772,202.678955 
	C576.381897,203.338821 576.287170,203.655365 576.192505,203.971924 
	C575.742554,204.931839 575.292542,205.891739 574.592285,207.360138 
	C574.253479,208.243546 574.164978,208.618423 574.076477,208.993317 
	C573.656799,209.944962 573.237061,210.896606 572.569946,212.351151 
	C572.215820,213.235886 572.109131,213.617722 572.002441,213.999573 
	C572.002441,213.999573 571.796753,214.248596 571.593811,214.585785 
	C571.320679,215.276627 571.250549,215.630280 571.180420,215.983917 
	C571.180359,215.983917 570.955200,216.359253 570.662048,216.717072 
	C570.266418,217.447845 570.163940,217.820816 569.910522,218.311508 
	C569.553223,218.632248 569.502930,218.868484 569.608643,219.137924 
	C559.719727,219.218079 549.830811,219.298233 539.479675,219.449280 
M540.554810,48.766888 
	C537.365601,48.180038 534.186523,47.162239 530.985291,47.087185 
	C521.399109,46.862431 511.804291,47.006340 502.093475,47.006340 
	C502.093475,66.295013 502.093475,84.841873 502.093475,104.011993 
	C512.686523,104.011993 522.959534,104.019363 533.232544,104.009506 
	C540.622986,104.002419 543.936096,100.822815 543.976257,93.481941 
	C544.040833,81.666199 544.177734,69.845154 543.871399,58.037056 
	C543.794617,55.076801 542.146362,52.157318 540.554810,48.766888 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M658.088013,269.521912 
	C656.763977,266.849213 654.345764,263.842987 654.188660,260.722809 
	C653.679138,250.608368 653.903198,240.450729 654.055847,230.312881 
	C654.103455,227.154037 653.238220,225.814606 649.862183,225.939362 
	C643.885986,226.160141 637.891541,226.135849 631.913147,225.948029 
	C628.791138,225.849945 627.916321,227.078445 627.951965,230.037262 
	C628.078247,240.508331 627.954590,250.982300 628.050232,261.454010 
	C628.064880,263.051178 628.921021,264.627563 629.050842,266.240662 
	C629.167419,267.690979 628.820801,269.178619 628.368713,270.820374 
	C627.403931,268.268066 626.227783,265.553619 626.183167,262.820770 
	C625.979675,250.343201 626.093872,237.860458 626.093872,224.353180 
	C631.647522,224.353180 637.045654,224.353714 642.443726,224.353073 
	C655.764404,224.351501 655.752197,224.351303 655.769165,237.542740 
	C655.778625,244.873428 655.628174,252.213776 655.968628,259.530273 
	C656.118774,262.757233 657.310364,265.935730 658.088013,269.521912 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M737.600830,282.534363 
	C737.249329,282.507996 737.148499,282.285522 737.219666,282.185822 
	C746.019287,269.859924 743.914124,255.699921 743.998596,241.911652 
	C744.023071,237.917694 743.908203,233.920502 744.033691,229.930344 
	C744.115601,227.327972 743.494568,225.884262 740.471558,225.958191 
	C734.151306,226.112762 727.823425,226.075867 721.501221,225.969498 
	C718.943542,225.926468 717.940063,226.859268 717.971619,229.458740 
	C718.070618,237.612213 717.967346,245.768082 718.056091,253.921783 
	C718.067566,254.975891 719.078735,256.037506 719.032776,257.069214 
	C718.975342,258.359283 718.303162,259.622009 717.546387,260.953796 
	C717.196289,249.047424 717.196289,237.083817 717.196289,224.665436 
	C726.827026,224.665436 736.202881,224.665436 746.612610,224.665436 
	C745.993713,240.442505 745.395874,255.683868 744.729248,271.330658 
	C742.417603,275.289246 740.174683,278.842407 737.600830,282.534363 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M578.558533,195.897064 
	C578.116333,173.311340 578.056213,150.617889 578.074646,127.461639 
	C578.767700,128.160019 579.912170,129.318466 579.918152,130.482758 
	C580.025574,151.389893 580.007812,172.297760 579.950012,193.205292 
	C579.947571,194.067566 579.291992,194.928024 578.558533,195.897064 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M714.478271,269.578369 
	C714.555054,274.180237 710.969055,278.829895 705.116333,279.649628 
	C695.831299,280.950134 686.410156,281.313568 677.035645,281.897247 
	C675.872742,281.969635 674.643005,280.966827 673.273376,280.101685 
	C682.133850,280.765656 691.123779,281.409607 700.114441,279.109467 
	C705.968323,277.611816 710.774719,274.815430 714.478271,269.578369 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M660.012817,276.002899 
	C659.353699,274.208130 658.694580,272.413391 658.071655,270.243317 
	C660.385498,272.012787 662.663025,274.157593 665.132202,276.584961 
	C664.169373,276.908844 663.015015,276.950134 661.321838,276.774231 
	C660.586609,276.288513 660.329895,276.103790 660.012817,276.002899 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M628.817627,273.445984 
	C633.320740,272.699463 631.647461,276.121399 631.948303,278.755737 
	C630.901123,277.396942 629.881714,275.598175 628.817627,273.445984 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M716.789673,263.270203 
	C718.268433,265.384308 719.396851,267.734222 715.264771,268.983124 
	C715.428955,267.169037 715.993042,265.350891 716.789673,263.270203 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M672.947510,279.529846 
	C671.238525,279.324554 669.304260,278.839478 667.230103,278.001404 
	C668.967590,278.182281 670.844910,278.716187 672.947510,279.529846 
z"/>
<path fill="#E9FAE7" opacity="1.000000" stroke="none" 
	d="
M570.061523,218.193787 
	C570.163940,217.820816 570.266418,217.447845 570.634644,216.934204 
	C570.933411,217.283859 570.966370,217.774185 570.999634,218.632095 
	C571.000000,218.999664 570.530151,219.001678 570.289307,218.799240 
	C570.048462,218.596802 570.061462,218.193771 570.061523,218.193787 
z"/>
<path fill="#E9FAE7" opacity="1.000000" stroke="none" 
	d="
M572.253174,213.821503 
	C572.109131,213.617722 572.215820,213.235886 572.562988,212.664780 
	C572.703613,212.864822 572.603821,213.254120 572.253174,213.821503 
z"/>
<path fill="#E9FAE7" opacity="1.000000" stroke="none" 
	d="
M571.394348,215.808868 
	C571.250549,215.630280 571.320679,215.276627 571.610291,214.746429 
	C571.755920,214.924545 571.682129,215.279190 571.394348,215.808868 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M573.777405,119.937401 
	C573.529602,120.074715 573.131836,119.904564 572.550476,119.459915 
	C572.787048,119.333580 573.207275,119.481758 573.777405,119.937401 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M571.865662,118.939957 
	C571.622192,119.083992 571.194519,118.926025 570.571167,118.477455 
	C570.810852,118.337227 571.246155,118.487587 571.865662,118.939957 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M578.297302,198.897125 
	C578.150452,198.982956 578.055054,198.497620 578.120483,198.259155 
	C578.326416,198.185699 578.485840,198.339447 578.594788,198.523132 
	C578.623901,198.572159 578.498535,198.712753 578.297302,198.897125 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M577.337708,201.807037 
	C577.203064,201.619370 577.288879,201.255356 577.612305,200.724945 
	C577.752686,200.915939 577.655457,201.273315 577.337708,201.807037 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M576.393799,203.823990 
	C576.287170,203.655365 576.381897,203.338821 576.693848,202.884048 
	C576.805786,203.055908 576.700439,203.365982 576.393799,203.823990 
z"/>
<path fill="#E9FAE7" opacity="1.000000" stroke="none" 
	d="
M574.311768,208.811783 
	C574.164978,208.618423 574.253479,208.243546 574.572632,207.680099 
	C574.717896,207.871109 574.632507,208.250687 574.311768,208.811783 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M468.112366,265.621368 
	C467.632050,266.066345 467.374481,266.246826 467.089355,266.748688 
	C466.428894,267.103424 465.867706,267.239075 465.138733,267.614258 
	C464.250824,267.782288 463.530701,267.710754 462.242950,267.442688 
	C461.128326,267.145935 460.581268,267.045746 460.034241,266.945557 
	C460.034241,266.945557 460.052399,266.998077 459.750000,266.683533 
	C456.964874,266.230560 454.482208,265.973450 451.999329,265.971283 
	C410.009247,265.934631 368.019104,265.929688 326.028992,265.917725 
	C326.028992,265.917755 326.034119,265.970001 326.055145,265.952301 
	C326.049072,263.924316 326.021973,261.914001 326.046448,259.475281 
	C369.416992,259.008453 412.735962,258.970093 456.879333,258.908936 
	C460.456604,257.946381 463.209564,257.006622 465.962494,256.066864 
	C466.883667,256.902679 467.804840,257.738495 468.874451,258.785126 
	C468.769745,261.082153 468.516602,263.168396 468.112366,265.621368 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M465.978180,255.800140 
	C463.209564,257.006622 460.456604,257.946381 457.340393,258.829956 
	C459.072266,257.185333 461.167389,255.596817 463.889587,254.110291 
	C465.012512,254.474518 465.508301,254.736801 466.004120,254.999100 
	C466.000702,255.177200 465.997284,255.355301 465.978180,255.800140 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M323.400421,248.990662 
	C323.781525,246.697632 324.552124,244.406052 325.687561,242.069489 
	C326.035645,244.044144 326.018951,246.063797 326.064606,248.511536 
	C325.347931,248.957123 324.568939,248.974625 323.400421,248.990662 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M466.263977,254.793518 
	C465.508301,254.736801 465.012512,254.474518 464.257385,254.103531 
	C464.245605,253.200272 464.493134,252.405716 465.085693,251.213104 
	C465.706879,250.624237 465.894531,250.368500 465.993652,250.047806 
	C465.993652,250.047806 466.204498,249.818893 466.774658,249.874573 
	C467.897339,249.954941 468.449890,249.979614 469.002441,250.004272 
	C468.176239,251.532166 467.350037,253.060059 466.263977,254.793518 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M457.072021,249.495422 
	C458.734009,249.428497 460.477997,249.709213 462.591339,250.100800 
	C461.025146,250.088791 459.089569,249.965912 457.072021,249.495422 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M465.733765,250.049316 
	C465.894531,250.368500 465.706879,250.624237 465.214111,250.923126 
	C464.997559,251.031235 464.981232,250.624985 464.981567,250.421722 
	C465.145905,250.162582 465.309906,250.106705 465.733765,250.049316 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M450.083008,219.365845 
	C469.605408,218.969208 489.210510,218.969208 509.515259,218.969208 
	C506.887878,211.962799 504.413269,205.363800 502.021057,198.381622 
	C507.340485,203.238754 510.291473,209.763901 512.225403,216.746826 
	C512.481506,217.671616 510.863220,219.115524 510.057129,220.660736 
	C496.924164,221.002029 483.847107,221.001450 470.770050,221.001297 
	C465.385406,221.001236 459.998383,221.089935 454.617615,220.945679 
	C453.124146,220.905670 451.649139,220.175842 450.083008,219.365845 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M469.273376,249.776520 
	C468.449890,249.979614 467.897339,249.954941 466.930237,249.876862 
	C467.379730,247.491318 468.243774,245.159164 469.157776,242.425659 
	C472.633698,244.384262 470.235779,247.004669 469.273376,249.776520 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M469.089752,240.776672 
	C468.145233,240.454559 467.341858,239.810623 466.275879,238.692169 
	C466.008484,237.813171 466.003632,237.408676 465.998779,237.004181 
	C469.361786,236.219086 470.091095,236.997726 469.089752,240.776672 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M465.624634,237.004150 
	C466.003632,237.408676 466.008484,237.813171 466.014130,238.520844 
	C464.617645,238.858795 463.220367,238.893585 461.402710,238.904510 
	C460.982330,238.880646 460.959290,238.445786 461.259399,238.200623 
	C462.041473,237.638916 462.523438,237.322357 463.005402,237.005798 
	C463.753754,237.005234 464.502106,237.004684 465.624634,237.004150 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M462.679352,236.975540 
	C462.523438,237.322357 462.041473,237.638916 461.256378,237.982910 
	C460.495483,237.543182 460.037689,237.076004 459.579926,236.608826 
	C460.504395,236.720978 461.428833,236.833130 462.679352,236.975540 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M468.449951,254.532288 
	C468.417084,254.477127 468.482849,254.587448 468.449951,254.532288 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M513.467529,225.450165 
	C513.522888,225.417038 513.412109,225.483276 513.467529,225.450165 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M460.022217,267.331512 
	C460.581268,267.045746 461.128326,267.145935 461.880585,267.437805 
	C462.082062,269.173035 462.078339,270.716492 462.139252,272.717834 
	C462.203888,273.175659 462.180725,273.142548 461.948822,273.156494 
	C461.403046,273.272888 461.174103,273.472656 461.030121,273.769714 
	C460.539398,274.110718 460.048676,274.451752 458.944458,274.887787 
	C457.886871,274.977448 457.442749,274.972076 456.998657,274.966705 
	C458.002533,272.550293 459.006378,270.133881 460.022217,267.331512 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M459.095215,283.561829 
	C455.849670,283.794586 452.257629,283.746521 448.351746,283.441650 
	C451.608185,283.216888 455.178467,283.248962 459.095215,283.561829 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M449.134033,275.862305 
	C449.868774,275.510040 450.864807,275.251099 451.998596,275.383118 
	C451.222656,275.834595 450.309021,275.895111 449.134033,275.862305 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M326.008545,274.008453 
	C326.641846,274.400208 327.275177,274.791931 327.962311,275.482117 
	C327.588989,275.868500 327.161865,275.956451 326.407043,276.063080 
	C326.079407,276.081787 325.974518,275.975067 325.966675,275.614746 
	C325.975372,274.839111 325.991974,274.423767 326.008545,274.008453 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M465.004883,273.332611 
	C465.425201,273.225586 466.036499,273.368439 466.816833,273.787048 
	C466.389221,273.902710 465.792511,273.742615 465.004883,273.332611 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M456.988281,275.167023 
	C457.442749,274.972076 457.886871,274.977448 458.663940,274.990356 
	C458.521698,275.299805 458.046417,275.601685 457.273132,275.836029 
	C456.975128,275.768494 456.977905,275.367340 456.988281,275.167023 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M468.601624,282.697205 
	C468.660889,282.918121 468.448883,283.310913 467.900696,283.795593 
	C467.819794,283.548035 468.075073,283.208557 468.601624,282.697205 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M461.275513,273.787109 
	C461.174103,273.472656 461.403046,273.272888 461.951599,273.155243 
	C462.102905,273.500183 461.881104,273.721649 461.275513,273.787109 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M453.009674,267.026550 
	C451.709930,267.684235 450.411804,268.912781 449.110260,268.916443 
	C412.308167,269.019562 375.505707,269.010223 338.703430,268.949432 
	C337.529633,268.947479 336.357086,268.210815 335.556580,267.414185 
	C374.956055,267.016663 413.982849,267.021606 453.009674,267.026550 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M453.449432,267.020325 
	C413.982849,267.021606 374.956055,267.016663 335.459473,267.020447 
	C333.606110,267.025238 332.222534,267.021332 330.087646,267.014526 
	C328.888336,267.015747 328.440338,267.019897 327.992340,267.024078 
	C327.433502,266.833496 326.874634,266.642883 326.172394,266.184998 
	C368.019104,265.929688 410.009247,265.934631 451.999329,265.971283 
	C454.482208,265.973450 456.964874,266.230560 459.758362,266.656921 
	C458.009094,266.967926 455.949158,266.990997 453.449432,267.020325 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M325.734802,273.909119 
	C325.991974,274.423767 325.975372,274.839111 325.914001,275.561584 
	C325.454620,275.907990 325.040009,275.947174 324.312073,275.992554 
	C324.486206,275.269104 324.973633,274.539459 325.734802,273.909119 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M328.034668,267.319122 
	C328.440338,267.019897 328.888336,267.015747 329.672333,267.019287 
	C329.770660,267.537781 329.532959,268.048615 329.295288,268.559448 
	C328.889191,268.244354 328.483063,267.929260 328.034668,267.319122 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M457.467590,285.449219 
	C457.523163,285.415955 457.412018,285.482452 457.467590,285.449219 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M689.117615,26.993608 
	C689.045349,26.213589 689.135437,25.694048 689.225586,25.174511 
	C689.769043,25.601091 690.312561,26.027670 690.856018,26.454250 
	C690.330688,26.720865 689.805298,26.987478 689.117615,26.993608 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M697.950867,17.656826 
	C697.246216,18.051546 696.492249,18.113247 695.369019,18.080828 
	C695.967041,17.765741 696.934326,17.544773 697.950867,17.656826 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M738.951294,17.668137 
	C738.245728,18.063477 737.491394,18.124653 736.368469,18.088326 
	C736.967407,17.771872 737.935059,17.552921 738.951294,17.668137 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M759.271118,96.734444 
	C758.025146,91.939774 757.072815,86.869926 756.176880,81.398247 
	C759.567139,85.671638 760.694458,90.822311 759.271118,96.734444 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M756.197388,76.776161 
	C755.257263,73.319824 754.630432,69.588890 754.032837,65.428833 
	C755.041443,66.735016 756.491211,68.373932 756.871826,70.231880 
	C757.277893,72.213921 756.673706,74.402916 756.197388,76.776161 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M753.822449,63.318710 
	C753.601440,63.291267 753.381409,62.967739 753.195129,62.340858 
	C753.427063,62.365883 753.625244,62.694260 753.822449,63.318710 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M346.532288,144.550003 
	C346.476959,144.582977 346.587585,144.517029 346.532288,144.550003 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M332.373535,222.998840 
	C333.080750,222.334229 334.163940,221.669083 335.622955,221.004639 
	C334.915680,221.669678 333.832611,222.334000 332.373535,222.998840 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M791.530823,40.208336 
	C791.732117,41.514896 791.646790,42.998405 791.308228,44.752636 
	C791.118103,43.477333 791.181152,41.931309 791.530823,40.208336 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M788.390320,34.464836 
	C787.941589,34.349056 787.666443,34.066475 787.412659,33.765881 
	C787.406128,33.758144 787.632812,33.553524 787.750732,33.440742 
	C788.022766,33.725719 788.294739,34.010685 788.390320,34.464836 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M844.045410,26.395033 
	C844.286926,26.790836 844.285217,27.184551 844.255005,27.576069 
	C844.254211,27.587067 843.949036,27.574604 843.785767,27.573080 
	C843.789917,27.179600 843.794067,26.786119 844.045410,26.395033 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M776.629150,129.004852 
	C776.307495,133.771484 775.847351,138.590240 774.856750,143.297363 
	C774.129089,146.754852 772.706543,150.066132 771.473022,153.808884 
	C770.072266,152.756516 768.963623,151.923569 767.527100,151.043243 
	C767.090942,150.566284 766.982605,150.136734 766.896423,149.374420 
	C767.278625,147.996429 767.981750,146.937515 767.940735,145.908249 
	C767.739502,140.857544 767.340393,135.814697 767.005615,130.384842 
	C770.087708,129.670105 773.175415,129.339600 776.629150,129.004852 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M832.328369,18.076319 
	C832.235168,17.469860 832.470642,16.955484 832.706055,16.441090 
	C833.112488,16.760241 833.518921,17.079409 833.962646,17.696423 
	C833.552368,18.052313 833.104736,18.110357 832.328369,18.076319 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M873.535339,53.390404 
	C873.651184,52.941643 873.936096,52.669338 874.221069,52.397041 
	C874.333801,52.515026 874.446533,52.633003 874.559265,52.750969 
	C874.274353,53.022926 873.989380,53.294895 873.535339,53.390404 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M873.467529,49.450226 
	C873.522888,49.417202 873.412231,49.483250 873.467529,49.450226 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M559.920288,18.448769 
	C558.531921,18.677557 557.060730,18.567215 555.286133,18.251488 
	C556.601013,18.067282 558.219238,18.088459 559.920288,18.448769 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M786.471069,73.073540 
	C786.760925,74.177734 786.716003,75.357735 786.450562,76.816650 
	C786.198730,75.780159 786.167542,74.464752 786.471069,73.073540 
z"/>
<path fill="#FAFDF9" opacity="1.000000" stroke="none" 
	d="
M806.001404,268.992920 
	C816.984680,268.995697 827.468384,268.966431 837.951477,269.032135 
	C839.746094,269.043427 841.576904,269.300476 843.323364,269.723419 
	C846.889404,270.586884 848.621338,272.895844 848.315308,276.649750 
	C848.009338,280.404480 845.742004,282.405304 842.281860,282.873444 
	C839.167053,283.294891 835.981567,283.291809 832.826660,283.303009 
	C819.847229,283.349030 806.864197,283.161835 793.890015,283.428558 
	C789.631775,283.516144 788.232300,282.090973 788.623962,278.018341 
	C788.889648,275.255371 788.674683,272.446136 788.674683,268.992889 
	C794.619934,268.992889 800.060730,268.992889 806.001404,268.992920 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M818.000000,240.867401 
	C824.812805,240.914948 831.125610,240.971954 837.438477,241.006577 
	C841.147644,241.026917 844.263245,242.287460 844.579590,246.322922 
	C844.876587,250.112549 842.163086,251.851501 838.659241,252.422470 
	C838.007812,252.528595 837.374695,252.888245 836.732666,252.889328 
	C820.981018,252.916168 805.229248,252.910324 789.061401,252.910324 
	C789.061401,248.879211 789.061401,245.199966 789.061401,240.867447 
	C798.654053,240.867447 808.077026,240.867447 818.000000,240.867401 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M535.965149,216.919052 
	C535.947693,217.393234 535.930298,217.867432 535.875732,218.695831 
	C535.396606,219.307343 534.954712,219.564621 534.281372,219.932800 
	C532.420288,218.837509 530.790588,217.631332 528.783081,216.145508 
	C531.321777,214.950287 533.305237,214.016495 535.650818,213.436798 
	C535.997070,214.833603 535.981140,215.876328 535.965149,216.919052 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M514.369751,133.463074 
	C520.392578,133.154892 526.736755,133.097824 533.543396,133.122986 
	C533.488159,134.691742 532.970337,136.178284 532.873291,136.456955 
	C529.874268,135.523453 527.479370,134.413818 524.982605,134.089737 
	C521.592957,133.649750 518.125244,133.810898 514.369751,133.463074 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M502.399658,138.937561 
	C501.968781,137.212112 501.888794,135.423325 501.782074,133.036423 
	C504.824005,133.036423 507.443329,133.036423 510.507599,133.122803 
	C507.232269,133.669586 504.260834,135.214142 502.399658,138.937561 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M536.251038,216.735962 
	C535.981140,215.876328 535.997070,214.833603 536.015686,213.399506 
	C536.771057,212.031143 537.523682,211.054184 538.596924,210.029190 
	C538.124023,212.171722 537.330444,214.362305 536.251038,216.735962 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M536.992188,133.622864 
	C537.915588,133.564758 538.842041,133.879944 539.919067,134.814178 
	C540.055664,135.952255 540.041565,136.471298 540.027527,136.990326 
	C539.016785,135.992264 538.005981,134.994202 536.992188,133.622864 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M536.112915,199.504623 
	C536.277100,200.683304 536.112732,201.803345 535.948364,202.923386 
	C535.367432,202.722549 534.786499,202.521713 534.205566,202.320877 
	C534.731812,201.362579 535.258118,200.404282 536.112915,199.504623 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M541.190002,204.348419 
	C541.190002,204.348419 541.203064,204.705872 540.869385,204.986816 
	C540.454346,205.599045 540.373047,205.930328 540.291687,206.261597 
	C540.291687,206.261597 540.274292,206.278122 540.281250,206.292618 
	C539.578369,205.575287 538.868408,204.843460 538.158508,204.111649 
	C538.961975,203.563980 539.765503,203.016312 540.806335,203.061096 
	C541.092529,203.885178 541.141296,204.116806 541.190002,204.348419 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M543.810547,141.030746 
	C543.820068,142.059494 543.829529,143.088226 543.622559,144.279053 
	C542.996521,143.348694 542.586853,142.256287 542.774414,141.093582 
	C543.371582,141.023285 543.810608,141.030746 543.810547,141.030746 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M540.214478,137.245697 
	C540.041565,136.471298 540.055664,135.952255 540.119385,135.045715 
	C541.069397,135.561356 541.969788,136.464523 542.855469,137.702271 
	C542.027649,137.858231 541.214539,137.679657 540.214478,137.245697 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M536.717041,142.450974 
	C537.577087,142.721573 538.191284,143.198532 538.805420,143.675507 
	C538.510681,143.980942 538.215942,144.286362 537.921204,144.591797 
	C537.437866,143.946976 536.954468,143.302155 536.717041,142.450974 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M543.618774,195.970001 
	C543.536743,197.677887 543.237671,199.387527 542.642700,201.334351 
	C542.246826,201.004898 542.146851,200.438248 542.292908,199.227722 
	C542.682068,197.719452 542.825134,196.855087 542.968201,195.990723 
	C542.968201,195.990723 543.401794,195.971741 543.618774,195.970001 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M502.374603,143.950378 
	C501.974152,143.245758 501.902985,142.490463 501.937714,141.367081 
	C502.263702,141.965897 502.483826,142.932785 502.374603,143.950378 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M542.646118,196.040161 
	C542.825134,196.855087 542.682068,197.719452 542.283569,198.796509 
	C542.126831,198.035995 542.225464,197.062805 542.646118,196.040161 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M539.885742,194.704483 
	C539.593811,194.787079 539.207825,194.696960 539.122864,194.485733 
	C539.054626,194.316086 539.351135,193.999741 539.485779,193.748505 
	C539.631042,193.992157 539.776367,194.235809 539.885742,194.704483 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M543.857300,140.746674 
	C543.810608,141.030746 543.371582,141.023285 543.151489,141.012573 
	C542.979431,140.752609 543.027405,140.503372 543.187256,139.888428 
	C543.500854,139.836014 543.702454,140.149307 543.857300,140.746674 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M541.411377,204.181396 
	C541.141296,204.116806 541.092529,203.885178 541.020325,203.298401 
	C540.996948,202.943253 541.459167,202.956772 541.689880,202.967712 
	C541.824585,203.323898 541.728638,203.669144 541.411377,204.181396 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M539.641174,208.148590 
	C539.775391,208.332825 539.692749,208.669846 539.379395,209.152374 
	C539.240601,208.965714 539.332520,208.633545 539.641174,208.148590 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M594.152344,252.421753 
	C592.562317,252.405304 591.305603,252.241440 590.048889,252.077591 
	C590.106628,251.633240 590.164368,251.188889 590.222107,250.744537 
	C592.262390,250.744537 594.302612,250.744537 597.162476,250.744537 
	C595.887085,251.473465 595.186401,251.873917 594.152344,252.421753 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M569.910461,218.311493 
	C570.061462,218.193771 570.048462,218.596802 570.054443,218.798767 
	C570.060364,219.000732 569.836426,219.079285 569.722534,219.108612 
	C569.502930,218.868484 569.553223,218.632248 569.910461,218.311493 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M576.467529,221.450058 
	C576.523193,221.416763 576.411865,221.483353 576.467529,221.450058 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M639.112915,284.235748 
	C639.327148,284.112427 639.587097,284.293549 639.889648,284.781921 
	C639.677856,284.899658 639.419922,284.716705 639.112915,284.235748 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M639.992188,288.293091 
	C640.262085,288.088531 640.546204,288.247101 640.891235,288.731140 
	C640.697754,288.814117 640.382446,288.680664 639.992188,288.293091 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M731.869568,288.318298 
	C730.722839,284.465912 733.272827,283.507233 736.730835,283.008636 
	C735.466614,284.743561 733.816895,286.479034 731.869568,288.318298 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M730.464966,289.259338 
	C730.391418,288.979187 730.582642,288.665985 731.067810,288.395142 
	C731.151062,288.700439 730.940430,288.963379 730.464966,289.259338 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M729.348633,290.147644 
	C729.277954,289.912811 729.480408,289.600769 729.969116,289.237671 
	C730.044128,289.481262 729.832886,289.775848 729.348633,290.147644 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M714.871094,293.755737 
	C714.526733,294.333496 714.059143,294.646515 713.295776,294.979797 
	C713.105103,294.266968 713.210205,293.533936 713.315247,292.800903 
	C713.792786,293.030914 714.270325,293.260956 714.871094,293.755737 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M648.957642,290.709869 
	C648.437866,290.833313 647.887939,290.665436 647.171570,290.255615 
	C647.645935,290.148621 648.286743,290.283569 648.957642,290.709869 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M724.953125,290.704590 
	C724.551392,291.001129 724.112854,291.001038 723.345703,291.001099 
	C723.246094,290.486969 723.475098,289.972717 723.704041,289.458435 
	C724.108154,289.774933 724.512268,290.091431 724.953125,290.704590 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M719.630005,293.009216 
	C718.908630,293.341949 717.822266,293.671173 716.371643,294.000793 
	C717.093201,293.669403 718.179138,293.337585 719.630005,293.009216 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M652.955688,292.681274 
	C652.415771,292.520325 651.833984,292.041351 651.131470,291.302673 
	C651.645142,291.483063 652.279419,291.923187 652.955688,292.681274 
z"/>
<path fill="#61B65C" opacity="1.000000" stroke="none" 
	d="
M660.116882,276.220581 
	C660.329895,276.103790 660.586609,276.288513 660.890015,276.772247 
	C660.675720,276.892853 660.416992,276.709778 660.116882,276.220581 
z"/>
<path fill="#E9FAE7" opacity="1.000000" stroke="none" 
	d="
M142.879883,105.743195 
	C167.015274,107.447777 181.748260,130.922470 172.567810,152.636963 
	C170.971832,156.411972 168.428055,159.833221 165.983749,163.170044 
	C165.362823,164.017700 163.443558,163.914291 162.120850,164.247879 
	C162.283829,162.871796 161.925034,161.010956 162.686737,160.191162 
	C178.216599,143.476410 171.403641,117.451714 149.534714,111.057755 
	C139.505936,108.125580 130.105179,110.026413 121.835022,116.652351 
	C121.446869,116.963326 121.153870,117.561508 120.741379,117.642357 
	C119.463455,117.892845 118.149178,117.957916 116.849014,118.094955 
	C117.164948,116.942070 117.120552,115.428856 117.854958,114.694450 
	C122.838707,109.710770 128.947632,106.864212 135.928955,105.990204 
	C138.067963,105.722412 140.249573,105.794746 142.879883,105.743195 
z"/>
<path fill="#E9FAE7" opacity="1.000000" stroke="none" 
	d="
M154.671585,119.203918 
	C166.966385,129.199417 169.193466,142.913757 160.623871,154.526657 
	C159.856293,155.566818 158.880295,156.543518 157.794861,157.217636 
	C157.309814,157.518906 155.649948,156.980560 155.653275,156.852524 
	C155.684448,155.649673 155.565384,154.136566 156.230469,153.306885 
	C160.155807,148.410095 162.050858,143.086761 161.127960,136.717407 
	C158.980133,121.893822 141.684769,114.290367 129.570190,122.946938 
	C128.627792,123.620331 127.782486,124.546600 126.736504,124.922607 
	C125.829720,125.248566 124.702065,124.960098 123.672035,124.943192 
	C123.922432,123.809761 123.780479,122.217857 124.484932,121.615005 
	C130.428284,116.528809 137.301819,113.868385 145.155365,115.409531 
	C148.339233,116.034325 151.306290,117.763893 154.671585,119.203918 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M164.086487,106.293930 
	C167.698181,102.941666 171.486450,99.880150 175.645599,96.883919 
	C178.813904,99.864792 181.611313,102.780380 184.699509,105.857498 
	C183.537323,108.216339 182.220856,110.522377 180.586090,112.574898 
	C179.382462,114.086082 177.762726,115.265854 176.167023,116.898193 
	C172.091461,113.663628 168.177261,110.124153 164.086487,106.293930 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M113.307755,91.978920 
	C116.749527,94.158409 120.193024,96.335167 123.632195,98.518761 
	C124.958954,99.361145 126.275856,100.219048 127.888496,101.333015 
	C128.179749,101.596146 128.068436,102.036453 128.068436,102.036453 
	C128.068436,102.036453 128.021713,101.995804 127.696800,102.003830 
	C126.826668,102.293114 126.281448,102.574371 125.736229,102.855637 
	C121.462997,105.438660 117.165627,107.983093 112.933136,110.631241 
	C111.844429,111.312424 110.960060,112.320190 109.739746,113.390190 
	C108.333069,112.467209 107.009521,111.598778 105.326462,110.859657 
	C104.966942,110.988968 105.008263,110.994003 105.185028,110.724213 
	C104.946899,109.952469 104.532013,109.450508 104.038742,108.583817 
	C107.615692,103.779190 111.271019,99.339302 115.184280,94.586113 
	C115.441360,94.936935 115.059120,94.421272 114.683296,93.900963 
	C114.221977,93.262314 113.766022,92.619774 113.307755,91.978920 
z"/>
<path fill="#E9FAE7" opacity="1.000000" stroke="none" 
	d="
M130.130386,131.909775 
	C131.494659,126.583633 137.713028,123.648697 144.538986,125.109215 
	C154.438736,127.227417 159.350616,139.837418 153.300644,147.993393 
	C152.432526,149.163696 150.470596,149.522629 149.014862,150.257050 
	C149.221786,148.790924 149.033203,147.107086 149.700699,145.894501 
	C153.619446,138.775711 152.246231,133.918945 144.324768,129.657806 
	C141.793900,129.565445 139.895615,129.781204 137.997314,129.996964 
	C135.619064,130.673889 133.240829,131.350815 130.440857,132.008102 
	C130.019119,131.988464 130.130386,131.909775 130.130386,131.909775 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M128.491974,102.036644 
	C128.068436,102.036453 128.179749,101.596146 128.252548,101.381195 
	C131.650940,100.523834 134.976547,99.881424 138.699631,99.248009 
	C135.703247,100.183609 132.309372,101.110222 128.491974,102.036644 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M123.310593,142.932266 
	C123.711296,141.922729 124.481583,140.884583 125.540100,139.685333 
	C125.112282,140.650696 124.396233,141.777191 123.310593,142.932266 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M138.441956,129.998016 
	C139.895615,129.781204 141.793900,129.565445 143.981445,129.605637 
	C142.475998,129.907440 140.681305,129.953262 138.441956,129.998016 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M128.993073,134.893799 
	C128.484268,134.921722 128.128876,134.624268 127.701889,134.107407 
	C128.187714,133.294174 128.745132,132.700348 129.716461,132.008148 
	C130.130386,131.909775 130.019119,131.988464 130.057556,131.931122 
	C129.779480,132.771988 129.462982,133.670212 128.993073,134.893799 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M118.044556,148.368713 
	C118.445168,147.702118 118.863777,147.250336 119.551697,146.645721 
	C119.467697,147.283783 119.114380,148.074661 118.414833,148.940063 
	C118.068604,149.014603 118.062546,148.583527 118.044556,148.368713 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M150.989609,156.597015 
	C150.441208,156.448257 149.845215,155.992783 149.159576,155.262451 
	C149.693954,155.421814 150.317993,155.856064 150.989609,156.597015 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M121.293671,144.942902 
	C121.453392,144.386276 121.940285,143.792862 122.693459,143.069611 
	C122.513435,143.595230 122.067139,144.250671 121.293671,144.942902 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M122.801834,128.981781 
	C122.209602,128.790863 121.546204,128.268433 120.765152,127.444382 
	C121.341881,127.645271 122.036278,128.147766 122.801834,128.981781 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M126.901848,132.974152 
	C126.534172,132.973679 126.079025,132.711670 125.532639,132.197021 
	C125.899055,132.200455 126.356712,132.456528 126.901848,132.974152 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M155.752472,160.986313 
	C155.322250,161.014664 154.826401,160.754318 154.249542,160.216461 
	C154.674622,160.191833 155.180725,160.444733 155.752472,160.986313 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M126.060242,102.888687 
	C126.281448,102.574371 126.826668,102.293114 127.692062,102.034439 
	C127.469582,102.345261 126.926918,102.633507 126.060242,102.888687 
z"/>
<path fill="#76B870" opacity="1.000000" stroke="none" 
	d="
M148.703766,154.226578 
	C148.419250,154.328674 148.063431,154.076599 147.558777,153.527313 
	C147.817429,153.444214 148.224930,153.658310 148.703766,154.226578 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M139.516373,99.047958 
	C139.474106,98.913200 139.721329,98.708824 140.135971,98.577805 
	C140.137543,98.760223 139.971695,98.869286 139.516373,99.047958 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M142.036285,95.704834 
	C141.766800,95.800179 141.592484,95.616394 141.563812,95.142525 
	C141.810837,95.094223 141.951492,95.285309 142.036285,95.704834 
z"/>
<path fill="#8BBE85" opacity="1.000000" stroke="none" 
	d="
M141.015488,94.294159 
	C141.075836,94.392151 140.750412,94.481712 140.750412,94.481712 
	C140.750412,94.481712 140.508743,94.246780 140.551147,94.082466 
	C140.593536,93.918152 140.955139,94.196167 141.015488,94.294159 
z"/>
<path fill="#6CBB5B" opacity="1.000000" stroke="none" 
	d="
M113.131172,91.716370 
	C113.766022,92.619774 114.221977,93.262314 114.683296,93.900963 
	C115.059120,94.421272 115.441360,94.936935 115.184280,94.586113 
	C111.271019,99.339302 107.615692,103.779190 103.681618,108.651474 
	C103.172714,109.159271 102.942551,109.234680 102.712387,109.310081 
	C102.712387,109.310081 102.888557,109.094231 102.846771,109.006058 
	C102.535034,108.950729 102.265083,108.983582 101.995140,109.016441 
	C98.082939,105.243454 94.170731,101.470474 90.006668,97.152115 
	C89.456215,96.417625 89.157616,96.228516 88.859024,96.039406 
	C88.540390,95.984505 88.281052,95.830048 88.154907,95.017288 
	C91.573647,91.690582 94.863861,88.851059 98.311867,86.218170 
	C98.960617,85.722778 100.252762,86.138367 101.223022,85.980202 
	C102.824272,85.719177 104.405144,85.333160 105.994606,84.999817 
	C107.097702,85.927238 108.200798,86.854660 109.682434,88.163391 
	C110.372086,88.704025 110.683197,88.863365 110.994316,89.022705 
	C111.647743,89.833076 112.301163,90.643448 113.131172,91.716370 
z"/>
<path fill="#74BF59" opacity="1.000000" stroke="none" 
	d="
M105.991493,84.777451 
	C104.405144,85.333160 102.824272,85.719177 101.223022,85.980202 
	C100.252762,86.138367 98.960617,85.722778 98.311867,86.218170 
	C94.863861,88.851059 91.573647,91.690582 88.073792,94.801498 
	C82.322792,89.815498 81.603294,85.292702 85.605171,80.601013 
	C89.640892,75.869644 95.091232,75.665367 100.816078,80.032997 
	C101.990883,80.929291 103.160904,81.831856 104.714035,83.132172 
	C105.385696,83.725464 105.676453,83.918121 105.967209,84.110786 
	C105.967209,84.110786 105.988380,84.555069 105.991493,84.777451 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M102.780106,109.637474 
	C102.942551,109.234680 103.172714,109.159271 103.760002,109.016212 
	C104.532013,109.450508 104.946899,109.952469 105.178833,110.744583 
	C104.279861,110.678116 103.563835,110.321495 102.780106,109.637474 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M105.876167,83.863815 
	C105.676453,83.918121 105.385696,83.725464 105.021118,83.273727 
	C105.226578,83.215385 105.505852,83.416122 105.876167,83.863815 
z"/>
<path fill="#F0FEEE" opacity="1.000000" stroke="none" 
	d="
M110.877724,88.788101 
	C110.683197,88.863365 110.372086,88.704025 109.966225,88.291817 
	C110.168022,88.210464 110.464577,88.381981 110.877724,88.788101 
z"/>
<path fill="#61B75D" opacity="1.000000" stroke="none" 
	d="
M102.149223,109.182823 
	C102.265083,108.983582 102.535034,108.950729 102.780853,109.128784 
	C102.756737,109.339691 102.303307,109.349205 102.149223,109.182823 
z"/>
<path fill="#74BF59" opacity="1.000000" stroke="none" 
	d="
M88.943954,96.305878 
	C89.157616,96.228516 89.456215,96.417625 89.864265,96.854080 
	C89.658775,96.925072 89.343826,96.748718 88.943954,96.305878 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M728.897644,168.140991 
	C731.778503,181.337921 734.344177,194.769150 736.846863,208.602905 
	C736.097351,208.201828 735.198975,207.498871 734.761230,206.577103 
	C732.909424,202.678055 731.194641,198.713943 729.335083,194.562134 
	C730.359070,194.562134 731.397705,194.562134 732.589172,194.562134 
	C732.530945,193.841797 732.523560,193.377411 732.452209,192.923111 
	C731.166809,184.739792 729.873779,176.557678 728.897644,168.140991 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M705.616455,49.050171 
	C706.664124,53.067024 707.330750,57.134407 707.997437,61.601082 
	C702.379456,57.413082 701.387329,52.779713 705.616455,49.050171 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M712.767517,80.235397 
	C714.050598,85.031822 715.035583,90.092857 715.970337,95.577591 
	C714.769897,90.834198 713.619690,85.667107 712.767517,80.235397 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M716.822693,102.182594 
	C717.727539,104.718285 718.335327,107.491371 718.876099,110.667397 
	C715.387634,110.954330 711.754883,111.070061 715.621460,106.121170 
	C716.334167,105.208847 716.244751,103.669762 716.822693,102.182594 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M700.369751,142.913513 
	C698.680237,142.642044 697.355835,142.314728 696.031494,141.987411 
	C696.367554,141.304703 696.703674,140.622009 697.039734,139.939316 
	C698.271484,140.912109 699.503235,141.884903 700.369751,142.913513 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M720.876343,124.128662 
	C721.175049,123.897736 721.564026,123.948174 721.753174,123.999397 
	C721.977600,125.418518 722.012878,126.786415 722.057434,128.565369 
	C721.570312,127.437477 721.073914,125.898529 720.876343,124.128662 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M723.524780,164.693497 
	C723.530701,165.448196 723.325745,165.983688 723.120728,166.519165 
	C722.846558,166.384354 722.572327,166.249542 722.298157,166.114731 
	C722.636719,165.567917 722.975281,165.021103 723.524780,164.693497 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M711.535400,100.390259 
	C711.651245,99.941650 711.933777,99.666718 712.234253,99.413002 
	C712.241943,99.406509 712.446472,99.633110 712.559143,99.751022 
	C712.274231,100.022911 711.989319,100.294792 711.535400,100.390259 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M714.464722,126.609764 
	C714.348938,127.058441 714.066345,127.333389 713.765808,127.587128 
	C713.758118,127.593658 713.553589,127.367020 713.440857,127.249084 
	C713.725769,126.977150 714.010681,126.705223 714.464722,126.609764 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M639.604797,143.045502 
	C639.209045,143.286942 638.815308,143.285187 638.423828,143.255005 
	C638.412903,143.254150 638.425415,142.948975 638.427002,142.785706 
	C638.820374,142.789902 639.213806,142.794113 639.604797,143.045502 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M728.161743,162.723846 
	C728.379944,162.747696 728.600769,163.059464 728.788818,163.665924 
	C728.558838,163.644318 728.361572,163.328049 728.161743,162.723846 
z"/>
<path fill="#F9FDF8" opacity="1.000000" stroke="none" 
	d="
M716.131348,96.951920 
	C716.343628,96.948303 716.582581,97.192650 716.791992,97.702255 
	C716.461548,97.789902 716.260132,97.534027 716.131348,96.951920 
z"/>
<path fill="#FCFEFB" opacity="1.000000" stroke="none" 
	d="
M540.887573,48.992992 
	C542.146362,52.157318 543.794617,55.076801 543.871399,58.037056 
	C544.177734,69.845154 544.040833,81.666199 543.976257,93.481941 
	C543.936096,100.822815 540.622986,104.002419 533.232544,104.009506 
	C522.959534,104.019363 512.686523,104.011993 502.093475,104.011993 
	C502.093475,84.841873 502.093475,66.295013 502.093475,47.006340 
	C511.804291,47.006340 521.399109,46.862431 530.985291,47.087185 
	C534.186523,47.162239 537.365601,48.180038 540.887573,48.992992 
z"/>
<path fill="#E4F8E1" opacity="1.000000" stroke="none" 
	d="
M540.522949,206.131927 
	C540.373047,205.930328 540.454346,205.599045 540.766602,205.133179 
	C540.916443,205.333160 540.835327,205.667709 540.522949,206.131927 
z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
